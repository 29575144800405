import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './Privacy.module.css';

function PrivacyPolicy() {
  return (
    <Container>
      <Box className={styles.mainContainer}>
        <Typography variant="h1" className={styles.mainTitle}>Privacybeleid</Typography>
        <Typography variant="body2" className={styles.paragraph} sx={{ fontStyle: 'italic' }}>
          Effectieve Datum: 21-09-2023
        </Typography>

        <Typography variant="h2" className={styles.header}>1. Inleiding</Typography>
        <Typography variant="body2" className={styles.paragraph}>Welkom bij Baby Book! We zijn toegewijd aan het beschermen van uw privacy. Dit privacybeleid legt uit hoe we uw persoonlijke informatie verzamelen, gebruiken en beschermen wanneer u onze website gebruikt.</Typography>

        <Typography variant="h2" className={styles.header}>2. Informatie Die We Verzamelen</Typography>
        <Typography variant="body2" className={styles.paragraph}>We kunnen de volgende soorten informatie verzamelen:</Typography>
        <Typography component={'span'} variant="body2" className={styles.paragraph}>
          <ul>
            <li>Persoonlijke Informatie: Naam, e-mailadres en andere informatie die u vrijwillig verstrekt.</li>
            <li>Gebruikersinformatie: Gegevens over hoe u met onze website omgaat.</li>
          </ul>
        </Typography>

        <Typography variant="h2" className={styles.header}>3. Hoe We Uw Informatie Gebruiken</Typography>
        <Typography variant="body2" className={styles.paragraph}>Wanneer u zich aanmeldt bij Baby Book, gebruiken we uw Google-accountinformatie om de gegevens die u in onze website invoert op te slaan en te koppelen aan alleen uw account.</Typography>
        <Typography variant="body2" className={styles.paragraph}>Baby Book is een non-profit website en we delen uw gegevens niet met derden en verkopen deze niet.</Typography>

        <Typography variant="h2" className={styles.header}>4. Cookies en Tracking</Typography>
        <Typography variant="body2" className={styles.paragraph}>Wij maken geen gebruik van eigen cookies voor trackingdoeleinden op onze website. Echter, bij het gebruik van Google-authenticatie kan Google cookies plaatsen op uw apparaat voor authenticatie- en sessiebeheerdoeleinden. Deze cookies zijn essentieel om bepaalde functies mogelijk te maken en een veilige interactie met ons platform te waarborgen. U kunt meer te weten komen over de gegevensverwerkingspraktijken van Google door hun privacybeleid te raadplegen en uw Google-accountinstellingen indien nodig aan te passen.</Typography>

        <Typography variant="h2" className={styles.header}>5. Links naar Derden</Typography>
        <Typography variant="body2" className={styles.paragraph}>Onze website kan links bevatten naar websites van derden. We zijn niet verantwoordelijk voor hun privacypraktijken.</Typography>

        <Typography variant="h2" className={styles.header}>6. Gegevensbeveiliging</Typography>
        <Typography variant="body2" className={styles.paragraph}>Wij hechten grote waarde aan de beveiliging van uw gegevens en nemen beveiligingsmaatregelen om uw informatie te beschermen. Deze maatregelen omvatten onder andere:</Typography>
        <Typography component={'span'} variant="body2" className={styles.paragraph}>
          <ul>
            <li>Regelmatige monitoring en beoordeling van onze beveiligingsprocedures.</li>
            <li>Beperkte toegang tot uw gegevens, met alleen geautoriseerd personeel dat toegang heeft tot relevante informatie.</li>
            <li>Het gebruik van versleuteling en beveiligingsprotocollen om gegevensoverdracht te beschermen.</li>
            <li>Periodieke beveiligingsaudits en updates van onze systemen en software.</li>
          </ul>
        </Typography>
        <Typography variant="body2" className={styles.paragraph}>Hoewel we ons uiterste best doen om de beveiliging van uw gegevens te waarborgen, moet u er rekening mee houden dat geen enkele methode van gegevensoverdracht via internet of elektronische opslag volledig veilig is. We raden u aan uw eigen voorzorgsmaatregelen te nemen, zoals het regelmatig wijzigen van wachtwoorden en het bijwerken van uw software, om uw persoonlijke informatie te beschermen.</Typography>

        <Typography variant="h2" className={styles.header}>7. Uw Keuzes</Typography>
        <Typography variant="body2" className={styles.paragraph}>U kunt uw persoonlijke informatie beheren via uw Google account. Wij beheren dit account niet en lezen slechts gegevens hierin uit zoals uw naam en emailadres t.b.v. de functionaliteit van Baby Book.</Typography>
        <Typography variant="body2" className={styles.paragraph}>U kunt ten alle tijde uw Baby Book account en data verwijderen via de Profiel pagina.</Typography>

        <Typography variant="h2" className={styles.header}>8. Privacy van Kinderen</Typography>
        <Typography variant="body2" className={styles.paragraph}>Onze website is niet bedoeld voor kinderen jonger dan 13 jaar.</Typography>

        <Typography variant="h2" className={styles.header}>9. Wijzigingen in dit Beleid</Typography>
        <Typography variant="body2" className={styles.paragraph}>We kunnen dit beleid bijwerken. Bekijk het regelmatig.</Typography>

        <Typography variant="body2" className={styles.paragraph}>Bedankt voor het gebruik van Baby Book!</Typography>
      </Box>
    </Container>
  )
}

export default PrivacyPolicy