import React, { createContext, useState } from 'react';

type IStatisticsContext = [any, React.Dispatch<React.SetStateAction<any>>];

const StatisticsContext = createContext<IStatisticsContext>([[], () => {}]);

const StatisticsProvider = ({ children }: { children: any }) => {
  const [statistics, setStatistics] = useState(null);
  
  return (
    <StatisticsContext.Provider value={[statistics, setStatistics]}>
      {children}
    </StatisticsContext.Provider>
  )
} 

export { StatisticsContext, StatisticsProvider };
