import { FunctionComponent } from "react";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ThemeProvider } from '@mui/material/styles';
import { nowdatepickertheme, OpenCalendarIcon } from './Datepickertheme';
import { NowButton } from '../custom/NowButton';
import useMediaQuery from '@mui/material/useMediaQuery';

interface CustomNowDateTimePickerProps {
  startDate: Date;
  onDateTimeChange: (date: Date) => void;
  onNowDate: (date: Date) => void;
}

const CustomNowDateTimePicker: FunctionComponent<CustomNowDateTimePickerProps> = ({ startDate, onDateTimeChange, onNowDate }) => {
  const sm = useMediaQuery('(max-width:425px)');

  return (
    <div>
      <div style={{ width: sm ? '55%' : 'auto', float: 'left' }}>
        <ThemeProvider theme={nowdatepickertheme}>
          <DateTimePicker
            value={startDate}
            onChange={(date: any) => { onDateTimeChange(date); }}
            slots={{
              openPickerIcon: OpenCalendarIcon
            }}
          />
        </ThemeProvider>
      </div>
      <div style={{ float: 'left' }}>
        <NowButton
          onClick={() => onNowDate(new Date())}
        >
          Nu
        </NowButton>
      </div>
    </div>
  );
}

export default CustomNowDateTimePicker;
