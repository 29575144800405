import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Link } from "react-router-dom";
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { GiRoundBottomFlask } from 'react-icons/gi';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import Stopwatch from '../stopwatch/Stopwatch';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './Pumping.module.css';

interface PumpingProps {
  hidePumping: boolean;
  onFormClose: (formType: string) => void;
  pumpingItem: any;
}

interface Values {
  datetime: Date;
  singleDoublePumping: string;
  timeDoublePumping: number;
  timeLeftBreast: number;
  timeRightBreast: number;
  leftAmount: string;
  rightAmount: string;
  comment: string;
}

const Pumping: FunctionComponent<PumpingProps> = ({ hidePumping, onFormClose, pumpingItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [singleDoublePumping, setSingleDoublePumping] = useState('double');
  const [timeDoublePumping, setTimeDoublePumping] = useState(0);
  const [timeLeftBreast, setTimeLeftBreast] = useState(0);
  const [timeRightBreast, setTimeRightBreast] = useState(0);
  const [leftAmount, setLeftAmount] = useState('');
  const [rightAmount, setRightAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [startEndTime, setStartEndTime] = useState('start');
  const [comment, setComment] = useState('');
  const [isVisible, setIsVisible] = useState(true);

  const sm = useMediaQuery('(max-width:416px)');

  const handleComment = (value: string) => {
    setComment(value);
  }

  const handleAmount = (side: string, value: string) => {
    if (side == 'Left') {
      setLeftAmount(value);
    } else {
      setRightAmount(value);
    }
  }

  const calculateStartTime = () => {
    // Get total minutes and seconds of both sides
    let totalTime = 0;
    if (timeDoublePumping && timeDoublePumping !== 0) {
      totalTime = timeDoublePumping;
    } else {
      totalTime = timeLeftBreast + timeRightBreast;
    }

    const seconds = Math.floor((totalTime / 1000)) % 60;
    const minutes = Math.floor((totalTime / 1000) / 60);

    // Startdate is selected as enddate, so recalculate to starttime
    const endTimeMilliseconds = startDate.getTime();
    const timeMilliseconds = (minutes * 60 + seconds) * 1000;
    const newStartDateMilliseconds = endTimeMilliseconds - timeMilliseconds;
    const newStartDate = new Date(newStartDateMilliseconds);

    return newStartDate
  }

  const addPumping = async (data: any) => {
    // Saving time as miliseconds
    const BreastfeedingsCollection = collection(db, "trackingdata", user.userId, "pumping");
    await setDoc(doc(BreastfeedingsCollection), {
      datetime: data.datetime,
      singleDoublePumping: data.singleDoublePumping,
      timeDoublePumping: data.timeDoublePumping,
      timeLeftBreast: data.timeLeftBreast,
      timeRightBreast: data.timeRightBreast,
      leftAmount: data.leftAmount,
      rightAmount: data.rightAmount,
      comment: data.comment,
      babyId: baby.babyId
    });

    onFormClose('pumping');
  }

  const updatePumping = async (data: any) => {
    const pumpingCollection = collection(db, "trackingdata", pumpingItem.userId, "pumping");
    const pumpingDocRef = doc(pumpingCollection, pumpingItem.id);

    try {
      await updateDoc(pumpingDocRef, {
        datetime: data.datetime,
        singleDoublePumping: data.singleDoublePumping,
        timeDoublePumping: data.timeDoublePumping,
        timeLeftBreast: data.timeLeftBreast,
        timeRightBreast: data.timeRightBreast,
        leftAmount: data.leftAmount,
        rightAmount: data.rightAmount,
        comment: data.comment
      });
    } catch (error) {
      console.error('Error updating pumping:', error);
    }

    onFormClose('pumping');
  }

  const deletePumping = async () => {
    const pumpingCollection = collection(db, "trackingdata", pumpingItem.userId, "pumping");
    const pumpingDocRef = doc(pumpingCollection, pumpingItem.id);

    try {
      await deleteDoc(pumpingDocRef);
    } catch (error) {
      console.error('Error deleting pumping:', error);
    }

    onFormClose('pumping');
  }

  const resetForm = () => {
    setStartDate(new Date());
    setSingleDoublePumping('double');
    setTimeDoublePumping(0);
    setTimeLeftBreast(0);
    setTimeRightBreast(0);
    setLeftAmount('');
    setRightAmount('');
    setComment('');
    setTotalAmount(0);
    setStartEndTime('start');
  }

  useEffect(() => {
    if (pumpingItem != null) {
      const start = new Date(pumpingItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setStartEndTime('start');
      setSingleDoublePumping(pumpingItem.data.singleDoublePumping);
      setTimeDoublePumping(pumpingItem.data.timeDoublePumping);
      setTimeLeftBreast(pumpingItem.data.timeLeftBreast);
      setTimeRightBreast(pumpingItem.data.timeRightBreast);
      setLeftAmount(pumpingItem.data.leftAmount);
      setRightAmount(pumpingItem.data.rightAmount);
      setComment(pumpingItem.data.comment);
    }
  }, [pumpingItem]);

  useEffect(() => {
    if (pumpingItem == null) {
      resetForm();
    }
  }, [hidePumping]);

  useEffect(() => {
    const totalLeft = leftAmount ? parseFloat(leftAmount) : 0;
    const totalRight = rightAmount ? parseFloat(rightAmount) : 0;
    const total = totalLeft + totalRight;
    setTotalAmount(parseFloat(total.toFixed(2)));
  }, [leftAmount, rightAmount]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          singleDoublePumping: singleDoublePumping,
          timeDoublePumping: timeDoublePumping,
          timeLeftBreast: timeLeftBreast,
          timeRightBreast: timeRightBreast,
          leftAmount: leftAmount,
          rightAmount: rightAmount,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (startEndTime == 'end') {
            values.datetime = calculateStartTime();
          }

          if (pumpingItem != null) {
            updatePumping(values);
          } else {
            addPumping(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <GiRoundBottomFlask style={headerIcon} />
              <span style={sm ? headerTitleSX : headerTitleMD}>Kolven</span>
            </div>

            <div className="form-body">
              <Typography className="group-space" component={'span'} variant="h4">
                <div>Start / eindtijd</div>
              </Typography>
              <div style={{ marginTop: '10px' }}>
                <div className={`radio-group ${styles.radioGroup}`} role="group" aria-labelledby="my-radio-group">
                  <label
                    onClick={() => { setFieldValue("startEndTime", "start"); setStartEndTime('start') }}
                    style={{ backgroundColor: startEndTime === "start" ? '#18d99c' : 'transparent', color: startEndTime === "start" ? '#000' : '#F0FFFF' }}>
                    Starttijd
                  </label>
                  <label
                    onClick={() => { setFieldValue("startEndTime", "end"); setStartEndTime('end') }}
                    style={{ backgroundColor: startEndTime === "end" ? '#18d99c' : 'transparent', color: startEndTime === "end" ? '#000' : '#F0FFFF' }}>
                    Eindtijd
                  </label>
                </div>
              </div>
              <div className="datepicker-group" style={{ marginTop: '10px' }}>
                <div>
                  <CustomNowDateTimePicker
                    startDate={startDate}
                    onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date); }}
                    onNowDate={setStartDate}
                  />
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Kolven</div>
                </Typography>
                <div style={{ marginTop: '10px' }}>
                  <div className={`radio-group ${styles.radioGroup}`} role="group" aria-labelledby="my-radio-group">
                    <label
                      onClick={() => { setFieldValue("singleDoublePumping", "single"); setSingleDoublePumping('single') }}
                      style={{ backgroundColor: singleDoublePumping === "single" ? '#18d99c' : 'transparent', color: singleDoublePumping === "single" ? '#000' : '#F0FFFF' }}>
                      Enkelzijdig
                    </label>
                    <label
                      onClick={() => { setFieldValue("singleDoublePumping", "double"); setSingleDoublePumping('double') }}
                      style={{ backgroundColor: singleDoublePumping === "double" ? '#18d99c' : 'transparent', color: singleDoublePumping === "double" ? '#000' : '#F0FFFF' }}>
                      Dubbelzijdig
                    </label>
                  </div>
                </div>
                {/* Display two or one stopwatch based on single or double pumping */}
                <div className={styles.stopwatchesGroup} style={{ display: singleDoublePumping === 'single' ? 'block' : 'none', marginTop: '5px' }}>
                  <div className={`${styles.stopwatchChild} ${styles.space}`}>
                    Links
                    <Stopwatch onTimeChange={setTimeLeftBreast} onRunning={setIsVisible} breastfeedingItem={timeLeftBreast} />
                  </div>
                  <div className={styles.stopwatchChild}>
                    Rechts
                    <Stopwatch onTimeChange={setTimeRightBreast} onRunning={setIsVisible} breastfeedingItem={timeRightBreast} />
                  </div>
                </div>
                <div className={styles.stopwatchesGroup} style={{ display: singleDoublePumping === 'double' ? 'block' : 'none', marginTop: '5px' }}>
                  <div className={`${styles.stopwatchChildDouble}`}>
                    Links & Rechts
                    <Stopwatch onTimeChange={setTimeDoublePumping} onRunning={setIsVisible} breastfeedingItem={timeDoublePumping} />
                  </div>
                </div>
              </div>

              <div className={`group-space`}>
                <Typography component={'span'} variant="h4">
                  <div>Hoeveelheid</div>
                </Typography>
                <div className={styles.amountContainer}>
                  <label className={[styles.amountLabel, styles.amountLabelLeft].join(' ')}>
                    <p>Links</p>
                    <Field 
                      as="input" 
                      name="leftAmount"
                      type="number"
                      placeholder=""
                      onChange={(e: any) => { handleAmount('Left', e.target.value); }} //
                      value={leftAmount} 
                      className={styles.amountField} 
                      maxLength="30"
                    /> ml
                  </label>
                  <label className={styles.amountLabel}>
                    <p>Rechts:</p>
                    <Field 
                      as="input" 
                      name="rightAmount"
                      type="number"
                      placeholder=""
                      onChange={(e: any) => { handleAmount('Right', e.target.value); }}
                      value={rightAmount} 
                      className={styles.amountField} 
                      maxLength="30"
                    /> ml
                  </label>
                </div>
                <div style={{ color: '#fff', fontStyle: 'italic', marginTop: 8 }}>
                  Totaal: {totalAmount} ml
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Opmerking</div>
                </Typography>
                <Field
                  as="textarea"
                  name="comment"
                  onChange={(e: any) => handleComment(e.target.value)}
                  value={comment}
                  className="comment-field"
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                {pumpingItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deletePumping}
                    sx={{
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                }
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Pumping;
