import { useState, useEffect, FunctionComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DurationPicker, { Duration } from '../duration-picker/DurationPicker';
import styles from './Breastfeeding.module.css';
import '../../styles/FormStyles.css';

export interface DialogProps {
  open: boolean;
  onClose: (pauseTime: { hours: number, minutes: number, seconds: number }) => void;
  pauseTime: Duration;
}

const PauseDialog: FunctionComponent<DialogProps> = ({ open, onClose, pauseTime }) => {
  const [pauseTimeEditing, setPauseTimeEditing] = useState<Duration>({ hours: 0, minutes: 0, seconds: 0 });

  const handleHours = (hours: number) => {
    setPauseTimeEditing(prevState => ({ ...prevState, hours: hours }));
  }  

  const handleMinutes = (minutes: number) => {
    setPauseTimeEditing(prevState => ({ ...prevState, minutes: minutes }));
  }

  const handleSeconds = (seconds: number) => {
    setPauseTimeEditing(prevState => ({ ...prevState, seconds: seconds }));
  }

  const handleCloseClick = () => {
    onClose(pauseTimeEditing);
  };

  const handleResetPauseTime = () => {
    setPauseTimeEditing({ hours: 0, minutes: 0, seconds: 0 });
  }

  useEffect(() => {
    if (pauseTime != null) {
      setPauseTimeEditing(pauseTime);
    }
  }, [pauseTime]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      PaperProps={{
        style: {
          backgroundColor: '#444654',
          padding: '20px'
        },
      }}
    >
      <div className={styles.pickerContainer}>
        <DurationPicker pauseTime={pauseTimeEditing} onHoursChange={handleHours} onMinutesChange={handleMinutes} onSecondsChange={handleSeconds} />
      </div>

      <div className={styles.buttonsContainer}>
        <div className={styles.saveBtn} style={{ marginRight: '10px' }}>
          <Button 
            variant="contained"
            type="button"
            onClick={handleCloseClick}
            className={styles.btn}
          >
            OK
          </Button>
        </div>
        <div className={styles.saveBtn}>
          <Button 
            variant="contained"
            type="button"
            onClick={handleResetPauseTime}
            className={`${styles.btn} ${styles.resetBtn}`}
          >
            Reset
          </Button>
        </div>
      </div>

    </Dialog>
  );
}

export default PauseDialog;
