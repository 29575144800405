import React from 'react';

interface Props {
  components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>
  children: React.ReactNode
}

export default function ComposeContext(props: Props) {
  const { components = [], children } = props

  return (
      <>
          {components.reduceRight((acc, Comp) => {
              return <Comp>{acc}</Comp>
          }, children)}
      </>
  )
}
