import React, { createContext, useState } from 'react';

type IPartnerContext = [any, React.Dispatch<React.SetStateAction<any>>];

const PartnerContext = createContext<IPartnerContext>([[], () => {}]);

const PartnerProvider = ({ children }: { children: any }) => {
  const [partner, setPartner] = useState(null);
  
  return (
    <PartnerContext.Provider value={[partner, setPartner]}>
      {children}
    </PartnerContext.Provider>
  )
} 

export { PartnerContext, PartnerProvider };
