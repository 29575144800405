import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FaWeightScale } from 'react-icons/fa6';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import styles from './Weight.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../styles/FormStyles.css';

interface WeightProps {
  hideWeight: boolean;
  onFormClose: (formType: string) => void;
  weightItem: any;
}

interface Values {
  datetime: Date;
  weight: number;
  comment: string;
}

const Weight: FunctionComponent<WeightProps> = ({ hideWeight, onFormClose, weightItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [weight, setWeight] = useState(0);
  const [comment, setComment] = useState('');

  const sm = useMediaQuery('(max-width:416px)');

  const addWeight = async (data: any) => {
    const weightsCollection = collection(db, "trackingdata", user.userId, "weights");
    await setDoc(doc(weightsCollection), {
        datetime: data.datetime,
        weight: data.weight,
        comment: data.comment,
        babyId: baby.babyId
      });

    onFormClose('weight');
  }

  const updateWeight = async (data: any) => {
    const weightsCollection = collection(db, "trackingdata", weightItem.userId, "weights");
    const weightsDocRef = doc(weightsCollection, weightItem.id);

    try {
      await updateDoc(weightsDocRef, {
        datetime: data.datetime,
        weight: data.weight,
        comment: data.comment,
      });
    } catch (error) {
      console.error('Error updating weight:', error);
    }

    onFormClose('weight');
  }

  const deleteWeight = async () => {
    const weightsCollection = collection(db, "trackingdata", weightItem.userId, "weights");
    const weightsDocRef = doc(weightsCollection, weightItem.id);

    try {
      await deleteDoc(weightsDocRef);
    } catch (error) {
      console.error('Error deleting weight:', error);
    }

    onFormClose('weight');
  }

  const handleWeight = (value: string) => {
    if (value) {
      const weight = parseInt(value);
      setWeight(weight);
    } else {
      setWeight(0);
    }
  }

  const handleComment = (value: string) => {
    setComment(value);
  }

  const resetForm = () => {
    setStartDate(new Date());
    setWeight(0);
    setComment('');
  }

  useEffect(() => {
    if (weightItem != null) {
      const start = new Date(weightItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setWeight(weightItem.data.weight);
      setComment(weightItem.data.comment);
    }
  }, [weightItem]);

  useEffect(() => {
    if (weightItem == null) {
      resetForm();
    }
  }, [hideWeight]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          weight: weight,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (weightItem != null) {
            updateWeight(values);
          } else {
            addWeight(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <FaWeightScale style={headerIcon} />
              <span style={sm ? headerTitleSX : headerTitleMD}>Gewicht</span>
            </div>

            <div className="form-body">
              <div className="group-space datepicker-group">
                <CustomNowDateTimePicker 
                  startDate={startDate}
                  onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date);}}
                  onNowDate={setStartDate}
                />
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Gewicht</div>
                </Typography>
                <div>
                  <label style={{ color: 'white', fontSize: '1.2rem' }}>
                    <Field 
                      as="input" 
                      name="weight"
                      type="number"
                      onChange={(e: any) => handleWeight(e.target.value)} 
                      value={weight == 0 ? '' : weight}
                      className={styles.weightField} 
                    /> gram
                  </label>
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Opmerking</div>
                </Typography>
                <Field 
                  as="textarea" 
                  name="comment" 
                  onChange={(e: any) => handleComment(e.target.value)} 
                  value={comment} 
                  className="comment-field" 
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                { weightItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteWeight}
                    sx={{ 
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                } 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Weight;
