import { db } from "../services/firebase";
import { doc, getDoc, getDocs, updateDoc, deleteDoc, collection, query, where, limit, writeBatch, orderBy, startAt } from "firebase/firestore";
import { subDays, startOfDay } from 'date-fns';

export const findUserByUserId = async (userId: any) => {
  const usersRef = doc(db, "users", userId);
  const userSnap = await getDoc(usersRef);
  const userData = userSnap.data();

  return userData
}

export const getUserByEmail = async (email: string) => {
  const usersCollection = collection(db, 'users');
  const q = query(usersCollection, where('email', '==', email), limit(1));
  let foundUser: any = null;

  await getDocs(q)
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        foundUser = querySnapshot.docs[0].data();
      } else {
        console.log('No user found with that email.');
      }
    })
    .catch((error) => {
      console.error('Error getting users:', error);
    });

  return foundUser;
}

export const getChildrenData = async (userId: string, partnerId?: string) => {
  const babyCollection = collection(db, 'babies');
  let q = query(babyCollection, where('createdByUser', '==', userId));

  if (partnerId != "") {
    q = query(babyCollection, where('createdByUser', 'in', [userId, partnerId]));
  }

  const childrenArray: any = [];

  await getDocs(q)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const documentId = doc.id;

        const childObject = {
          data: data,
          babyId: documentId
        };
        childrenArray.push(childObject);
      });
    })
    .catch((error) => {
      console.error('Error getting documents:', error);
    });

  return childrenArray;
}

// #region get tracking data
export const getDiaperData = async (userId: string, partnerId: string, days: number) => {
  const diapers: any = [];
  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchDiapers = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const diaperCollection = collection(documentRef, 'diapers');
    const q = query(diaperCollection, orderBy('datetime'), startAt(pastDate)); // assuming 'timestamp' is the field name
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const diaperObject = {
        data: data,
        id: documentId,
        type: 'diaper',
        userId: id
      };

      diapers.push(diaperObject);
    });
  };

  await fetchDiapers(userId);
  if(partnerId !== '') {
    await fetchDiapers(partnerId);
  }

  return diapers;
}
export const getBreastfeedingData = async (userId: string, partnerId: string, days: number) => {
  const breastfeedings: any = [];

  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchBreastfeedings = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const breastfeedingCollection = collection(documentRef, 'breastfeedings');
    const q = query(breastfeedingCollection, orderBy('datetime'), startAt(pastDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const breastfeedingObject = {
        data: data,
        id: documentId,
        type: 'breastfeeding',
        userId: id
      };

      breastfeedings.push(breastfeedingObject);
    });
  };

  await fetchBreastfeedings(userId);
  if(partnerId !== '') {
    await fetchBreastfeedings(partnerId);
  }

  return breastfeedings;
}
export const getBottlefeedingData = async (userId: string, partnerId: string, days: number) => {
  const bottlefeedings: any = [];
  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchBottlefeedings = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const bottlefeedingCollection = collection(documentRef, 'bottlefeedings');
    const q = query(bottlefeedingCollection, orderBy('datetime'), startAt(pastDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const bottlefeedingObject = {
        data: data,
        id: documentId,
        type: 'bottlefeeding',
        userId: id
      };

      bottlefeedings.push(bottlefeedingObject);
    });
  };

  await fetchBottlefeedings(userId);
  if(partnerId !== '') {
    await fetchBottlefeedings(partnerId);
  }

  return bottlefeedings;
};
export const getPumpingData = async (userId: string, partnerId: string, days: number) => {
  const pumpings: any = [];
  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchPumpings = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const pumpingCollection = collection(documentRef, 'pumping');
    const q = query(pumpingCollection, orderBy('datetime'), startAt(pastDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const pumpingObject = {
        data: data,
        id: documentId,
        type: 'pumping',
        userId: id
      };

      pumpings.push(pumpingObject);
    });
  };

  await fetchPumpings(userId);
  if(partnerId !== '') {
    await fetchPumpings(partnerId);
  }

  return pumpings;
};
export const getTemperaturesData = async (userId: string, partnerId: string, days: number) => {
  const temperatures: any = [];
  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchTemperatures = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const temperatureCollection = collection(documentRef, 'temperatures');
    const q = query(temperatureCollection, orderBy('datetime'), startAt(pastDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const temperatureObject = {
        data: data,
        id: documentId,
        type: 'temperatures',
        userId: id
      };

      temperatures.push(temperatureObject);
    });
  };

  await fetchTemperatures(userId);
  if(partnerId !== '') {
    await fetchTemperatures(partnerId);
  }

  return temperatures;
};
export const getWeightsData = async (userId: string, partnerId: string, days: number) => {
  const weights: any = [];
  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchWeights = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const weightCollection = collection(documentRef, 'weights');
    const q = query(weightCollection, orderBy('datetime'), startAt(pastDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const weightObject = {
        data: data,
        id: documentId,
        type: 'weights',
        userId: id
      };

      weights.push(weightObject);
    });
  };

  await fetchWeights(userId);
  if(partnerId !== '') {
    await fetchWeights(partnerId);
  }

  return weights;
};
export const getMedicationData = async (userId: string, partnerId: string, days: number) => {
  const medications: any = [];
  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchMedications = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const medicationCollection = collection(documentRef, 'medications');
    const q = query(medicationCollection, orderBy('datetime'), startAt(pastDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const medicationObject = {
        data: data,
        id: documentId,
        type: 'medication',
        userId: id
      };

      medications.push(medicationObject);
    });
  };

  await fetchMedications(userId);
  if(partnerId !== '') {
    await fetchMedications(partnerId);
  }

  return medications;
};
export const getFoodData = async (userId: string, partnerId: string, days: number) => {
  const food: any = [];
  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchFood = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const foodCollection = collection(documentRef, 'food');
    const q = query(foodCollection, orderBy('datetime'), startAt(pastDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const foodObject = {
        data: data,
        id: documentId,
        type: 'food',
        userId: id
      };

      food.push(foodObject);
    });
  };

  await fetchFood(userId);
  if(partnerId !== '') {
    await fetchFood(partnerId);
  }

  return food;
};
export const getDrinkData = async (userId: string, partnerId: string, days: number) => {
  const drink: any = [];
  let pastDate = subDays(new Date(), days);
  pastDate = startOfDay(pastDate);

  const fetchDrink = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const drinkCollection = collection(documentRef, 'drink');
    const q = query(drinkCollection, orderBy('datetime'), startAt(pastDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const drinkObject = {
        data: data,
        id: documentId,
        type: 'drink',
        userId: id
      };

      drink.push(drinkObject);
    });
  };

  await fetchDrink(userId);
  if(partnerId !== '') {
    await fetchDrink(partnerId);
  }

  return drink;
};
export const getNotesData = async (userId: string, partnerId: string, days: number) => {
  const notes: any = [];
  let pastDate = subDays(new Date(), days); // get the date 'days' days in the past
  pastDate = startOfDay(pastDate); // set the time to the start of the day (midnight)

  const fetchNotes = async (id: string) => {
    const documentRef = doc(db, 'trackingdata', id);
    const noteCollection = collection(documentRef, 'notes');
    const q = query(noteCollection, orderBy('datetime'), startAt(pastDate)); // 'datetime' is the field name
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const documentId = doc.id;
      const noteObject = {
        data: data,
        id: documentId,
        type: 'notes',
        userId: id
      };

      notes.push(noteObject);
    });
  };

  await fetchNotes(userId);
  if(partnerId !== '') {
    await fetchNotes(partnerId);
  }

  return notes;
};
// #endregion get tracking data

export const addPartnerToUser = async (userId: string, partnerId: string) => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();

  const partnerRef = doc(db, 'users', partnerId);
  const partnerSnap = await getDoc(partnerRef);
  const partnerData = partnerSnap.data();

  let sync = false;
  if (userData && partnerData) {
    sync = partnerData.partnerId == userId ? true : false;
  }

  try {
    await updateDoc(userRef, {
      partnerId: partnerId,
      partnerSynced: sync
    });

    if (sync) {
      await updateDoc(partnerRef, {
        partnerSynced: sync,
      });
    }

    console.log(`Partner added successfully to user ${userId}`);
  } catch (error) {
    console.error('Error adding partner:', error);
  }
}

export const removePartnerFromUser = async (userId: string, partnerId: string) => {
  const userRef = doc(db, 'users', userId);
  const partnerRef = doc(db, 'users', partnerId);

  try {
    await updateDoc(userRef, {
      partnerId: "",
      partnerSynced: false
    });

    await updateDoc(partnerRef, {
      partnerId: "",
      partnerSynced: false,
    });
  } catch (error) {
    console.error('Error adding partner:', error);
  }
}

export const deleteBabyById = async (babyId: string) => {
  const babyRef = doc(db, 'babies', babyId);

  try {
    await deleteDoc(babyRef);
  } catch (error) {
    console.error('Error deleting baby:', error);
  }
}

export const deleteCollection = async (db: any, collectionPath: string, batchSize: number) => {
  try {
    const collectionRef = collection(db, collectionPath);
    const queryRef = query(collectionRef);

    let documentsToDelete: any = [];
    const querySnapshot = await getDocs(queryRef);

    if (querySnapshot.size === 0) {
      return;
    }

    querySnapshot.forEach((doc) => {
      documentsToDelete.push(doc.ref);
    });

    // Delete documents in batches
    for (let i = 0; i < documentsToDelete.length; i += batchSize) {
      const batch = writeBatch(db);

      const batchedDocs = documentsToDelete.slice(i, i + batchSize);
      batchedDocs.forEach((docRef: any) => {
        batch.delete(docRef);
      });

      await batch.commit();
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteDocumentsWithBabyId = async (db: any, collectionPath: string, babyId: string, batchSize: number) => {
  try {
    const collectionRef = collection(db, collectionPath);
    const queryRef = query(collectionRef, where("babyId", "==", babyId));

    let documentsToDelete: any = [];
    const querySnapshot = await getDocs(queryRef);

    if (querySnapshot.size === 0) {
      return;
    }

    querySnapshot.forEach((doc) => {
      documentsToDelete.push(doc.ref);
    });

    // Delete documents in batches
    for (let i = 0; i < documentsToDelete.length; i += batchSize) {
      const batch = writeBatch(db);

      const batchedDocs = documentsToDelete.slice(i, i + batchSize);
      batchedDocs.forEach((docRef: any) => {
        batch.delete(docRef);
      });

      await batch.commit();
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteAllUserBabies = async (db: any, collectionPath: string, userId: string, batchSize: number) => {
  try {
    const collectionRef = collection(db, collectionPath);
    const queryRef = query(collectionRef, where("createdByUser", "==", userId));

    let documentsToDelete: any = [];
    const querySnapshot = await getDocs(queryRef);

    if (querySnapshot.size === 0) {
      return;
    }

    querySnapshot.forEach((doc) => {
      documentsToDelete.push(doc.ref);
    });

    // Delete documents in batches
    for (let i = 0; i < documentsToDelete.length; i += batchSize) {
      const batch = writeBatch(db);

      const batchedDocs = documentsToDelete.slice(i, i + batchSize);
      batchedDocs.forEach((docRef: any) => {
        batch.delete(docRef);
      });

      await batch.commit();
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};