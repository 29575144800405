import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { PiSmiley, PiSmileyMeh, PiSmileySad } from "react-icons/pi";
import '../../styles/FormStyles.css';
import styles from './Reaction.module.css';

export type ReactionType = 'happy' | 'neutral' | 'sad';

interface ReactionButtonProps {
  reaction: ReactionType;
  selected: boolean;
  onClick: () => void;
}

const ReactionButton: React.FC<ReactionButtonProps> = ({ reaction, selected, onClick }) => (
  <button type="button" className={`${styles.reactionButton} ${selected ? styles.selected : ''}`} onClick={onClick}>
    {reaction === 'happy' && <PiSmiley style={{ color: 'white' }} />}
    {reaction === 'neutral' && <PiSmileyMeh style={{ color: 'white' }} />}
    {reaction === 'sad' && <PiSmileySad style={{ color: 'white' }} />}
  </button>
);

interface ReactionProps {
  onReaction: (reaction: ReactionType) => void;
  initReaction: ReactionType | '';
}

const Reaction: React.FC<ReactionProps> = ({ onReaction, initReaction }) => {
  const [selectedReaction, setSelectedReaction] = useState<ReactionType | ''>(initReaction);

  const handleClick = (reaction: ReactionType) => {
    setSelectedReaction(reaction);
    onReaction(reaction);
  };

  useEffect(() => {
    setSelectedReaction(initReaction);
  }, [initReaction]);

  return (
  <div className={`group-space ${styles.reactionComponent}`}>
    <Typography component={'span'} variant="h4">
      <div id="reaction-group">Reactie</div>
    </Typography>
    <div className={styles.reactionButtonGroup}>
      <ReactionButton reaction="happy" onClick={() => handleClick('happy')} selected={selectedReaction === 'happy'} />
      <ReactionButton reaction="neutral" onClick={() => handleClick('neutral')} selected={selectedReaction === 'neutral'} />
      <ReactionButton reaction="sad" onClick={() => handleClick('sad')} selected={selectedReaction === 'sad'} />
    </div>
  </div>
)};

export default Reaction;
