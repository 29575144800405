import React, { createContext, useState } from 'react';

type IChildrenContext = [any, React.Dispatch<React.SetStateAction<any>>];

const ChildrenContext = createContext<IChildrenContext>([[], () => {}]);

const ChildrenProvider = ({ children }: { children: any }) => {
  const [allChildren, setAllChildren] = useState(null);
  
  return (
    <ChildrenContext.Provider value={[allChildren, setAllChildren]}>
      {children}
    </ChildrenContext.Provider>
  )
} 

export { ChildrenContext, ChildrenProvider };
