import { useState, useEffect, useRef, FunctionComponent } from "react";
import { showTimeInMinAndSec } from '../../utils/Utils';
import './Stopwatch.css';

interface StopwatchProps {
  onTimeChange: (time: number) => void;
  onRunning: (running: boolean) => void;
  breastfeedingItem: number;
}

const Stopwatch: FunctionComponent<StopwatchProps> = ({ onTimeChange, onRunning, breastfeedingItem }) => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [timerStartTime, setTimerStartTime] = useState<number | null>(null);

  const lastDateSecond = useRef<number>(0);
  const absoluteStartTime = useRef<number>(0);

  const [elapsedTime, setElapsedTime] = useState<number>(0);

  // const seconds = Math.floor((time / 1000)) % 60;
  // const minutes = Math.floor((time / 1000) / 60);

  // Method to start and stop timer
  const startAndStop = () => {
    setIsRunning(!isRunning);
    onRunning(isRunning);

    // on start or stop, reset start time, so we have a new start point
    if (absoluteStartTime.current != 0) {
      absoluteStartTime.current = new Date().getTime() - elapsedTime;
    }
  };

  // Method to reset timer back to 0
  const reset = () => {
    setTime(0);

    lastDateSecond.current = 0;
    absoluteStartTime.current = 0;
    setElapsedTime(0);
    setTimerStartTime(null);
  };

  const addMinutes = (seconds: number) => {
    const miliseconds = seconds * 1000;
    setTime(prevTime => prevTime + miliseconds);
  };

  const checkSeconds = () => {
    const currentTime = new Date().getTime();
    if (lastDateSecond.current == 0) {
      lastDateSecond.current = currentTime;
      absoluteStartTime.current = currentTime;
    }

    // calculate when 1 second has passed
    const plus1second = lastDateSecond.current + 1000;
    const secondPassed = currentTime >= plus1second;
    if (secondPassed) {
      lastDateSecond.current = currentTime;

      // Check whether the number of seconds passed since the start is actually still correct with reality and then set the time
      const elapsedSinceStart = lastDateSecond.current - absoluteStartTime.current;
      setTime(elapsedSinceStart);
      setElapsedTime(elapsedSinceStart);
    }
  }

  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      // setting time from 0 to 1 every 1 milisecond
      intervalId = setInterval(() => checkSeconds(), 10);
    }
    return () => { clearInterval(intervalId); }
  }, [isRunning, time]);

  // Handler for when the page gets hidden by tab switch or lockscreen
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Page is hidden (lockscreen or tab switch)
        if (isRunning) {
          setTimerStartTime(new Date().getTime());
        }
      } else {
        // Page is visible
        if (isRunning) {
          const currentTime = new Date().getTime();
          if (timerStartTime !== null) {
            const elapsed = currentTime - timerStartTime;
            const elapsedReduced = elapsed / 10;
            setTime(prevTime => prevTime + elapsedReduced);
            setTimerStartTime(currentTime);
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isRunning, time]);

  useEffect(() => {
    onTimeChange(time);
  }, [time]);

  useEffect(() => {
    if (breastfeedingItem == 0) {
      // New timer, so don't set timer after this anymore
      setIsRunning(false);
      setTimerStartTime(null);
      setElapsedTime(0);
      lastDateSecond.current = 0;
      absoluteStartTime.current = 0;
      setTime(0);
    }
    if (breastfeedingItem != 0) {
      setTime(breastfeedingItem);
      setElapsedTime(breastfeedingItem);
      lastDateSecond.current = new Date().getTime();
      absoluteStartTime.current = new Date().getTime() - breastfeedingItem;
    }
  }, [breastfeedingItem]);

  return (
    <div className="stopwatch-container">
      <p className="stopwatch-time">
        {showTimeInMinAndSec(time)}
      </p>
      <div className="stopwatch-buttons">
        <button type="button" className="stopwatch-button" onClick={startAndStop}>
          {isRunning ? "Stop" : "Start"}
        </button>
        <button type="button" className="stopwatch-button" onClick={reset}>
          Reset
        </button>
      </div>
      <div className="add-time-group">
        <button type="button" className="add-time-btn" onClick={() => addMinutes(60)}>
          +1
        </button>
        <button type="button" className="add-time-btn" onClick={() => addMinutes(300)}>
          +5
        </button>
        <button type="button" className="add-time-btn" onClick={() => addMinutes(600)}>
          +10
        </button>
      </div>
    </div>
  );
}

export default Stopwatch;
