// #region imports
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { db } from "../../services/firebase";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { MdBabyChangingStation } from 'react-icons/md';
import { FaTemperatureHigh } from 'react-icons/fa';
import { FaWeightScale, FaPrescriptionBottleMedical, FaPersonBreastfeeding, FaMugHot } from 'react-icons/fa6';
import { BiSolidBowlHot } from "react-icons/bi";
import { BsPen } from 'react-icons/bs';
import { GiBabyBottle, GiRoundBottomFlask } from 'react-icons/gi';
import { TrackButton } from '../custom/TrackButton';
import { FbUserContext } from "../../context/FbUserContext";
import { UserContext } from "../../context/UserContext";
import { PartnerContext } from "../../context/PartnerContext";
import { BabyContext } from "../../context/BabyContext";
import { ChildrenContext } from "../../context/ChildrenContext";
import { TrackingDataContext } from "../../context/TrackingDataContext";
import { StatisticsContext } from "../../context/StatisticsContext";
import TrackingData from "../tracking-data/TrackingData";
import Diaper from "../diaper/Diaper";
import Breastfeeding from "../breastfeeding/Breastfeeding";
import Bottlefeeding from "../bottlefeeding/Bottlefeeding";
import Pumping from "../pumping/Pumping";
import Temperature from "../temperature/Temperature";
import Weight from "../weight/Weight";
import Medication from "../medication/Medication";
import Notes from "../notes/Notes";
import Food from "../food/Food";
import Drink from "../drink/Drink";
import Typography from "@mui/material/Typography";
import { findUserByUserId, getChildrenData, getDiaperData, getBreastfeedingData, getBottlefeedingData, getPumpingData, getTemperaturesData, getWeightsData, getMedicationData, getFoodData, getDrinkData, getNotesData } from "../../services/DataService";
import "./Dashboard.css";
// #endregion imports

const { format, differenceInMinutes, startOfMinute } = require('date-fns');

function Dashboard() {
  const [fbUser, setFbUser] = useContext(FbUserContext);
  const [user, setUser] = useContext(UserContext);
  const [partner, setPartner] = useContext(PartnerContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [allChildren, setAllChildren] = useContext(ChildrenContext);
  const [trackingData, setTrackingData] = useContext(TrackingDataContext);
  const [statistics, setStatistics] = useContext(StatisticsContext);

  const [loading, setLoading] = useState(true);
  const [hideDiaper, setHideDiaper] = useState(true);
  const [hideBreastfeeding, setHideBreastfeeding] = useState(true);
  const [hideBottlefeeding, setHideBottlefeeding] = useState(true);
  const [hidePumping, setHidePumping] = useState(true);
  const [hideTemperature, setHideTemperature] = useState(true);
  const [hideWeight, setHideWeight] = useState(true);
  const [hideMedication, setHideMedication] = useState(true);
  const [hideFood, setHideFood] = useState(true);
  const [hideDrink, setHideDrink] = useState(true);
  const [hideNote, setHideNote] = useState(true);
  const [closingForm, setClosingForm] = useState(false);

  // For editing forms
  const [diaperItem, setDiaperItem] = useState(null);
  const [breastfeedingItem, setBreastfeedingItem] = useState(null);
  const [bottlefeedingItem, setBottlefeedingItem] = useState(null);
  const [pumpingItem, setPumpingItem] = useState(null);
  const [temperatureItem, setTemperatureItem] = useState(null);
  const [weightItem, setWeightItem] = useState(null);
  const [medicationItem, setMedicationItem] = useState(null);
  const [foodItem, setFoodItem] = useState(null);
  const [drinkItem, setDrinkItem] = useState(null);
  const [noteItem, setNoteItem] = useState(null);

  const [lastBreastFeeding, setLastBreastFeeding] = useState(null);

  const findOrCreateUser = async (fbUser: any) => {
    const userData = await findUserByUserId(fbUser.uid);
    if (userData && (!userData.photoUrl || userData.photoUrl === "" || userData.photoUrl !== fbUser.photoURL)) {
      const userRef = doc(db, 'users', userData.userId);
      try {
        await updateDoc(userRef, {
          photoUrl: fbUser.photoURL,
        });
        console.log(`Photo for user id ${userData.userId} successfully updated`);
      } catch (error) {
        console.error('Error editing baby:', error);
      }
    }

    if (userData == null) {
      // User not yet present, so create new user
      const usersCollection = collection(db, "users");

      const joinDate = new Date();
      await setDoc(doc(usersCollection, fbUser.uid), {
        userId: fbUser.uid,
        email: fbUser.email,
        name: fbUser.displayName,
        photoUrl: fbUser.photoURL,
        partnerId: "",
        partnerSynced: false,
        joinDate: joinDate
      });

      const trackingCollection = collection(db, "trackingdata");
      await setDoc(doc(trackingCollection, fbUser.uid), {});

      await getAndSetUser(fbUser.uid);
    } else {
      if (user == null) {
        if (userData.partnerId != "") {
          const partnerData = await findUserByUserId(userData.partnerId);
          setPartner(partnerData);
        }

        setUser(userData);
      }
    }
  }

  const getAndSetUser = async (userId: any) => {
    const userData = await findUserByUserId(userId);

    setUser(userData);
  }

  const getData = async () => {
    let partnerId = "";
    if (partner && user.partnerSynced) {
      partnerId = partner.userId;
    }
    await getChildrenData(user.userId, partnerId).then((data) => {
      setAllChildren(data);
      // First child as default baby
      if (baby == null) {
        setBaby(data[0]);
      }
    })

    await getTrackingData(3);
  }

  const getTrackingData = async (days: number) => {
    // Get all user tracking data
    let partnerId = "";
    if (partner && user.partnerSynced) {
      partnerId = partner.userId;
    }

    let userStats = { user: 'me', diapers: 0, breastfeedings: 0, bottlefeedings: 0, pumpings: 0, temperatures: 0, weights: 0, medications: 0, food: 0, drink: 0, notes: 0 };
    let partnerStats = { user: 'partner', diapers: 0, breastfeedings: 0, bottlefeedings: 0, pumpings: 0, temperatures: 0, weights: 0, medications: 0, food: 0, drink: 0, notes: 0 };

    try {
      const promises = [
        getDiaperData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.diapers = userItems;
          partnerStats.diapers = partnerItems;

          return items;
        }),
        getBreastfeedingData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.breastfeedings = userItems;
          partnerStats.breastfeedings = partnerItems;

          // Order array and get the most recent breastfeeding
          items.sort((a: any, b: any) => b.data.datetime.seconds - a.data.datetime.seconds);
          if (items.length > 0) {
            setLastBreastFeeding(items[0]);
          }

          return items;
        }),
        getBottlefeedingData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.bottlefeedings = userItems;
          partnerStats.bottlefeedings = partnerItems;

          return items;
        }),
        getPumpingData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.pumpings = userItems;
          partnerStats.pumpings = partnerItems;

          return items;
        }),
        getTemperaturesData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.temperatures = userItems;
          partnerStats.temperatures = partnerItems;
          
          return items;
        }),
        getWeightsData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.weights = userItems;
          partnerStats.weights = partnerItems;
          
          return items;
        }),
        getMedicationData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.medications = userItems;
          partnerStats.medications = partnerItems;
          
          return items;
        }),
        getFoodData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.food = userItems;
          partnerStats.food = partnerItems;
          
          return items;
        }),
        getDrinkData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.drink = userItems;
          partnerStats.drink = partnerItems;
          
          return items;
        }),
        getNotesData(user.userId, partnerId, days).then((items: any) => {
          const userItems = items.reduce((count: number, item: any) => (item.userId === user.userId ? count + 1 : count), 0);
          const partnerItems = items.reduce((count: number, item: any) => (item.userId === partnerId ? count + 1 : count), 0);
          userStats.notes = userItems;
          partnerStats.notes = partnerItems;
          
          return items;
        })
      ];

      const allTrackingData = await Promise.all(promises).then((data: any) => {
        const mergedArray: any = [];
        data.forEach((array: any) => {
          mergedArray.push(...array);
        });

        // Sort all data arrays in descending order so the latest item is shown at the top of the grid
        mergedArray.sort((a: any, b: any) => b.data.datetime.seconds - a.data.datetime.seconds);

        // Calculate the interval between feedings and add interval time to item
        let currentFeedingItem: any;
        let previousFeedingItem: any;
        let modifiedFeedingItems: any[] = [];
        mergedArray.forEach((item: any) => {
          if (item.type === 'breastfeeding' || item.type === 'bottlefeeding' || item.type === 'food' || item.type === 'drink') {
            currentFeedingItem = item;
            let interval: string = "";

            if (previousFeedingItem) {
              // Calculate time between previousFeedingItem and currentFeedingItem
              interval = calculateInterval(currentFeedingItem, previousFeedingItem);

              // add interval result to previousFeedingItem in mergedArray!
              previousFeedingItem.interval = interval;
              modifiedFeedingItems.push(previousFeedingItem);
            }

            // then set item to previousFeedingItem for next comparison
            previousFeedingItem = item;
          }
        });

        // modifiedFeedingItems contains an extra interval property. Find the same items in the mergedArray and add the interval to those items:
        modifiedFeedingItems.forEach((modifiedItem) => {
          const index = mergedArray.findIndex((item: any) => item.id === modifiedItem.id);
          if (index !== -1) {
            mergedArray[index] = modifiedItem;
          }
        });

        return mergedArray;
      });

      const stats = [];
      stats.push(userStats);
      stats.push(partnerStats);
      setStatistics(stats);
      setTrackingData(allTrackingData);
    } catch (error) {
      // Handle any errors here
      console.error(error);
    }
  }

  const calculateInterval = (firstItem: any, secondItem: any) => {
    let endDateFirst = new Date(firstItem.data.datetime.seconds * 1000);
    let startDateSecond = new Date(secondItem.data.datetime.seconds * 1000);

    const roundedEndDate = startOfMinute(endDateFirst);
    const roundedStartDate = startOfMinute(startDateSecond);

    const diffMinutes = Math.abs(differenceInMinutes(roundedEndDate, roundedStartDate));
    
    const hours = Math.floor(diffMinutes / 60);
    const minutes = Math.floor(diffMinutes % 60);

    let timeString = "";
    if (hours > 0) {
      timeString += `${hours}h`;
    }
    if (minutes > 0) {
      timeString += `${minutes}m`;
    }
    if (hours == 0 && minutes == 0) {
      timeString += "0m"
    }

    return timeString;
  }

  const handleCloseForm = async (formType: string) => {
    setClosingForm(true);
    switch (formType) {
      case 'diaper': {
        setHideDiaper(true);
        break;
      }
      case 'breastfeeding': {
        setHideBreastfeeding(true);
        break;
      }
      case 'bottlefeeding': {
        setHideBottlefeeding(true);
        break;
      }
      case 'pumping': {
        setHidePumping(true);
        break;
      }
      case 'temperature': {
        setHideTemperature(true);
        break;
      }
      case 'weight': {
        setHideWeight(true);
        break;
      }
      case 'medication': {
        setHideMedication(true);
        break;
      }
      case 'food': {
        setHideFood(true);
        break;
      }
      case 'drink': {
        setHideDrink(true);
        break;
      }
      case 'note': {
        setHideNote(true);
        break;
      }
      default: {
        break;
      }
    }

    // refetch data
    await getTrackingData(3);
    setClosingForm(false);

    // scroll to top of page
    window.scrollTo(0, 0);
  }

  // #region open forms
  const handleHideDiaper = (open: boolean) => {
    setHideTemperature(true);
    setHideWeight(true);
    setHideBreastfeeding(true);
    setHideBottlefeeding(true);
    setHidePumping(true);
    setHideMedication(true);
    setHideNote(true);
    setHideFood(true);
    setHideDrink(true);

    setHideDiaper(open);
  }
  const handleHideBreastfeeding = (open: boolean) => {
    setHideDiaper(true);
    setHideWeight(true);
    setHideTemperature(true);
    setHideBottlefeeding(true);
    setHidePumping(true);
    setHideMedication(true);
    setHideNote(true);
    setHideFood(true);
    setHideDrink(true);

    setHideBreastfeeding(open);
  }
  const handleHideBottlefeeding = (open: boolean) => {
    setHideDiaper(true);
    setHideTemperature(true);
    setHideWeight(true);
    setHideBreastfeeding(true);
    setHidePumping(true);
    setHideMedication(true);
    setHideNote(true);
    setHideFood(true);
    setHideDrink(true);

    setHideBottlefeeding(open);
  }
  const handleHidePumping = (open: boolean) => {
    setHideDiaper(true);
    setHideTemperature(true);
    setHideWeight(true);
    setHideBreastfeeding(true);
    setHideBottlefeeding(true);
    setHideMedication(true);
    setHideNote(true);
    setHideFood(true);
    setHideDrink(true);

    setHidePumping(open);
  }
  const handleHideTemperature = (open: boolean) => {
    setHideDiaper(true);
    setHideWeight(true);
    setHideBreastfeeding(true);
    setHideBottlefeeding(true);
    setHidePumping(true);
    setHideMedication(true);
    setHideNote(true);
    setHideFood(true);
    setHideDrink(true);

    setHideTemperature(open);
  }
  const handleHideWeight = (open: boolean) => {
    setHideTemperature(true);
    setHideDiaper(true);
    setHideBreastfeeding(true);
    setHideBottlefeeding(true);
    setHidePumping(true);
    setHideMedication(true);
    setHideNote(true);
    setHideFood(true);
    setHideDrink(true);

    setHideWeight(open);
  }
  const handleHideMedication = (open: boolean) => {
    setHideTemperature(true);
    setHideWeight(true);
    setHideDiaper(true);
    setHideBreastfeeding(true);
    setHideBottlefeeding(true);
    setHidePumping(true);
    setHideNote(true);
    setHideFood(true);
    setHideDrink(true);

    setHideMedication(open);
  }
  const handleHideFood = (open: boolean) => {
    setHideTemperature(true);
    setHideWeight(true);
    setHideDiaper(true);
    setHideBreastfeeding(true);
    setHideBottlefeeding(true);
    setHidePumping(true);
    setHideMedication(true);
    setHideNote(true);
    setHideDrink(true);

    setHideFood(open);
  }
  const handleHideDrink = (open: boolean) => {
    setHideTemperature(true);
    setHideWeight(true);
    setHideDiaper(true);
    setHideBreastfeeding(true);
    setHideBottlefeeding(true);
    setHidePumping(true);
    setHideMedication(true);
    setHideNote(true);
    setHideFood(true);

    setHideDrink(open);
  }
  const handleHideNote = (open: boolean) => {
    setHideTemperature(true);
    setHideWeight(true);
    setHideBreastfeeding(true);
    setHideBottlefeeding(true);
    setHidePumping(true);
    setHideMedication(true);
    setHideDiaper(true);
    setHideFood(true);
    setHideDrink(true);

    setHideNote(open);
  }
  // #endregion open forms

  const handleItemClick = (item: any) => {
    switch (item.type) {
      case 'diaper': {
        setBreastfeedingItem(null);
        setBottlefeedingItem(null);
        setPumpingItem(null);
        setTemperatureItem(null);
        setWeightItem(null);
        setMedicationItem(null);
        setNoteItem(null);
        setFoodItem(null);
        setDrinkItem(null);
        setDiaperItem(item);
        handleHideDiaper(false);
        break;
      }
      case 'breastfeeding': {
        setDiaperItem(null);
        setBottlefeedingItem(null);
        setPumpingItem(null);
        setTemperatureItem(null);
        setWeightItem(null);
        setMedicationItem(null);
        setNoteItem(null);
        setFoodItem(null);
        setDrinkItem(null);
        setBreastfeedingItem(item);
        handleHideBreastfeeding(false);
        break;
      }
      case 'bottlefeeding': {
        setDiaperItem(null);
        setBreastfeedingItem(null);
        setPumpingItem(null);
        setTemperatureItem(null);
        setWeightItem(null);
        setMedicationItem(null);
        setNoteItem(null);
        setFoodItem(null);
        setDrinkItem(null);
        setBottlefeedingItem(item);
        handleHideBottlefeeding(false);
        break;
      }
      case 'pumping': {
        setDiaperItem(null);
        setBreastfeedingItem(null);
        setTemperatureItem(null);
        setWeightItem(null);
        setMedicationItem(null);
        setNoteItem(null);
        setBottlefeedingItem(null);
        setFoodItem(null);
        setDrinkItem(null);
        setPumpingItem(item);
        handleHidePumping(false);
        break;
      }
      case 'temperatures': {
        setDiaperItem(null);
        setBreastfeedingItem(null);
        setBottlefeedingItem(null);
        setPumpingItem(null);
        setWeightItem(null);
        setMedicationItem(null);
        setNoteItem(null);
        setFoodItem(null);
        setDrinkItem(null);
        setTemperatureItem(item);
        handleHideTemperature(false);
        break;
      }
      case 'weights': {
        setDiaperItem(null);
        setBreastfeedingItem(null);
        setBottlefeedingItem(null);
        setPumpingItem(null);
        setTemperatureItem(null);
        setMedicationItem(null);
        setNoteItem(null);
        setFoodItem(null);
        setDrinkItem(null);
        setWeightItem(item);
        handleHideWeight(false);
        break;
      }
      case 'medication': {
        setDiaperItem(null);
        setBreastfeedingItem(null);
        setBottlefeedingItem(null);
        setPumpingItem(null);
        setTemperatureItem(null);
        setWeightItem(null);
        setNoteItem(null);
        setFoodItem(null);
        setDrinkItem(null);
        setMedicationItem(item);
        handleHideMedication(false);
        break;
      }
      case 'food': {
        setDiaperItem(null);
        setBreastfeedingItem(null);
        setBottlefeedingItem(null);
        setPumpingItem(null);
        setTemperatureItem(null);
        setWeightItem(null);
        setMedicationItem(null);
        setNoteItem(null);
        setDrinkItem(null);
        setFoodItem(item);
        handleHideFood(false);
        break;
      }
      case 'drink': {
        setDiaperItem(null);
        setBreastfeedingItem(null);
        setBottlefeedingItem(null);
        setPumpingItem(null);
        setTemperatureItem(null);
        setWeightItem(null);
        setMedicationItem(null);
        setFoodItem(null);
        setNoteItem(null);
        setDrinkItem(item);
        handleHideDrink(false);
        break;
      }
      case 'notes': {
        setDiaperItem(null);
        setBreastfeedingItem(null);
        setBottlefeedingItem(null);
        setPumpingItem(null);
        setTemperatureItem(null);
        setWeightItem(null);
        setMedicationItem(null);
        setFoodItem(null);
        setDrinkItem(null);
        setNoteItem(item);
        handleHideNote(false);
        break;
      }
      default: {
        break;
      }
    }

    // scroll to top of page where form is
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (fbUser && user == null) {
        await findOrCreateUser(fbUser);
      }
    };

    fetchData();
  }, [fbUser]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (user != null) {
        await getData().then(() => {
          setLoading(false);
        });
      }
    };

    fetchData();
  }, [user]);

  return (
    <div>
      {loading ?
        <Typography className="no-baby-content" component={'span'} variant="body2">
          Loading Dashboard...
        </Typography>
        :
        <div>
          <div className="track-btn-container">
            <div className="track-btn-group">
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setDiaperItem(null); handleHideDiaper(!hideDiaper) }}
                  backgroundcolor="#eda200"
                  hovercolor="#ed8600">
                  <MdBabyChangingStation style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setBreastfeedingItem(null); handleHideBreastfeeding(!hideBreastfeeding) }}
                  backgroundcolor="#00b012"
                  hovercolor="#00820d">
                  <FaPersonBreastfeeding style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setBottlefeedingItem(null); handleHideBottlefeeding(!hideBottlefeeding) }}
                  backgroundcolor="#18d99c"
                  hovercolor="#00ad77">
                  <GiBabyBottle style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setPumpingItem(null); handleHidePumping(!hidePumping) }}
                  backgroundcolor="#34cdeb"
                  hovercolor="#2aa7bf">
                  <GiRoundBottomFlask style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setFoodItem(null); handleHideFood(!hideFood) }}
                  backgroundcolor="#eb5200"
                  hovercolor="#bd4200">
                  <BiSolidBowlHot style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setDrinkItem(null); handleHideDrink(!hideDrink) }}
                  backgroundcolor="#a8d600"
                  hovercolor="#88ad00">
                  <FaMugHot style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setTemperatureItem(null); handleHideTemperature(!hideTemperature) }}
                  backgroundcolor="#345ceb"
                  hovercolor="#2158c4">
                  <FaTemperatureHigh style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setWeightItem(null); handleHideWeight(!hideWeight) }}
                  backgroundcolor="#8702cf"
                  hovercolor="#7100ad">
                  <FaWeightScale style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setMedicationItem(null); handleHideMedication(!hideMedication) }}
                  backgroundcolor="#f02e2e"
                  hovercolor="#b50000">
                  <FaPrescriptionBottleMedical style={{ color: 'white' }} />
                </TrackButton>
              </div>
              <div>
                <TrackButton
                  disabled={baby == null}
                  sx={{ backgroundColor: baby == null ? 'grey' : null }}
                  onClick={() => { setNoteItem(null); handleHideNote(!hideNote) }}
                  backgroundcolor="#ff42b7"
                  hovercolor="#d62793">
                  <BsPen style={{ color: 'white' }} />
                </TrackButton>
              </div>
            </div>
          </div>

          <div className="no-baby-content" style={{ display: baby == null ? 'flex' : 'none', color: 'white' }}>
            <Typography component={'span'} variant="body2">
              Dit is jouw dashboard. Vanaf hier kun je data toevoegen en je recente activiteiten bekijken.
            </Typography>
            <Typography component={'span'} variant="body2">
              Ga naar de <Link to="/baby" className="babyLink">Baby</Link> pagina om een kind toe te voegen en te starten met tracken. Óf...
            </Typography>
            <Typography component={'span'} variant="body2">
              ...heeft jouw partner al een kind aangemaakt waar jullie beiden voor willen tracken?
              Ga dan naar de <Link to="/profile" className="babyLink">Profiel</Link> pagina om family sync te activeren.
            </Typography>
          </div>

          <div className="track-forms-group">
            <div className={hideDiaper ? 'hidden' : undefined}>
              <Diaper hideDiaper={hideDiaper} onFormClose={handleCloseForm} diaperItem={diaperItem} />
            </div>
            <div className={hideBreastfeeding ? 'hidden' : undefined}>
              <Breastfeeding hideBreastfeeding={hideBreastfeeding} onFormClose={handleCloseForm} lastBreastFeeding={lastBreastFeeding} breastfeedingItem={breastfeedingItem} />
            </div>
            <div className={hideBottlefeeding ? 'hidden' : undefined}>
              <Bottlefeeding hideBottlefeeding={hideBottlefeeding} onFormClose={handleCloseForm} bottlefeedingItem={bottlefeedingItem} />
            </div>
            <div className={hidePumping ? 'hidden' : undefined}>
              <Pumping hidePumping={hidePumping} onFormClose={handleCloseForm} pumpingItem={pumpingItem} />
            </div>
            <div className={hideTemperature ? 'hidden' : undefined}>
              <Temperature hideTemperature={hideTemperature} onFormClose={handleCloseForm} temperatureItem={temperatureItem} />
            </div>
            <div className={hideWeight ? 'hidden' : undefined}>
              <Weight hideWeight={hideWeight} onFormClose={handleCloseForm} weightItem={weightItem} />
            </div>
            <div className={hideMedication ? 'hidden' : undefined}>
              <Medication hideMedication={hideMedication} onFormClose={handleCloseForm} medicationItem={medicationItem} />
            </div>
            <div className={hideFood ? 'hidden' : undefined}>
              <Food hideFood={hideFood} onFormClose={handleCloseForm} foodItem={foodItem} />
            </div>
            <div className={hideDrink ? 'hidden' : undefined}>
              <Drink hideDrink={hideDrink} onFormClose={handleCloseForm} drinkItem={drinkItem} />
            </div>
            <div className={hideNote ? 'hidden' : undefined}>
              <Notes hideNote={hideNote} onFormClose={handleCloseForm} noteItem={noteItem} />
            </div>
          </div>

          <div>
            <TrackingData onItemClick={handleItemClick} closingForm={closingForm} />
          </div>
        </div>
      }
    </div>
  );
}

export default Dashboard;
