import { createTheme } from '@mui/material/styles';
import { styled } from "@mui/material/styles";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';

// Custom now datepickertheme
export const nowdatepickertheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: '2px solid #F0FFFF',
          color: '#F0FFFF',
          '@media (max-width: 416px)': {
              fontSize: '0.8rem',
          },
        },
      },
    }
  },
});

export const datepickertheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            padding: 0,
            color: '#F0FFFF',
            border: 'none',
            textAlign: 'right',
            boxSizing: 'border-box',
            textDecoration: 'underline',
            textDecorationColor: '#999999',
            textUnderlineOffset: '5px',
            fontSize: '0.9rem'
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiOutlinedInput-notchedOutline ': {
            border: 'none'
          }
        }
      }
    }
  },
});

export const OpenCalendarIcon = styled(EventIcon)(() => ({
  color: '#F0FFFF'
}));
export const OpenTimeIcon = styled(AccessTimeIcon)(() => ({
  color: '#F0FFFF'
}));