import React, { createContext, useState } from 'react';

type IFbUserContext = [any, React.Dispatch<React.SetStateAction<any>>];

const FbUserContext = createContext<IFbUserContext>([[], () => {}]);

const FbUserProvider = ({ children }: { children: any }) => {
  const [fbUser, setFbUser] = useState(null);
  
  return (
    <FbUserContext.Provider value={[fbUser, setFbUser]}>
      {children}
    </FbUserContext.Provider>
  )
} 

export { FbUserContext, FbUserProvider };
