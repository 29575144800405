import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FaTemperatureHigh } from 'react-icons/fa';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import styles from './Temperature.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../styles/FormStyles.css';

interface TemperatureProps {
  hideTemperature: boolean;
  onFormClose: (formType: string) => void;
  temperatureItem: any;
}

interface Values {
  datetime: Date;
  temperature: string;
  comment: string;
}

const Temperature: FunctionComponent<TemperatureProps> = ({ hideTemperature, onFormClose, temperatureItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [temperature, setTemperature] = useState('36.5');
  const [comment, setComment] = useState('');

  const sm = useMediaQuery('(max-width:416px)');

  const addTemperature = async (data: any) => {
    const temperaturesCollection = collection(db, "trackingdata", user.userId, "temperatures");
    await setDoc(doc(temperaturesCollection), {
        datetime: data.datetime,
        temperature: data.temperature,
        comment: data.comment,
        babyId: baby.babyId
      });

    onFormClose('temperature');
  }

  const updateTemperature = async (data: any) => {
    const temperaturesCollection = collection(db, "trackingdata", temperatureItem.userId, "temperatures");
    const temperaturesDocRef = doc(temperaturesCollection, temperatureItem.id);

    try {
      await updateDoc(temperaturesDocRef, {
        datetime: data.datetime,
        temperature: data.temperature,
        comment: data.comment,
      });
    } catch (error) {
      console.error('Error updating temperature:', error);
    }

    onFormClose('temperature');
  }

  const deleteTemperature = async () => {
    const temperaturesCollection = collection(db, "trackingdata", temperatureItem.userId, "temperatures");
    const temperaturesDocRef = doc(temperaturesCollection, temperatureItem.id);

    try {
      await deleteDoc(temperaturesDocRef);
    } catch (error) {
      console.error('Error deleting temperature:', error);
    }

    onFormClose('temperature');
  }

  const handleTemperature = (value: string) => {
    setTemperature(value);
  }

  const handleComment = (value: string) => {
    setComment(value);
  }

  const resetForm = () => {
    setStartDate(new Date());
    setTemperature('36.5');
    setComment('');
  }

  useEffect(() => {
    if (temperatureItem != null) {
      const start = new Date(temperatureItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setTemperature(temperatureItem.data.temperature);
      setComment(temperatureItem.data.comment);
    }
  }, [temperatureItem]);

  useEffect(() => {
    if (temperatureItem == null) {
      resetForm();
    }
  }, [hideTemperature]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          temperature: temperature,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (temperatureItem != null) {
            updateTemperature(values);
          } else {
            addTemperature(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <FaTemperatureHigh style={headerIcon} />
              <span style={sm ? headerTitleSX : headerTitleMD}>Temperatuur</span>
            </div>

            <div className="form-body">
              <div className="group-space datepicker-group">
                <CustomNowDateTimePicker 
                  startDate={startDate}
                  onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date);}}
                  onNowDate={setStartDate}
                />
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Temperatuur</div>
                </Typography>
                <div>
                  <label>
                    <Field 
                      as="input" 
                      name="temperature"
                      type="number"
                      onChange={(e: any) => handleTemperature(e.target.value)} 
                      value={temperature} 
                      className={styles.temperatureField} 
                    />
                  </label>
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Opmerking</div>
                </Typography>
                <Field 
                  as="textarea" 
                  name="comment" 
                  onChange={(e: any) => handleComment(e.target.value)} 
                  value={comment} 
                  className="comment-field" 
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                { temperatureItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteTemperature}
                    sx={{ 
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                } 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Temperature;
