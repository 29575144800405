import { useState, useEffect, useContext, FunctionComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Formik, Form, Field } from 'formik';
import { FaBaby } from 'react-icons/fa6';
import { UserContext } from '../../context/UserContext';
import { db } from "../../services/firebase";
import { doc, collection, setDoc, updateDoc } from "firebase/firestore";
import BirthDayDatePicker from '../datepicker/BirthdayDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Typography } from '@mui/material';
import * as Yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './Baby.module.css';
import { headerIcon } from '../../styles/IconStyles';
import { nowdatepickertheme, OpenTimeIcon } from '../datepicker/Datepickertheme';
import { ThemeProvider } from '@mui/material/styles';
import '../../styles/FormStyles.css';


export interface DialogProps {
  open: boolean;
  onClose: () => void;
  babyItem: any;
}

interface Values {
  name: string;
  gender: string;
  birthday: Date;
  birthTime: Date;
  birthweight: number;
}

const BabyFormValidationSchema = Yup.object().shape({
  name: Yup.string().required('Vul een naam in')
});

const BabyDialog: FunctionComponent<DialogProps> = ({ open, onClose, babyItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [name, setName] = useState('');
  const [gender, setGender] = useState('Boy');
  const [birthday, setBirthday] = useState(new Date());
  const [birthtime, setBirthtime] = useState(new Date());
  const [birthweight, setBirthweight] = useState(0);

  const sm = useMediaQuery('(max-width:416px)');

  const handleName = (value: string) => {
    setName(value);
  }
  const handleBirthday = (value: Date) => {
    setBirthday(value);
  }
  const handleBirthtime = (value: Date) => {
    setBirthtime(value);
  }
  const handleWeight = (value: string) => {
    if (value) {
      const weight = parseInt(value);
      setBirthweight(weight);
    } else {
      setBirthweight(0);
    }
  }

  const addBaby = async (data: any) => {
    const babyCollection = collection(db, "babies");
    await setDoc(doc(babyCollection), {
      name: data.name,
      gender: data.gender,
      birthday: data.birthday,
      birthtime: data.birthtime,
      birthweight: data.birthweight,
      createdByUser: user.userId
    });

    resetForm();
    onClose();
  }

  const editBaby = async (data: any) => {
    const babyRef = doc(db, 'babies', babyItem.babyId);
    try {
      await updateDoc(babyRef, {
        name: data.name,
        gender: data.gender,
        birthday: data.birthday,
        birthtime: data.birthtime,
        birthweight: data.birthweight
      });
  
      console.log(`Baby id ${babyItem.babyId} successfully edited`);
    } catch (error) {
      console.error('Error editing baby:', error);
    }

    resetForm();
    onClose();
  }

  const resetForm = () => {
    setName('');
    setGender('Boy');
    setBirthday(new Date());
    setBirthtime(new Date());
    setBirthweight(0);
  }

  useEffect(() => {
    if (babyItem != null) {
      setName(babyItem.data.name);
      setGender(babyItem.data.gender);
      // Convert firestore timestamp to Date
      const birthDate = new Date(babyItem.data.birthday.seconds * 1000);
      setBirthday(birthDate);
      const birthTime = new Date(babyItem.data.birthtime.seconds * 1000);
      setBirthtime(birthTime);
      setBirthweight(babyItem.data.birthweight);
    }
  }, [babyItem]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={'sm'}
      PaperProps={{
        style: {
          backgroundColor: '#444654',
          padding: '20px'
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: name,
          gender: gender,
          birthday: birthday,
          birthtime: birthtime,
          birthweight: birthweight
        }}
        validationSchema={BabyFormValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          if (babyItem != null) {
            editBaby(values);
          } else {
            addBaby(values);
          }
        }}
      >
        {({ touched, errors, values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <FaBaby style={headerIcon} />
            </div>

            <div className="form-body">
              <div>
                <Typography component={'span'} variant="h4">
                  <div>Naam</div>
                </Typography>
                <div>
                  <label style={{ color: 'white', fontSize: '1.2rem' }}>
                    <Field
                      as="input"
                      name="name"
                      onChange={(e: any) => handleName(e.target.value)}
                      value={name}
                      className={styles.nameField}
                    />
                    {(errors.name && touched.name) ? <div className={styles.formError}>{errors.name}</div> : null}
                  </label>
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Geslacht</div>
                </Typography>
                <div className={`${styles.radioGroup}`} role="group" aria-labelledby="my-radio-group" style={{ marginTop: '5px' }}>
                  <label
                    onClick={() => { setFieldValue("gender", "Boy"); setGender('Boy') }}
                    style={{ backgroundColor: gender === "Boy" ? '#00b026' : 'transparent', color: gender === "Boy" ? '#000' : '#F0FFFF' }}>
                    Jongen
                  </label>
                  <label
                    onClick={() => { setFieldValue("gender", "Girl"); setGender('Girl') }}
                    style={{ backgroundColor: gender === "Girl" ? '#00b026' : 'transparent', color: gender === "Girl" ? '#000' : '#F0FFFF' }}>
                    Meisje
                  </label>
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Geboortedatum</div>
                </Typography>
                <div className={styles.birthdayPicker}>
                  <BirthDayDatePicker
                    birthday={birthday}
                    onDateChange={(date: any) => { setFieldValue("birthday", date); handleBirthday(date); }}
                  />
                </div>
              </div>

              <div className={`group-space ${styles.spaceGroup}`}>
                <Typography component={'span'} variant="h4">
                  <div>Geboortetijd</div>
                </Typography>
                <div className={styles.birthTimePicker} style={{ width: sm ? '64%' : 'auto', float: 'left' }}>
                  <ThemeProvider theme={nowdatepickertheme}>
                    <TimePicker 
                      value={birthtime} 
                      onChange={(date: any) => { setFieldValue("birthtime", date); handleBirthtime(date); }} 
                      slots={{
                        openPickerIcon: OpenTimeIcon
                      }}
                    />
                  </ThemeProvider>
                </div>
              </div>

              <div className={`group-space ${styles.spaceGroup}`}>
                <Typography component={'span'} variant="h4">
                  <div>Geboortegewicht</div>
                </Typography>
                <div>
                  <label style={{ color: 'white' }}>
                    <Field 
                      as="input" 
                      name="birthweight"
                      type="number"
                      onChange={(e: any) => handleWeight(e.target.value)} 
                      value={birthweight == 0 ? '' : birthweight}
                      className={styles.weightField} 
                    /> gram
                  </label>
                </div>
              </div>

              <div className={styles.saveBtn}>
                <Button 
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default BabyDialog;
