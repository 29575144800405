import { useState, useEffect, FunctionComponent } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './swiper-override.css';
import styles from './DurationPicker.module.css';

interface DurationPickerProps {
  pauseTime: Duration;
  onHoursChange: (hours: number) => void;
  onMinutesChange: (minutes: number) => void;
  onSecondsChange: (seconds: number) => void;
}

export interface Duration {
  hours: number;
  minutes: number;
  seconds: number;
}

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const DurationPicker: FunctionComponent<DurationPickerProps> = ({ pauseTime, onHoursChange, onMinutesChange, onSecondsChange }) => {
  const [hours, setHours] = useState(pauseTime.hours);
  const [minutes, setMinutes] = useState(pauseTime.minutes);
  const [seconds, setSeconds] = useState(pauseTime.seconds);
  const [swiperHours, setSwiperHours] = useState<any>(null);
  const [swiperMinutes, setSwiperMinutes] = useState<any>(null);
  const [swiperSeconds, setSwiperSeconds] = useState<any>(null);
  const hoursArray = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const secondsOrMinutesArray = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  const handleHoursChange = (swiper: any) => {
    const newHours = swiper.realIndex;
    setHours(newHours);
    onHoursChange(newHours);
  };

  const handleMinutesChange = (swiper: any) => {
    const newMinutes = swiper.realIndex;
    setMinutes(newMinutes);
    onMinutesChange(newMinutes);
  };

  const handleSecondsChange = (swiper: any) => {
    const newSeconds = swiper.realIndex;
    setSeconds(newSeconds);
    onSecondsChange(newSeconds);
  };

  useEffect(() => {
    if (pauseTime != null && (pauseTime.hours !== hours || pauseTime.minutes !== minutes || pauseTime.seconds !== seconds)) {
      if ((swiperHours && swiperMinutes && swiperSeconds) && (pauseTime.hours === 0 && pauseTime.minutes === 0 && pauseTime.seconds === 0)) {
        swiperHours.slideTo(0);
        swiperMinutes.slideTo(0);
        swiperSeconds.slideTo(0);
      }

      setHours(pauseTime.hours);
      setMinutes(pauseTime.minutes);
      setSeconds(pauseTime.seconds);
    }
  }, [pauseTime]);

  return (
    <div className={styles.swiperWrapper}>
      <label className={styles.label}>
        <span className={styles.header}>U</span>
        <Swiper
          style={{ height: '40px', width: '40px', color: '#fff' }}
          direction={'vertical'}
          freeMode={true}
          loop={true}
          speed={1}
          initialSlide={hours}
          onSlideChange={handleHoursChange}
          onSwiper={setSwiperHours}
        >
          {hoursArray.map((hour, index) => (
            <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{hour}</SwiperSlide>
          ))}
        </Swiper>
      </label>
      <label className={styles.label}>
        <span className={styles.header}>Min</span>
        <Swiper
          style={{ height: '40px', width: '40px', color: '#fff' }}
          direction={'vertical'}
          freeMode={true}
          loop={true}
          speed={1}
          initialSlide={minutes}
          onSlideChange={handleMinutesChange}
          onSwiper={setSwiperMinutes}
        >
          {secondsOrMinutesArray.map((minute, index) => (
            <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{minute}</SwiperSlide>
          ))}
        </Swiper>
      </label>
      <label className={styles.label}>
        <span className={styles.header}>Sec</span>
        <Swiper
          style={{ height: '40px', width: '40px', color: '#fff' }}
          direction={'vertical'}
          freeMode={true}
          loop={true}
          speed={1}
          initialSlide={seconds}
          onSlideChange={handleSecondsChange}
          onSwiper={setSwiperSeconds}
        >
          {secondsOrMinutesArray.map((second, index) => (
            <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{second}</SwiperSlide>
          ))}
        </Swiper>
      </label>
    </div>
  );
};

export default DurationPicker;
