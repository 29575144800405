import { useContext, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { BsFillPersonFill } from 'react-icons/bs';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { TbDiscountCheck } from 'react-icons/tb';
import { UserContext } from '../../context/UserContext';
import { PartnerContext } from "../../context/PartnerContext";
import { BabyContext } from '../../context/BabyContext';
import { StatisticsContext } from '../../context/StatisticsContext';
import { TrackingDataContext } from "../../context/TrackingDataContext";
import { getUserByEmail, addPartnerToUser, removePartnerFromUser, findUserByUserId } from '../../services/DataService';
import DeleteAccountDialog from './DeleteAccountDialog';
import { CSVLink } from "react-csv";
import useMediaQuery from '@mui/material/useMediaQuery';
import './Profile.css';

const { format } = require('date-fns');

const stylesSM = {
  fontSize: '1.3rem',
  marginRight: '10px',
  verticalAlign: '-12%'
}
const stylesMD = {
  fontSize: '1.5rem',
  marginRight: '10px',
  verticalAlign: '-8%'
}

const Checkbox = ({ label, value, onChange }: any) => {
  return (
    <label>
      <input type="checkbox" checked={value} onChange={onChange} />
      {label}
    </label>
  );
};

function Profile() {
  const [user, setUser] = useContext(UserContext);
  const [partner, setPartner] = useContext(PartnerContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [statistics, setStatistics] = useContext(StatisticsContext);
  const [trackingData, setTrackingData] = useContext(TrackingDataContext);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(false);
  const sm = useMediaQuery('(max-width:416px)');

  const [exportDiapers, setExportDiapers] = useState(false);
  const [exportBreastfeedings, setExportBreastfeedings] = useState(false);
  const [exportBottlefeedings, setExportBottlefeedings] = useState(false);
  const [exportPumpings, setExportPumpings] = useState(false);
  const [exportTemperatures, setExportTemperatures] = useState(false);
  const [exportWeights, setExportWeights] = useState(false);
  const [exportMedications, setExportMedications] = useState(false);
  const [exportFood, setExportFood] = useState(false);
  const [exportDrink, setExportDrink] = useState(false);
  const [exportNotes, setExportNotes] = useState(false);

  const headers = [
    { label: "Datum", key: "date" },
    { label: "Tijd", key: "time" },
    { label: "Actie", key: "action" },
    { label: "Info", key: "info" },
    { label: "Opmerking", key: "comment" },
    { label: "Gebruiker", key: "user" }
  ];
  const [data, setData] = useState<any[]>([]);

  const handleExportChange = (activity: string) => {
    switch (activity) {
      case 'diaper': { setExportDiapers(!exportDiapers); break; }
      case 'breastfeeding': { setExportBreastfeedings(!exportBreastfeedings); break; }
      case 'bottlefeeding': { setExportBottlefeedings(!exportBottlefeedings); break; }
      case 'pumping': { setExportPumpings(!exportPumpings); break; }
      case 'temperature': { setExportTemperatures(!exportTemperatures); break; }
      case 'weight': { setExportWeights(!exportWeights); break; }
      case 'medication': { setExportMedications(!exportMedications); break; }
      case 'food': { setExportFood(!exportFood); break; }
      case 'drink': { setExportDrink(!exportDrink); break; }
      case 'note': { setExportNotes(!exportNotes); break; }
      default: { break; }
    }
  };

  const handleExport = () => {
    const dataToExport: any[] = [];
    trackingData.map((item: any) => {
      if (((item.type == 'diaper' && exportDiapers) || (item.type == 'breastfeeding' && exportBreastfeedings) || (item.type == 'bottlefeeding' && exportBottlefeedings) || (item.type == 'pumping' && exportPumpings) ||
          (item.type == 'temperatures' && exportTemperatures) || (item.type == 'weights' && exportWeights) || (item.type == 'medication' && exportMedications) || (item.type == 'food' && exportFood) || (item.type == 'drink' && exportDrink) || (item.type == 'notes' && exportNotes)) &&
          item.data.babyId == baby.babyId) {
        // Set Datum en Tijd
        const datum = format(new Date(item.data.datetime.seconds * 1000), "dd-MM-yyyy");
        const tijd = format(new Date(item.data.datetime.seconds * 1000), "HH:mm");

        // Set Actie
        let action = '';
        switch (item.type) {
          case 'diaper': { action = 'Verschonen'; break; }
          case 'breastfeeding': { action = 'Borstvoeding'; break; }
          case 'bottlefeeding': { action = 'Flesvoeding'; break; }
          case 'pumping': { action = 'Kolven'; break; }
          case 'temperatures': { action = 'Temperatuur'; break; }
          case 'weights': { action = 'Gewicht'; break; }
          case 'medication': { action = 'Medicatie'; break; }
          case 'food': { action = 'Eten'; break; }
          case 'drink': { action = 'Eten'; break; }
          case 'notes': { action = 'Notitie'; break; }
          default: { action = ''; break; }
        }
  
        // Set Info
        let info = '';
        switch (item.type) {
          case 'diaper': { info = formatDiaperData(item); break; }
          case 'breastfeeding': { info = formatBreastfeedingData(item); break; }
          case 'bottlefeeding': { info = formatBottlefeedingData(item); break; }
          case 'pumping': { info = formatPumpingData(item); break; }
          case 'temperatures': { info = item.data.temperature; break; }
          case 'weights': { info = item.data.weight + ' gram'; break; }
          case 'medication': { info = formatMedicationData(item); break; }
          case 'food': { info = formatFoodData(item); break; }
          case 'drink': { info = formatDrinkData(item); break; }
          case 'notes': { info = item.data.note; break; }
          default: { info = ''; break; }
        }

        // Set Opmerking
        const comment = item.data.comment;
  
        // Set Gebruiker
        let userName = '';
        if (item.userId == user.userId) {
          userName = user.name;
        } else if (partner && item.userId == partner.userId) {
          userName = partner.name;
        }

        const result = { date: datum, time: tijd, action: action, info: info, comment: comment, user: userName };
        dataToExport.push(result);
      }
    });
    setData(dataToExport);
  }

  const formatDiaperData = (item: any) => {
    let status = '';
    switch (item.data.diaperStatus) {
      case 'Wet': { status = 'Nat'; break; }
      case 'Dirty': { status = 'Vuil'; break; }
      case 'Mixed': { status = 'Gemengd'; break; }
      case 'Dry': { status = 'Droog'; break; }
      default: { status = ''; break; }
    }
    return status;
  }

  const formatBreastfeedingData = (item: any) => {
    const timeLeft = calculateBreastFeedingTimes(item.data.timeLeftBreast);
    const timeRight = calculateBreastFeedingTimes(item.data.timeRightBreast);
    
    let dataString = '';
    if (item.data.startSide == 'L') {
      dataString += 'L: ' + timeLeft + ', R: ' + timeRight;
    } else {
      dataString += 'R: ' + timeRight + ', L: ' + timeLeft;
    }
    return dataString;
  }

  const formatBottlefeedingData = (item: any) => {
    let dataString = '';
    if (item.data.milkType == 'Formula') {
      dataString += item.data.bottleAmount + ' ml kunstvoeding'
    } else {
      dataString += item.data.bottleAmount + ' ml moedermelk'
    }
    return dataString;
  }

  const formatPumpingData = (item: any) => {
    const timeLeft = calculateBreastFeedingTimes(item.data.timeLeftBreast);
    const timeRight = calculateBreastFeedingTimes(item.data.timeRightBreast);
    const timeDouble = calculateBreastFeedingTimes(item.data.timeDoublePumping);
    const leftPumpAmount = item.data.leftAmount !== '' ? item.data.leftAmount : '0';
    const rightPumpAmount = item.data.rightAmount !== '' ? item.data.rightAmount : '0';

    let leftDataString = '';
    let rightDataString = '';
    let totalAmount = 0;
    let totalDataString = '';
    let totalDoubleTime = '';
    let dataString = '';
    if (item.data.singleDoublePumping === 'single') {
      leftDataString = `L: ${leftPumpAmount}${timeLeft === '00:00' ? 'ml' : `ml, ${timeLeft}min`}`;
      rightDataString = ` - R: ${rightPumpAmount}${timeRight === '00:00' ? 'ml' : `ml, ${timeRight}min`}`;
      totalAmount = parseInt(leftPumpAmount) + parseInt(rightPumpAmount);
      totalDataString = ` - Totaal: ${totalAmount}ml`;
      dataString = leftDataString + rightDataString + totalDataString;
    } else {
      leftDataString = `L: ${leftPumpAmount}ml`;
      rightDataString = ` - R: ${rightPumpAmount}ml`;
      totalAmount = parseInt(leftPumpAmount) + parseInt(rightPumpAmount);
      totalDataString = ` - Totaal: ${totalAmount}ml`;
      totalDoubleTime = ` - ${timeDouble}min`;
      dataString = leftDataString + rightDataString + totalDataString + totalDoubleTime;
    }

    return dataString;
  }

  const formatMedicationData = (item: any) => {
    let dataString = '';
    if (item.data.vitamineK && item.data.vitamineD) {
      dataString += 'Vitamine K & D';
    } else if (item.data.vitamineK && !item.data.vitamineD) {
      dataString += 'Vitamine K';
    } else if (!item.data.vitamineK && item.data.vitamineD) {
      dataString += 'Vitamine D';
    }

    if (item.data.medicine != '') {
      if (dataString != '') dataString += ', ';
      dataString += item.data.medicine;
      if (item.data.amount != '') {
        dataString += ' ' + item.data.amount;
      }
    }

    return dataString;
  }

  const formatFoodData = (item: any) => {
    let dataString = '';
    if (item.data.food != '') {
      if (dataString != '') dataString += ', ';
      dataString += item.data.food;
      if (item.data.amount != 0) {
        dataString += ' ' + item.data.amount;
      }
    }

    return dataString;
  }

  const formatDrinkData = (item: any) => {
    let dataString = '';
    if (item.data.drink != '') {
      if (dataString != '') dataString += ', ';
      dataString += item.data.drink;
      if (item.data.amount != 0) {
        dataString += ' ' + item.data.amount;
      }
    }

    return dataString;
  }

  const calculateBreastFeedingTimes = (time: number) => {
    const seconds = Math.floor((time / 1000)) % 60;
    const minutes = Math.floor((time / 1000) / 60);

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  const handleDeleteAccountOpen = () => {
    setOpen(true);
  };

  const handleDeleteAccountClose = () => {
    setOpen(false);
  };

  const handleEmail = (email: string) => {
    setEmail(email);
  }

  const handleSync = async () => {
    // Check for valid email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }

    const foundPartner = await getUserByEmail(email);

    if (foundPartner != null) {
      await addPartnerToUser(user.userId, foundPartner.userId);
      const updatedUser = await findUserByUserId(user.userId).then((data: any) => {
        setUser(data);
      });

      // In case the partnerSynced property changed, refetch partner again
      const updatedPartner = await getUserByEmail(email).then((partner: any) => {
        setPartner(partner);
      });
    }
  }

  const handleUnSync = async () => {
    if (partner) {
      await removePartnerFromUser(user.userId, partner.userId).then(() => {
        setPartner(null);
      });
    }
  }

  return (
    <Box>
      {user ? (
        <div>
          <div className="box-root">
            <Typography variant="h2" className="profile-header">
              Jouw Account
            </Typography>
            <Typography variant="body2" className="profile-data">
              <BsFillPersonFill style={sm ? stylesSM : stylesMD} /> {user.name}
            </Typography>
            <Typography variant="body2" className="profile-data">
              <MdOutlineAlternateEmail style={sm ? stylesSM : stylesMD} /> {user.email}
            </Typography>
            <Typography variant="body2" className="profile-data">
              <TbDiscountCheck style={sm ? stylesSM : stylesMD} /> Lid sinds {format(user.joinDate.toDate(), "dd MMMM yyyy HH:mm")}
            </Typography>

            {statistics && statistics.length > 0 ?
              <div>
                <Typography variant="h2" className="stats-header">
                  Statistieken afgelopen 3 dagen
                </Typography>
                <Typography variant="body2">
                  Luiers: {statistics.find((x: any) => x.user == 'me').diapers}
                </Typography>
                <Typography variant="body2">
                  Borstvoedingen: {statistics.find((x: any) => x.user == 'me').breastfeedings}
                </Typography>
                <Typography variant="body2">
                  Flesvoedingen: {statistics.find((x: any) => x.user == 'me').bottlefeedings}
                </Typography>
                <Typography variant="body2">
                  Kolven: {statistics.find((x: any) => x.user == 'me').pumpings}
                </Typography>
                <Typography variant="body2">
                  Temperaturen: {statistics.find((x: any) => x.user == 'me').temperatures}
                </Typography>
                <Typography variant="body2">
                  Gewichten: {statistics.find((x: any) => x.user == 'me').weights}
                </Typography>
                <Typography variant="body2">
                  Medicatie: {statistics.find((x: any) => x.user == 'me').medications}
                </Typography>
                <Typography variant="body2">
                  Eten: {statistics.find((x: any) => x.user == 'me').food}
                </Typography>
                <Typography variant="body2">
                  Drinken: {statistics.find((x: any) => x.user == 'me').drink}
                </Typography>
                <Typography variant="body2">
                  Notities: {statistics.find((x: any) => x.user == 'me').notes}
                </Typography>
              </div>
              :
              <div style={{ color: '#F0FFFF' }}>Geen statistieken bekend</div>
            }
          </div>

          <div className="box-root">
            {partner && partner.partnerSynced && user.partnerSynced ?
              <div>
                <Typography variant="h2" className="profile-header">
                  Jouw partner
                </Typography>
                <Typography variant="body2" className="profile-data">
                  <BsFillPersonFill style={sm ? stylesSM : stylesMD} /> {partner.name}
                </Typography>
                <Typography variant="body2" className="profile-data">
                  <MdOutlineAlternateEmail style={sm ? stylesSM : stylesMD} /> {partner.email}
                </Typography>
                <Typography variant="body2" className="profile-data">
                  <TbDiscountCheck style={sm ? stylesSM : stylesMD} /> Lid sinds {format(partner.joinDate.toDate(), "dd MMMM yyyy HH:mm")}
                </Typography>

                
                {statistics && statistics.length > 0 ?
                  <div>
                    <Typography variant="h2" className="stats-header">
                      Statistieken afgelopen 3 dagen
                    </Typography>
                    <Typography variant="body2">
                      Luiers: {statistics.find((x: any) => x.user == 'partner').diapers}
                    </Typography>
                    <Typography variant="body2">
                      Borstvoedingen: {statistics.find((x: any) => x.user == 'partner').breastfeedings}
                    </Typography>
                    <Typography variant="body2">
                      Flesvoedingen: {statistics.find((x: any) => x.user == 'partner').bottlefeedings}
                    </Typography>
                    <Typography variant="body2">
                      Kolven: {statistics.find((x: any) => x.user == 'partner').pumpings}
                    </Typography>
                    <Typography variant="body2">
                      Temperaturen: {statistics.find((x: any) => x.user == 'partner').temperatures}
                    </Typography>
                    <Typography variant="body2">
                      Gewichten: {statistics.find((x: any) => x.user == 'partner').weights}
                    </Typography>
                    <Typography variant="body2">
                      Medicatie: {statistics.find((x: any) => x.user == 'partner').medications}
                    </Typography>
                    <Typography variant="body2">
                      Eten: {statistics.find((x: any) => x.user == 'partner').food}
                    </Typography>
                    <Typography variant="body2">
                      Drinken: {statistics.find((x: any) => x.user == 'partner').drink}
                    </Typography>
                    <Typography variant="body2">
                      Notities: {statistics.find((x: any) => x.user == 'partner').notes}
                    </Typography>
                  </div>
                  :
                  <div style={{ color: '#F0FFFF' }}>Geen statistieken bekend</div>
                }
                <div>
                  <Typography variant="body2" sx={{ marginTop: 3, fontStyle: 'italic' }}>
                    Wil je ontkoppelen met {partner.name}?
                    Klik hieronder op ontkoppelen.
                  </Typography>
                  <Button
                    onClick={handleUnSync}
                  >
                    Ontkoppelen
                  </Button>
                </div>
              </div>
              :
              partner && !partner.partnerSynced ?
                <div>
                  <Typography variant="h2" className="profile-header">
                    Family sync
                  </Typography>
                  <Typography variant="body2" className='paragraph'>
                    Jouw partner met email {partner.email} heeft nog niet met jou gekoppeld.
                    Zodra diegene jouw email adres invult op hun profiel pagina worden jullie gekoppeld en zien jullie hier elkaars gegevens.
                  </Typography>
                  <Typography variant="body2" className='paragraph' sx={{ fontStyle: 'italic' }}>
                    Wil je ontkoppelen met {partner.email}?
                    Klik hieronder op ontkoppelen.
                  </Typography>
                  <div>
                    <Button
                      onClick={handleUnSync}
                    >Ontkoppelen</Button>
                  </div>
                </div>
                :
                <div>
                  <Typography variant="h2" className="profile-header">
                    Family sync
                  </Typography>
                  <Typography variant="body2">
                    Je kunt één partner toevoegen om data mee te delen. Vul hieronder het emailadres waarmee jouw partner inlogt op Baby Book.
                  </Typography>
                  <Typography variant="body2" className='paragraph'>
                    Je partner doet daarna hetzelfde in hun account. Daarna zijn jullie accounts gekoppeld en zien jullie elkaars ingevoerde data.
                  </Typography>
                  <Typography variant="body2" className='paragraph'>
                    Let op: je partner krijgt geen verificatie mail! Pas als jullie beide elkaars email adres hebben ingevoerd vindt de koppeling plaats.
                  </Typography>
                  <div>
                    <input
                      type="email"
                      onChange={(e: any) => handleEmail(e.target.value)}
                      style={{ marginTop: '10px', padding: 5 }}
                    />
                    <p style={{ display: showError ? 'block' : 'none', color: 'red', fontStyle: 'italic', margin: 0, paddingTop: 5 }}>Vul een geldig emailadres in</p>
                    <Button
                      onClick={handleSync}
                    >Verstuur</Button>
                  </div>
                </div>
            }
          </div>

          <div className="box-root">
            <Typography variant="h2" className="profile-header">
              Data export
            </Typography>
            <Typography variant="body2" className='paragraph'>
              Via deze functie kun je de data uit je dashboard exporteren om bijvoorbeeld te kunnen delen met je zorgverlener.
              Vink aan welke activiteiten je wilt exporteren. Deze data wordt vervolgens in een CSV bestand naar je download folder verstuurd.
            </Typography>
            <Typography variant="body2" className='paragraph'>
              Heb je meerdere kinderen? Selecteer dan eerst het kind waarvoor je een export wilt maken in de menubar.
            </Typography>
            <div className='exportCheckBoxes'>
              <Checkbox
                label="Verschonen"
                value={exportDiapers}
                onChange={() => handleExportChange('diaper')}
              />
              <Checkbox
                label="Borstvoeding"
                value={exportBreastfeedings}
                onChange={() => handleExportChange('breastfeeding')}
              />
              <Checkbox
                label="Flesvoeding"
                value={exportBottlefeedings}
                onChange={() => handleExportChange('bottlefeeding')}
              />
              <Checkbox
                label="Kolven"
                value={exportPumpings}
                onChange={() => handleExportChange('pumping')}
              />
              <Checkbox
                label="Temperatuur"
                value={exportTemperatures}
                onChange={() => handleExportChange('temperature')}
              />
              <Checkbox
                label="Gewicht"
                value={exportWeights}
                onChange={() => handleExportChange('weight')}
              />
              <Checkbox
                label="Medicatie"
                value={exportMedications}
                onChange={() => handleExportChange('medication')}
              />
              <Checkbox
                label="Eten"
                value={exportFood}
                onChange={() => handleExportChange('food')}
              />
              <Checkbox
                label="Drinken"
                value={exportDrink}
                onChange={() => handleExportChange('drink')}
              />
              <Checkbox
                label="Notities"
                value={exportNotes}
                onChange={() => handleExportChange('note')}
              />
              <div>
                <CSVLink
                  data={data}
                  onClick={() => { if (exportDiapers || exportBreastfeedings || exportBottlefeedings || exportPumpings || exportTemperatures || exportWeights || exportMedications || exportFood || exportDrink || exportNotes) { handleExport() }}}
                  separator=";"
                  headers={headers}
                  filename={"baby-book.csv"}
                  className='exportBtn'
                >
                  Exporteren
                </CSVLink>
              </div>
            </div>
          </div>

          <Button
            variant="contained"
            onClick={handleDeleteAccountOpen}
            sx={{
              margin: 'auto',
              backgroundColor: 'transparent',
              border: '2px solid red',
              textTransform: 'none',
              marginTop: '30px',
              marginBottom: '30px',
              '&:hover': {
                backgroundColor: 'red',
                textTransform: 'none',
              },
            }}
          >
            Account verwijderen
          </Button>

          <DeleteAccountDialog
            open={open}
            onClose={handleDeleteAccountClose}
          />
        </div>
      ) : (
        <Typography variant="body2">
          Login om je account in te zien.
        </Typography>
      )}
    </Box>
  );
}

export default Profile;
