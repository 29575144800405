import { FunctionComponent, useContext } from 'react';
import { db } from "../../services/firebase";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { UserContext } from '../../context/UserContext';
import { PartnerContext } from '../../context/PartnerContext';
import { deleteBabyById, deleteDocumentsWithBabyId } from "../../services/DataService";
import styles from './Baby.module.css';

export interface DialogProps {
  open: boolean;
  onClose: (cancel: boolean) => void;
  babyItem: any;
}

const BabyDeleteDialog: FunctionComponent<DialogProps> = ({ open, onClose, babyItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [partner, setPartner] = useContext(PartnerContext);

  const handleDelete = async () => {
    await deleteBabyById(babyItem.babyId);
    await deleteBabyDataById(babyItem.babyId).then(() => {
      onClose(false);
    });
  };

  const deleteBabyDataById = async (babyId: string) => {
    const userId = user.userId;
  
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/diapers`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/breastfeedings`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/bottlefeedings`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/pumping`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/temperatures`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/weights`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/medications`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/food`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/drink`, babyId, 500);
    await deleteDocumentsWithBabyId(db, `trackingdata/${userId}/notes`, babyId, 500);

    if (partner && user.partnerSynced) {
      const partnerId = partner.userId;
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/diapers`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/breastfeedings`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/bottlefeedings`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/pumping`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/temperatures`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/weights`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/medications`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/food`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/drink`, babyId, 500);
      await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/notes`, babyId, 500);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#444654',
          padding: '20px'
        },
      }}
    >
      <Typography variant="h2">
        Kind verwijderen
      </Typography>
      <Typography variant="body2" sx={{ paddingTop: '10px' }}>
        Weet je zeker dat je dit kind en alle bijbehorende data wilt verwijderen?
      </Typography>

      <div>
        <Button
          variant="contained"
          type="button"
          onClick={() => onClose(true)}
        >
          Nee, annuleer
        </Button>
        <Button
          variant="contained"
          type="button"
          onClick={handleDelete}
          className={styles.deleteBtn}
        >
          Ja, verwijder dit kind
        </Button>
      </div>
    </Dialog>
  );
}

export default BabyDeleteDialog;
