import React, { createContext, useState } from 'react';

type ITrackingDataContext = [any, React.Dispatch<React.SetStateAction<any>>];

const TrackingDataContext = createContext<ITrackingDataContext>([[], () => {}]);

const TrackingDataProvider = ({ children }: { children: any }) => {
  const [trackingData, setTrackingData] = useState(null);
  
  return (
    <TrackingDataContext.Provider value={[trackingData, setTrackingData]}>
      {children}
    </TrackingDataContext.Provider>
  )
} 

export { TrackingDataContext, TrackingDataProvider };
