import { useContext, useState, MouseEvent } from 'react';
import Link from "@mui/material/Link";
import { Link as RouterLink } from 'react-router-dom';
import { auth } from "../../services/firebase";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { FiMenu } from 'react-icons/fi';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { LuBaby } from 'react-icons/lu';
import { UserContext } from '../../context/UserContext';
import { FbUserContext } from "../../context/FbUserContext";
import { ChildrenContext } from "../../context/ChildrenContext";
import { BabyContext } from "../../context/BabyContext";
import './Navbar.css';

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElChild, setAnchorElChild] = useState<null | HTMLElement>(null);

  const [fbUser, setFbUser] = useContext(FbUserContext);
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [allChildren, setAllChildren] = useContext(ChildrenContext);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElChild(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseChildMenu = () => {
    setAnchorElChild(null);
  };

  const handleSwitchChild = (child: any) => {
    setBaby(child);
    setAnchorElChild(null);
  };

  const signOut = () => {
    setFbUser(null);
    setUser(null);
    setBaby(null);
    setAllChildren(null);
    auth.signOut();
    setAnchorElNav(null);
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link component={RouterLink} to={fbUser ? '/dashboard' : '/'} style={{ marginTop: 5 }}>
            <LuBaby size={40} />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <FiMenu />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {fbUser ?
                <div>
                  <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/dashboard" className="menuLink">
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/profile" className="menuLink">
                    Profiel
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/baby" className="menuLink">
                    Baby
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/faq" className="menuLink">
                    FAQ
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/about" className="menuLink">
                    Over Baby Book
                  </MenuItem>
                  <MenuItem onClick={signOut} className="menuLink">
                    Uitloggen
                  </MenuItem>
                </div>
                :
                <div>
                  <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/faq" className="menuLink">
                    FAQ
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to="/about" className="menuLink">
                    Over Baby Book
                  </MenuItem>
                </div>
              }
            </Menu>
          </Box>

          {allChildren && allChildren.length > 1 && baby ?
            <Box sx={{ flexGrow: 0, marginLeft: '10px' }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{ backgroundColor: baby.data.gender == 'Boy' ? '#097bd9' : '#ff70d2' }}>{baby.data.name.charAt(0)}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElChild}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElChild)}
                onClose={handleCloseChildMenu}
              >
                <Typography variant="h4" sx={{ color: '#575757', paddingLeft: '5px' }}>Switch kind:</Typography>
                {allChildren.map((child: any, index: number) => (
                  (baby.babyId != child.babyId) ? (
                    <MenuItem key={index} onClick={() => handleSwitchChild(child)}>
                      <Typography textAlign="center">{child.data.name}</Typography>
                    </MenuItem>
                  ) : null
                ))}
              </Menu>
            </Box>
            : null}

          {/* space */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>

          {fbUser ?
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Link component={RouterLink} to="/profile" className="menuLink">
                Profiel
              </Link>
              <Link component={RouterLink} to="/baby" className="menuLink">
                Baby
              </Link>
              <Link component={RouterLink} to="/faq" className="menuLink">
                FAQ
              </Link>
              <Link component={RouterLink} to="/about" className="menuLink">
                Over Baby Book
              </Link>
              <Link
                onClick={signOut}
                className="menuLink"
              >
                Uitloggen
              </Link>
            </Box>
            :
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Link component={RouterLink} to="/faq" className="menuLink">
                FAQ
              </Link>
              <Link component={RouterLink} to="/about" className="menuLink">
                Over Baby Book
              </Link>
            </Box>
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;