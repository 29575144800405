import { styled } from "@mui/material/styles";
import MuiButton, { ButtonProps } from '@mui/material/Button';

interface TrackButtonProps extends ButtonProps {
  backgroundcolor?: string;
  hovercolor?: string;
}

export const TrackButton = styled(MuiButton)<TrackButtonProps>(({ backgroundcolor, hovercolor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  color: '#000',
  width: '60px',
  height: '60px',
  fontSize: '2rem',
  marginTop: '5px',
  backgroundColor: backgroundcolor,
  '&:hover': {
    backgroundColor: hovercolor,
  }
}));