import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FaPrescriptionBottleMedical } from 'react-icons/fa6';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import styles from './Medication.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../styles/FormStyles.css';

interface MedicationProps {
  hideMedication: boolean;
  onFormClose: (formType: string) => void;
  medicationItem: any;
}

interface Values {
  datetime: Date;
  vitamineK: boolean;
  vitamineD: boolean;
  medicine: string;
  amount: string;
  comment: string;
}

const Medication: FunctionComponent<MedicationProps> = ({ hideMedication, onFormClose, medicationItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [vitamineK, setVitamineK] = useState(false);
  const [vitamineD, setVitamineD] = useState(false);
  const [medicine, setMedicine] = useState('');
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');

  const sm = useMediaQuery('(max-width:416px)');

  const addMedication = async (data: any) => {
    const medicationsCollection = collection(db, "trackingdata", user.userId, "medications");
    await setDoc(doc(medicationsCollection), {
      datetime: data.datetime,
      vitamineK: data.vitamineK,
      vitamineD: data.vitamineD,
      medicine: data.medicine,
      amount: data.amount,
      comment: data.comment,
      babyId: baby.babyId
    });

    onFormClose('medication');
  }

  const updateMedication = async (data: any) => {
    const medicationCollection = collection(db, "trackingdata", medicationItem.userId, "medications");
    const medicationDocRef = doc(medicationCollection, medicationItem.id);

    try {
      await updateDoc(medicationDocRef, {
        datetime: data.datetime,
        vitamineK: data.vitamineK,
        vitamineD: data.vitamineD,
        medicine: data.medicine,
        amount: data.amount,
        comment: data.comment
      });
    } catch (error) {
      console.error('Error updating medication:', error);
    }

    onFormClose('medication');
  }

  const deleteMedication = async () => {
    const medicationCollection = collection(db, "trackingdata", medicationItem.userId, "medications");
    const medicationDocRef = doc(medicationCollection, medicationItem.id);

    try {
      await deleteDoc(medicationDocRef);
    } catch (error) {
      console.error('Error deleting medication:', error);
    }

    onFormClose('medication');
  }

  const handleVitamine = (vitamine: string) => {
    if (vitamine == "K") {
      setVitamineK(!vitamineK);
    } else {
      setVitamineD(!vitamineD);
    }
  }

  const handleMedicine = (value: string) => {
    setMedicine(value);
  }

  const handleAmount = (value: string) => {
    setAmount(value);
  }

  const handleComment = (value: string) => {
    setComment(value);
  }

  const resetForm = () => {
    setStartDate(new Date());
    setVitamineK(false);
    setVitamineD(false);
    setMedicine('');
    setAmount('');
    setComment('');
  }

  useEffect(() => {
    if (medicationItem != null) {
      const start = new Date(medicationItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setVitamineK(medicationItem.data.vitamineK);
      setVitamineD(medicationItem.data.vitamineD);
      setMedicine(medicationItem.data.medicine);
      setAmount(medicationItem.data.amount);
      setComment(medicationItem.data.comment);
    }
  }, [medicationItem]);

  useEffect(() => {
    if (medicationItem == null) {
      resetForm();
    }
  }, [hideMedication]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          vitamineK: vitamineK,
          vitamineD: vitamineD,
          medicine: medicine,
          amount: amount,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (medicationItem != null) {
            updateMedication(values);
          } else {
            addMedication(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <FaPrescriptionBottleMedical style={headerIcon} />
              <span style={sm ? headerTitleSX : headerTitleMD}>Medicatie</span>
            </div>

            <div className="form-body">
              <div className="group-space datepicker-group">
                <CustomNowDateTimePicker
                  startDate={startDate}
                  onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date); }}
                  onNowDate={setStartDate}
                />
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Vitamines</div>
                </Typography>
                <div className={`radio-group ${styles.radioGroup}`} role="group" aria-labelledby="checkbox-group">
                  <label>
                    <Field 
                      type="checkbox" 
                      name="vitamineK" 
                      onClick={() => { setFieldValue("vitamineK", true); handleVitamine("K") }}
                    />
                    Vitamine K
                  </label>
                  <label>
                    <Field 
                      type="checkbox" 
                      name="vitamineD" 
                      onClick={() => { setFieldValue("vitamineD", true); handleVitamine("D") }}
                    />
                    Vitamine D
                  </label>
                </div>
              </div>

              <div className={`group-space ${styles.medicinegroup}`}>
                <Typography component={'span'} variant="h4">
                  <div>Anders</div>
                </Typography>
                <div className={styles.medicineContainer}>
                  <label>
                    <p>Medicijn:</p>
                    <Field 
                      as="input" 
                      name="medicine"
                      type="text"
                      placeholder="Hoestdrank"
                      onChange={(e: any) => { handleMedicine(e.target.value); }} //
                      value={medicine} 
                      className={styles.medicineField} 
                      maxLength="30"
                    /> 
                  </label>
                  <label>
                    <p>Hoeveelheid:</p>
                    <Field 
                      as="input" 
                      name="amount"
                      type="text"
                      placeholder="10 ml"
                      onChange={(e: any) => { handleAmount(e.target.value); }}
                      value={amount} 
                      className={styles.medicineField} 
                      maxLength="30"
                    />
                  </label>
                </div>
              </div>

              <div className={`group-space ${styles.medicinegroup}`}>
                <Typography component={'span'} variant="h4">
                  <div>Opmerking</div>
                </Typography>
                <Field
                  as="textarea"
                  name="comment"
                  onChange={(e: any) => handleComment(e.target.value)}
                  value={comment}
                  className="comment-field"
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                { medicationItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteMedication}
                    sx={{ 
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                } 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Medication;
