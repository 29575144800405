import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { MdBabyChangingStation } from 'react-icons/md';
import { TbDroplet, TbDropletHalfFilled, TbDropletOff } from 'react-icons/tb';
import { FaPoop } from 'react-icons/fa6';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import styles from './Diaper.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../styles/FormStyles.css';

interface DiaperProps {
  hideDiaper: boolean;
  onFormClose: (formType: string) => void;
  diaperItem: any;
}

interface Values {
  datetime: Date;
  diaperstatus: string;
  comment: string;
}

const Diaper: FunctionComponent<DiaperProps> = ({ hideDiaper, onFormClose, diaperItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDiaperStatus, setSelectedDiaperStatus] = useState('Wet');
  const [comment, setComment] = useState('');

  const sm = useMediaQuery('(max-width:416px)');

  const handleComment = (value: string) => {
    setComment(value);
  }

  const addDiaper = async (data: any) => {
    const diapersCollection = collection(db, "trackingdata", user.userId, "diapers");
    await setDoc(doc(diapersCollection), {
        datetime: data.datetime,
        diaperStatus: data.diaperstatus,
        comment: data.comment,
        babyId: baby.babyId
      });

    onFormClose('diaper');
  }

  const updateDiaper = async (data: any) => {
    const diapersCollection = collection(db, "trackingdata", diaperItem.userId, "diapers");
    const diaperDocRef = doc(diapersCollection, diaperItem.id);

    try {
      await updateDoc(diaperDocRef, {
        datetime: data.datetime,
        diaperStatus: data.diaperstatus,
        comment: data.comment
      });
    } catch (error) {
      console.error('Error updating diaper:', error);
    }

    onFormClose('diaper');
  }

  const deleteDiaper = async () => {
    const diapersCollection = collection(db, "trackingdata", diaperItem.userId, "diapers");
    const diaperDocRef = doc(diapersCollection, diaperItem.id);

    try {
      await deleteDoc(diaperDocRef);
    } catch (error) {
      console.error('Error deleting diaper:', error);
    }

    onFormClose('diaper');
  }

  const resetForm = () => {
    setStartDate(new Date());
    setSelectedDiaperStatus('Wet');
    setComment('');
  }

  useEffect(() => {
    if (diaperItem != null) {
      const start = new Date(diaperItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setSelectedDiaperStatus(diaperItem.data.diaperStatus);
      setComment(diaperItem.data.comment);
    }
  }, [diaperItem]);

  useEffect(() => {
    if (diaperItem == null) {
      resetForm();
    }
  }, [hideDiaper]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          diaperstatus: selectedDiaperStatus,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (diaperItem != null) {
            updateDiaper(values);
          } else {
            addDiaper(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <MdBabyChangingStation style={headerIcon} /> 
              <span style={sm ? headerTitleSX : headerTitleMD}>Verschonen</span>
            </div>

            <div className="form-body">
              <div className="group-space datepicker-group">
                <CustomNowDateTimePicker 
                  startDate={startDate}
                  onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date);}}
                  onNowDate={setStartDate}
                />
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div id="diaper-group">Luierstatus</div>
                </Typography>
                <div className={`radio-group ${styles.radioGroup}`} role="group" aria-labelledby="my-radio-group" style={{ marginTop: '5px' }}>
                  <label
                    onClick={() => { setFieldValue("diaperstatus", "Wet"); setSelectedDiaperStatus('Wet') }}
                    style={{ backgroundColor: selectedDiaperStatus === "Wet" ? '#eda200' : 'transparent' }}>
                    <TbDroplet /> Nat
                  </label>
                  <label
                    onClick={() => { setFieldValue("diaperstatus", "Dirty"); setSelectedDiaperStatus('Dirty') }}
                    style={{ backgroundColor: selectedDiaperStatus === "Dirty" ? '#eda200' : 'transparent' }}>
                    <FaPoop /> Vuil
                  </label>
                  <label
                    onClick={() => { setFieldValue("diaperstatus", "Mixed"); setSelectedDiaperStatus('Mixed') }}
                    style={{ backgroundColor: selectedDiaperStatus === "Mixed" ? '#eda200' : 'transparent' }}>
                    <TbDropletHalfFilled /> Gemengd
                  </label>
                  <label
                    onClick={() => { setFieldValue("diaperstatus", "Dry"); setSelectedDiaperStatus('Dry') }}
                    style={{ backgroundColor: selectedDiaperStatus === "Dry" ? '#eda200' : 'transparent' }}>
                    <TbDropletOff /> Droog
                  </label>
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div id="diaper-group">Opmerking</div>
                </Typography>
                <Field 
                  as="textarea" 
                  name="comment" 
                  onChange={(e: any) => handleComment(e.target.value)} 
                  value={comment} 
                  className="comment-field" 
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                { diaperItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteDiaper}
                    sx={{ 
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                } 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Diaper;
