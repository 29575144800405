import React, { createContext, useState } from 'react';

type IUserContext = [any, React.Dispatch<React.SetStateAction<any>>];

const UserContext = createContext<IUserContext>([[], () => {}]);

const UserProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState(null);
  
  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  )
} 

export { UserContext, UserProvider };
