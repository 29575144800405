import { Typography } from '@mui/material';
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import commentPNG from "../../images/breastfeeding-comment.PNG";
import startEndTimePNG from "../../images/breastfeeding-start-end-time.PNG";
import overviewPNG from "../../images/breastfeeding-overview.PNG";
import startSidePNG from "../../images/breastfeeding-startside.PNG";
import timersPNG from "../../images/breastfeeding-timers.PNG";
import iosSharePNG from "../../images/ios-share.PNG";
import editDeletePNG from "../../images/dashboard-edit.PNG";
import dashboardButtonsPNG from "../../images/dashboard-knoppen.PNG";
import switchChildPNG from "../../images/switch-child.PNG";
import feedingIconsPNG from "../../images/feeding-icons.PNG";
import iconTimesPNG from "../../images/icon-times.PNG";
import iconTimeAgoPNG from "../../images/icon-time-ago.PNG";
import iconTimeIntervalPNG from "../../images/icon-time-interval.PNG";
import iconStartSidePNG from "../../images/icon-start-side.PNG";
import iconTimersPNG from "../../images/icon-timers.PNG";
import iconBottlefeedingPNG from "../../images/icon-bottlefeeding.PNG";
import styles from './FAQ.module.css';

function FAQ() {
  const scrollToContent = (elementId: string) => {
    const element = document.getElementById(elementId);
    element?.scrollIntoView();
  }

  return (
    <div className={styles.faqContainer}>
      <Typography variant="h1">
        Veelgestelde vragen
      </Typography>

      <div className={styles.contentsContainer}>
        <Typography component={'span'} variant="h2">
          Inhoud
        </Typography>
        <ul className={styles.tableOfContents}>
          <Typography variant="h3" className={styles.tableOfContentsHeader}>
            Installatie & Gebruik
          </Typography>
          <li onClick={() => { scrollToContent('installApp'); }}>Hoe installeer ik Baby Book als app op mijn telefoon?</li>
          <li onClick={() => { scrollToContent('addActivity'); }}>Hoe kan ik een nieuwe activiteit toevoegen?</li>
          <li onClick={() => { scrollToContent('editActivity'); }}>Hoe kan ik een activiteit aanpassen of verwijderen?</li>
          <Typography variant="h3" className={styles.tableOfContentsHeader}>
            Borstvoeding, Kolven & Voedingsinformatie
          </Typography>
          <li onClick={() => { scrollToContent('breastfeeding'); }}>Hoe werkt het borstvoeding / kolven formulier?</li>
          <li onClick={() => { scrollToContent('feedingIcons'); }}>Wat betekenen de verschillende icoontjes en tijden bij de borstvoeding en flesvoeding activiteiten?</li>
          <Typography variant="h3" className={styles.tableOfContentsHeader}>
            Baby Pagina
          </Typography>
          <li onClick={() => { scrollToContent('multipleBabies'); }}>Kan ik meerdere kinderen toevoegen?</li>
          <li onClick={() => { scrollToContent('greyedOutBaby'); }}>Waarom zijn de verwijder/bewerk knoppen uitgegrijsd bij een kind op de Baby pagina?</li>
          <Typography variant="h3" className={styles.tableOfContentsHeader}>
            Account & Family Sync
          </Typography>
          <li onClick={() => { scrollToContent('removeAccount'); }}>Hoe verwijder ik mijn account?</li>
          <li onClick={() => { scrollToContent('familySync'); }}>Hoe werkt Family Sync?</li>
          <li onClick={() => { scrollToContent('partnerAccountRemoval'); }}>Wat gebeurt er als mijn partner diens account verwijdert?</li>
          <Typography variant="h3" className={styles.tableOfContentsHeader}>
            Contact & Privacy
          </Typography>
          <li onClick={() => { scrollToContent('contact'); }}>Hoe kan ik contact opnemen met de ontwikkelaar van Baby Book?</li>
          <li onClick={() => { scrollToContent('privacyPolicy'); }}>Waar vind ik het privacybeleid?</li>
        </ul>
      </div>

      <div className={styles.questionsContainer}>
        <div id="installApp">
          <Typography variant="h3" className={styles.header}>
            Hoe installeer ik Baby Book als app op mijn telefoon?
          </Typography>
          <Typography component={'span'} variant="body2" className={styles.paragraph}>
            Op Android:
            <ol style ={{ margin: 0 }}>
              <li>Open Chrome en browse naar de Baby Book website</li>
              <li>Klik rechtsboven op de 3 puntjes</li>
              <li>Klik op 'App installeren'</li>
              <li>Klik in de popup op 'Installeren'</li>
              <li>Baby Book staat nu op je home screen en tussen je andere apps</li>
            </ol>
          </Typography>
          <Typography component={'span'} variant="body2" className={styles.paragraph}>
            Op iOS:
            <ol style ={{ margin: 0 }}>
              <li>Open Safari en browse naar de Baby Book website</li>
              <li>Klik op de Share button:</li>
              <img className={styles.images} src={iosSharePNG} alt="ios share button" />
              <li>Klik op 'Add to Home Screen'</li>
              <li>Klik op 'Add'</li>
              <li>Baby Book staat nu op je home screen en tussen je andere apps</li>
            </ol>
          </Typography>
        </div>

        <div id="addActivity">
          <Typography variant="h3" className={styles.header}>
            Hoe kan ik een nieuwe activiteit toevoegen?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Maak op de <Link to="/baby" className={styles.profileLink}>Baby</Link> pagina eerst een baby aan als je dit nog niet hebt gedaan. De knoppen bovenaan op je <Link to="/dashboard" className={styles.profileLink}>Dashboard</Link> krijgen daarna een kleur en zijn vanaf dat moment bruikbaar.
          </Typography>
          <img className={styles.images} src={dashboardButtonsPNG} alt="Activiteiten knoppen op dashboard" />
          <Typography variant="body2" className={styles.paragraph}>
            Van links naar rechts: verschonen, borstvoeding, flesvoeding, kolven, temperatuur, gewicht, medicijnen, notities.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Klik op de knop waar je een activiteit voor wilt aanmaken, vul de gegevens in en klik op Opslaan. De activiteit verschijnt daarna in je dashboard.
          </Typography>
        </div>

        <div id="editActivity">
          <Typography variant="h3" className={styles.header}>
            Hoe kan ik een activiteit aanpassen of verwijderen?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Klik in het overzicht op het icoontje links naast de activiteit die je wilt aanpassen of verwijderen. Hieronder in het groen aangegeven:
          </Typography>
          <img className={styles.images} style={{ marginTop: 5 }} src={editDeletePNG} alt="Activiteiten icoontjes in overzicht op dashboard" />
          <Typography variant="body2" className={styles.paragraph}>
            Het formulier opent met de ingevulde gegevens. Pas deze aan en klik op Opslaan om aan te passen. Of klik op Verwijderen om de activiteit te verwijderen.
          </Typography>
        </div>

        <div id="breastfeeding">
          <Typography variant="h3" className={styles.header}>
            Hoe werkt het borstvoeding / kolven formulier?
          </Typography>
          <img className={styles.images} src={startEndTimePNG} alt="start and end time with calendar" />
          <Typography variant="body2" className={styles.paragraph}>
            Het borstvoeding formulier werkt als volgt: Wanneer je het formulier opent kun je bovenaan via de kalender de datum en tijd kiezen. Boven de kalender staan de opties 'Starttijd' en 'Eindtijd'. Deze staat standaard op 'Starttijd' om aan te geven dat de borstvoeding om deze tijd is gestart. De eindtijd wordt vervolgens door de applicatie berekent door de borstvoedingstijden die je invult op te tellen bij de starttijd in de kalender.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Als alternatief kun je 'Eindtijd' selecteren. De applicatie berekent dan de starttijd door de borstvoedingstijden af te trekken van de tijd in de kalender.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Wanneer je een borstvoeding opslaat zie je de start- en eindtijd in het overzicht op het dashboard staan:
          </Typography>
          <img className={styles.images} src={overviewPNG} alt="borstvoeding overzicht" />
          <Typography variant="body2" className={styles.paragraph}>
            In het formulier kun je per borst zowel timers gebruiken als handmatig tijden toevoegen via de + buttons. 
            Klik op 'Start' om een timer te starten. De start button verandert daarna in een 'Stop' button om de timer te stoppen / pauzeren. Klik 'Reset' om de timer naar 00:00 te resetten.
          </Typography>
          <img className={styles.images} src={timersPNG} alt="borstvoeding timers" />
          <Typography variant="body2" className={styles.paragraph}>
            Het formulier geeft automatisch aan welke borst als eerste aan de beurt is in deze voeding a.d.h.v. de startpositie in je laatste voeding. 
            Je kunt dit hieronder handmatig aanpassen.
          </Typography>
          <img className={styles.images} src={startSidePNG} alt="borstvoeding start kant" />
          <Typography variant="body2" className={styles.paragraph}>
            Als laatste is er ruimte voor opmerkingen.
          </Typography>
          <img className={styles.images} style={{ marginTop: '10px' }} src={commentPNG} alt="borstvoeding opmerking plaatsen" />
          <Typography variant="body2" className={styles.paragraph}>
            Klik op Opslaan om de sessie op te slaan. Wanneer je een borstvoedings item aanpast kun je tevens op de knop 'Verwijderen' klikken om de sessie te verwijderen.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Het formulier voor kolven (licht-blauw) werkt op ongeveer dezelfde manier met de start/eindtijd, kalender en timers. Bij het kolf formulier kun je kiezen voor enkelzijdig of dubbelzijdig kolven. Bij enkelzijdig kolven kun je een timer per borst starten. Bij dubbelzijdig kolven één timer voor beiden. Bij kolven wordt niet bijgehouden welke borst als eerste is gekolfd.
          </Typography>
        </div>

        <div id="feedingIcons">
          <Typography variant="h3" className={styles.header}>
            Wat betekenen de verschillende icoontjes en tijden bij de borstvoeding en flesvoeding activiteiten?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Hieronder een voorbeeld van een aantal voedingsactiviteiten (borstvoeding en flesvoeding) zoals dit eruit zou kunnen zien in je dashboard:
          </Typography>
          <img className={styles.images} src={feedingIconsPNG} alt="Uitleg voeding icons en tijden" />
          <Typography variant="body2" className={styles.paragraph} sx={{ position: 'relative', top: '10px' }}>
            De betekenis van de informatie is als volgt:
          </Typography>
          <TableContainer sx={{ marginTop: '10px' }}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell className={styles.tableCell}><img className={`${styles.tableImage}`} src={iconTimesPNG} alt="Tijden" /></TableCell>
                    <TableCell className={styles.tableCell}>De start- en eindtijd waarop de borstvoeding plaatsvond. Flesvoeding activiteiten tonen maar één tijd.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableCell}><img className={`${styles.tableImage}`} src={iconTimeAgoPNG} alt="Tijd verstreken" /></TableCell>
                    <TableCell className={styles.tableCell}>De verstreken tijd vanaf nu.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableCell}><img className={`${styles.tableImage}`} src={iconTimeIntervalPNG} alt="Interval tussen voedingen" /></TableCell>
                    <TableCell className={styles.tableCell}>De tijdsinterval tussen deze en de vorige borst- of flesvoeding.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableCell}><img className={`${styles.tableImage}`} src={iconStartSidePNG} alt="Startkant van de voeding" /></TableCell>
                    <TableCell className={styles.tableCell}>Aan welke kant je deze borstvoeding bent begonnen.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableCell}><img className={`${styles.tableImage}`} src={iconTimersPNG} alt="Voeding timers" /></TableCell>
                    <TableCell className={styles.tableCell}>De tijd die je per borstkant hebt gevoed d.m.v. het zetten van de timers.</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.tableCell}><img className={`${styles.tableImage}`} src={iconBottlefeedingPNG} alt="Flesvoeding informatie" /></TableCell>
                    <TableCell className={styles.tableCell}>Bij flesvoeding zie je geen timers, maar de hoeveelheid en soort melk die je hebt gevoed.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
        </div>

        <div id="multipleBabies">
          <Typography variant="h3" className={styles.header}>
            Kan ik meerdere kinderen toevoegen?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Ja, je kunt zoveel kinderen toevoegen als je wilt.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Ga naar de <Link to="/baby" className={styles.profileLink}>Baby</Link> pagina om één of meerdere kinderen toe te voegen.
            Wanneer je meerdere kinderen hebt verschijnt er in de menubar een bolletje waar je van kinderen kunt switchen.
          </Typography>
          <img className={styles.images} src={switchChildPNG} alt="Kind switchen" />
          <Typography variant="body2" className={styles.paragraph}>
            Je herkent het geselecteerde kind doordat het bolletje de eerste letter bevat van de naam en aan de kleur: blauw voor een jongen, roze voor een meisje.
            In het dashboard zie je alleen de data van het kind dat je geselecteerd hebt in de menubar.
          </Typography>
        </div>

        <div id="greyedOutBaby">
          <Typography variant="h3" className={styles.header}>
            Waarom zijn de verwijder/bewerk knoppen uitgegrijsd bij een kind op de Baby pagina?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Als de verwijder en bewerk knoppen onder een kind op de Baby pagina uitgegrijsd zijn en je er niet op kan klikken, betekent dit dat dit kind door jouw partner is gemaakt. Alleen jouw partner kan het kind verwijderen of bewerken.
          </Typography>
        </div>

        <div id="removeAccount">
          <Typography variant="h3" className={styles.header}>
            Hoe verwijder ik mijn account?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Ga naar de <Link to="/profile" className={styles.profileLink}>Profiel</Link> pagina. Klik onderaan op de knop 'Account verwijderen'.
          </Typography>
        </div>

        <div id="familySync">
          <Typography variant="h3" className={styles.header}>
            Hoe werkt Family Sync?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Met Family Sync kun je jouw account koppelen met die van jouw partner. Op die manier kunnen jullie beiden activiteiten bijhouden van jullie baby, en elkaars activiteiten zien in het dashboard.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Via de <Link to="/profile" className={styles.profileLink}>Profiel</Link> pagina kun je onder het kopje 'Family Sync' het emailadres van jouw partner invoeren.
            Jouw partner voert vervolgens jouw emailadres in via diens eigen profiel pagina en daarna is de koppeling aangemaakt.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Eén van jullie maakt op de <Link to="/baby" className={styles.profileLink}>Baby</Link> pagina een kind aan. De partner kan dit kind vervolgens ook zien en via de track formulieren op het dashboard activiteiten hiervoor loggen.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Je kunt met één partner tegelijk koppelen. Ontkoppelen kan eenvoudig gedaan worden via de Profiel pagina.
          </Typography>
        </div>

        <div id="partnerAccountRemoval">
          <Typography variant="h3" className={styles.header}>
            Wat gebeurt er als mijn partner diens account verwijdert?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Wanneer een gebruiker diens account verwijdert, wordt de volgende data verwijderd:
          </Typography>
          <Typography component={'span'} variant="body2" className={styles.paragraph}>
            <ul style ={{ margin: 0 }}>
              <li>Alle eigen aangemaakte kinderen</li>
              <li>Alle ingevoerde 'tracking data' (luiers, voedingen, medicijnen, temperaturen, gewichten, notities)</li>
              <li>Koppeling met partner</li>
              <li>Profiel gegevens</li>
            </ul>
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Heb jij een kind aangemaakt in Baby Book en heeft jouw partner daar data aan toegevoegd?
            Dan wordt diens deel van de data verwijderd. Deze verschijnen niet meer in jouw overzicht. Alleen jouw eigen ingevoerde data voor jouw kind wordt getoond.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Jouw partner is eigendom van diens eigen data. Daarom kan dit niet bewaard blijven wanneer de partner het account verwijderd.
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Op dit moment is er nog geen functionaliteit die de data van de partner automatisch overzet naar jouw account. Mogelijk wordt dit in de toekomst toegevoegd aan Baby Book.
            Wel is het mogelijk om via de <Link to="/profile" className={styles.profileLink}>Profiel</Link> pagina een export te maken van de data voordat de partner het account verwijdert. In de export staat achter elke activiteit de gebruiker vermeld die de data heeft aangemaakt, zodat je dit zelf kunt toevoegen aan je eigen account.
          </Typography>
        </div>

        <div id="contact">
          <Typography variant="h3" className={styles.header}>
            Hoe kan ik contact opnemen met de ontwikkelaar van Baby Book?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Je kunt contact opnemen via <a href = "mailto: babybookconnect@gmail.com" className={styles.profileLink}>babybookconnect@gmail.com</a>
          </Typography>
        </div>

        <div id="privacyPolicy">
          <Typography variant="h3" className={styles.header}>
            Waar vind ik het privacybeleid?
          </Typography>
          <Typography variant="body2" className={styles.paragraph}>
            Deze is te vinden op de <Link to="/privacy" className={styles.profileLink} onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link> pagina.
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default FAQ;