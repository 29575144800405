import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FcGoogle } from 'react-icons/fc';
import useMediaQuery from '@mui/material/useMediaQuery';
import dashboardPNG from "../../images/dashboard.png";
import dailyStatsPNG from "../../images/daily-stats.PNG";
import breastfeedingPNG from "../../images/breastfeeding-form.png";
import familySyncPNG from "../../images/family-sync.png";
import styles from "./Signin.module.css";

function SignIn() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const md = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading, navigate]);

  return (
    <div>
      {user == null && !loading ?
        <Container disableGutters={true}>
          <div className={styles.signIn}>
            <Typography variant="h1">Welkom bij Baby Book!</Typography>
            <Typography variant="h3">Dé baby tracker app voor ouders</Typography>
            <Button
              variant="contained"
              onClick={signInWithGoogle}
              startIcon={<FcGoogle style={{ verticalAlign: 'middle' }} />}
              sx={{
                margin: 'auto',
                display: 'inline',
                backgroundColor: '#ffffff',
                color: '#4285F4',
                border: 'none',
                marginTop: '20px',
                '&:hover': {
                  backgroundColor: '#ffffff',
                },
              }}
            >
              Inloggen met Google
            </Button>
          </div>

          <div className={styles.introContainer}>
            <div className={[styles.introContent, styles.introDashboard].join(' ')}>
              <div className={styles.introwrapper}>
                <Typography variant="h1" className={styles.title} sx={{ textAlign: md ? 'right' : 'center' }}>Houd belangrijke activiteiten van je baby bij in één handig overzicht</Typography>
                <img src={dashboardPNG} alt="Baby Book dashboard" className={styles.images} />
              </div>
            </div>

            <div className={[styles.introContent, styles.introForms].join(' ')}>
              {md ?
                <div className={styles.introwrapper}>
                  <img src={breastfeedingPNG} alt="Borstvoeding formulier" className={styles.images} />
                  <Typography variant="h1" className={styles.title} sx={{ textAlign: md ? 'left' : 'center' }}>Voeg in één klik nieuwe items toe voor voedingen, verschonen, kolven, temperaturen, gewichten, medicijnen en notities</Typography>
                </div>
                :
                <div className={styles.introwrapper}>
                  <Typography variant="h1" className={styles.title} sx={{ textAlign: md ? 'left' : 'center' }}>Voeg in één klik nieuwe items toe voor voedingen, verschonen, kolven, temperaturen, gewichten, medicijnen en notities</Typography>
                  <img src={breastfeedingPNG} alt="Borstvoeding formulier" className={styles.images} />
                </div>
              }
            </div>

            <div className={[styles.introContent, styles.introDailyStats].join(' ')}>
              <div className={styles.introwrapper}>
                <Typography variant="h1" className={styles.title} sx={{ textAlign: md ? 'right' : 'center' }}>Bekijk dagelijkse statistieken en exporteer data om te delen met je zorgverlener</Typography>
                <img src={dailyStatsPNG} alt="Dagelijkse statistieken" className={styles.images} />
              </div>
            </div>

            <div className={[styles.introContent, styles.introFamilySync].join(' ')}>
              {md ?
                <div className={styles.introwrapper}>
                  <img src={familySyncPNG} alt="Family sync optie" className={styles.images} />
                  <Typography variant="h1" className={styles.title} sx={{ textAlign: md ? 'right' : 'center' }}>Koppel je account met je partner en houd samen jullie baby's activiteiten bij</Typography>
                </div>
                :
                <div className={styles.introwrapper}>
                  <Typography variant="h1" className={styles.title} sx={{ textAlign: md ? 'right' : 'center' }}>Koppel je account met je partner en houd samen jullie baby's activiteiten bij</Typography>
                  <img src={familySyncPNG} alt="Family sync optie" className={styles.images} />
                </div>
              }
            </div>
          </div>

          <div className={styles.signInBottom}>
            <Typography variant="h3">Meld je nu aan voor Baby Book en start direct met tracken!</Typography>
            <Button
              variant="contained"
              onClick={signInWithGoogle}
              startIcon={<FcGoogle style={{ verticalAlign: 'middle' }} />}
              sx={{
                margin: 'auto',
                display: 'inline',
                backgroundColor: '#ffffff',
                color: '#4285F4',
                border: 'none',
                marginTop: '20px',
                '&:hover': {
                  backgroundColor: '#ffffff',
                },
              }}
            >
              Inloggen met Google
            </Button>
          </div>
        </Container>
        :
        null
      }
    </div>
  );
}

export default SignIn;
