import { useContext, useState, useEffect, FunctionComponent } from "react";
import { TrackingDataContext } from "../../context/TrackingDataContext";
import { UserContext } from "../../context/UserContext";
import { PartnerContext } from "../../context/PartnerContext";
import { BabyContext } from "../../context/BabyContext";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import { experimentalStyled as styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { convertPauseTimeToMs } from '../../utils/Utils';
import { MdBabyChangingStation, MdTimelapse, MdQueryStats } from 'react-icons/md';
import { FaTemperatureHigh } from 'react-icons/fa';
import { FaWeightScale, FaPrescriptionBottleMedical, FaPersonBreastfeeding, FaPoop, FaMugHot } from 'react-icons/fa6';
import { GiBabyBottle, GiRoundBottomFlask } from 'react-icons/gi';
import { TbDroplet, TbDropletHalfFilled, TbDropletOff } from 'react-icons/tb';
import { PiTimer,PiArrowsHorizontalFill, PiSmiley, PiSmileyMeh, PiSmileySad } from 'react-icons/pi';
import { BiSolidBowlHot } from "react-icons/bi";
import { BsPen } from 'react-icons/bs';
import styles from './TrackingData.module.css';
import { nl } from 'date-fns/locale';
const { format, differenceInMinutes, isSameDay, startOfMinute } = require('date-fns');

const Item = styled(Box)(({ }) => ({
  backgroundColor: '#444654',
  color: '#F0FFFF'
}));

interface TrackingDataProps {
  onItemClick: (item: any) => void;
  closingForm: boolean;
}

const TrackingData: FunctionComponent<TrackingDataProps> = ({ onItemClick, closingForm }) => {
  const [baby, setBaby] = useContext(BabyContext);
  const [trackingData, setTrackingData] = useContext(TrackingDataContext);
  const [user, setUser] = useContext(UserContext);
  const [partner, setPartner] = useContext(PartnerContext);
  const [dataCurrentBaby, setDataCurrentBaby] = useState<any[]>();
  const [dailyStats, setDailyStats] = useState<{ showStats: boolean; dateStats: Date; stats: any[] }>({ showStats: false, dateStats: new Date(), stats: [] });
  const [loading, setLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleOnItemClick = (item: any) => {
    setDailyStats({ showStats: false, dateStats: new Date(), stats: [] });
    onItemClick(item);
  }

  const calculateBreastFeedingTimes = (time: number) => {
    const seconds = Math.floor((time / 1000)) % 60;
    const minutes = Math.floor((time / 1000) / 60);

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  const formatPumpingData = (side: string, item: any) => {
    if (side === 'Left') {
      let timeLeft = '';
      if (item.data.singleDoublePumping === 'single') {
        timeLeft = calculateBreastFeedingTimes(item.data.timeLeftBreast);
      } else {
        timeLeft = calculateBreastFeedingTimes(item.data.timeDoublePumping);
      }

      const leftPumpAmount = item.data.leftAmount !== '' ? item.data.leftAmount : '0';
      const leftDataString = (
        <>
          <Item sx={{ display: 'inline-block' }}>{`${leftPumpAmount}${timeLeft === '00:00' ? 'ml' : `ml `}`}</Item>
          {timeLeft !== '00:00' ?
            <Item sx={{ display: 'inline-block' }}>
              <PiTimer style={{ color: '#34cdeb', fontSize: '1rem', position: 'relative', top: '2px' }} />
              {timeLeft}
            </Item>
            : null}
        </>
      );
      return leftDataString;
    } else {
      let timeRight = '';
      if (item.data.singleDoublePumping === 'single') {
        timeRight = timeRight = calculateBreastFeedingTimes(item.data.timeRightBreast);
      }
      const rightPumpAmount = item.data.rightAmount !== '' ? item.data.rightAmount : '0';
      const rightDataString = (
        <>
          <Item sx={{ display: 'inline-block' }}>{`${rightPumpAmount}${timeRight === '00:00' ? 'ml' : `ml `}`}</Item>
          {timeRight !== '00:00' && timeRight !== '' ?
            <Item sx={{ display: 'inline-block' }}>
              <PiTimer style={{ color: '#34cdeb', fontSize: '1rem', position: 'relative', top: '2px' }} />
              {timeRight}
            </Item>
            : null}
        </>
      );
      return rightDataString;
    }
  }

  const formatTotalPumping = (item: any) => {
    const totalLeft = item.data.leftAmount ? parseFloat(item.data.leftAmount) : 0;
    const totalRight = item.data.rightAmount ? parseFloat(item.data.rightAmount) : 0;
    const total = totalLeft + totalRight;
    return parseFloat(total.toFixed(2));
  }

  const calculateEndTime = (item: any) => {
    // Get total minutes and seconds of both sides
    let totalTime = 0;
    if (item.data.timeDoublePumping && item.data.timeDoublePumping !== 0) {
      totalTime = item.data.timeDoublePumping;
    } else {
      var pauseTime = item.data.pauseTime ? convertPauseTimeToMs(item.data.pauseTime) : 0;
      totalTime = item.data.timeLeftBreast + item.data.timeRightBreast + pauseTime;
    }
    const seconds = Math.floor((totalTime / 1000)) % 60;
    const minutes = Math.floor((totalTime / 1000) / 60);

    // Convert minutes and seconds to milliseconds, add them together and create new Date object with result
    const startTimeDate = new Date(item.data.datetime.seconds * 1000);
    const startTimeMilliseconds = startTimeDate.getTime();
    const timeMilliseconds = (minutes * 60 + seconds) * 1000;
    const newEndDateMilliseconds = startTimeMilliseconds + timeMilliseconds;
    const newEndDate = new Date(newEndDateMilliseconds);

    return newEndDate
  }

  const showStartEndTimes = (item: any) => {
    if (item.type === 'breastfeeding' && item.data.timeLeftBreast === 0 && item.data.timeRightBreast === 0) {
      return false;
    }
    if (item.type === 'pumping' && item.data.timeDoublePumping === 0 && item.data.timeLeftBreast === 0 && item.data.timeRightBreast === 0) {
      return false;
    }
    else {
      return true;
    }
  }

  const calculateTimeAgo = (item: any) => {
    const now = new Date();
    let endDate = new Date(item.data.datetime.seconds * 1000);

    const roundedEndDate = startOfMinute(endDate);
    const roundedNowDate = startOfMinute(now);
    const diffMinutes = Math.abs(differenceInMinutes(roundedEndDate, roundedNowDate));

    const hours = Math.floor(diffMinutes / 60);
    const minutes = Math.floor(diffMinutes % 60);

    let timeString = "";
    if (hours > 0) {
      timeString += `${hours}h`;
    }
    if (minutes > 0) {
      timeString += `${minutes}m`;
    }
    if (hours == 0 && minutes == 0) {
      timeString += "0m"
    }

    return timeString;
  }

  const moreThanOneDayAgo = (item: any) => {
    const now = new Date();
    let endDate = new Date(item.data.datetime.seconds * 1000);

    const diffMinutes = Math.abs(differenceInMinutes(endDate, now));
    const hours = Math.floor(diffMinutes / 60);

    if (hours >= 24) {
      return true;
    } else {
      return false;
    }
  }

  // If there is a very large interval (999+ hours), don't show it in the dashboard
  const largeInterval = (item: any) => {
    const regex = /^(\d+)h\d+m$/;
    const match = item.interval.match(regex);

    if (match) {
      // Extract the numeric part before 'h' and check for a large interval
      const hours = parseInt(match[1], 10);
      if (hours > 999) {
        return true;
      }
    }
    
    return false;
  }

  const getDiaperStatus = (status: string) => {
    const iconColor = {
      color: '#eda200'
    }
    let icon = <TbDroplet style={iconColor} />;
    switch (status) {
      case 'Wet': { status = 'Nat'; icon = <TbDroplet style={iconColor} />; break; }
      case 'Dirty': { status = 'Vuil'; icon = <FaPoop style={iconColor} />; break; }
      case 'Mixed': { status = 'Gemengd'; icon = <TbDropletHalfFilled style={iconColor} />; break; }
      case 'Dry': { status = 'Droog'; icon = <TbDropletOff style={iconColor} />; break; }
      default: { status = ''; break; }
    }
    return <>{icon} {status}</>
  }

  const getFeedingType = (type: string) => {
    switch (type) {
      case 'Formula': { type = 'Kunstvoeding'; break; }
      case 'Breast milk': { type = 'Moedermelk'; break; }
      default: { type = ''; break; }
    }
    return type
  }

  const showUserOrPartner = (userId: string) => {
    let nameToShow = <></>;
    if (partner && partner.userId != null) {
      if (userId === user.userId) {
        if (user.photoUrl && user.photoUrl !== "") {
          nameToShow = <Avatar alt={user.name} src={user.photoUrl} sx={{ backgroundColor: '#6f91b3', width: 24, height: 24, right: 10 }}></Avatar>
        } else {
          nameToShow = <Avatar sx={{ backgroundColor: '#6f91b3', width: 24, height: 24, fontSize: '0.8rem', fontWeight: 'bold', right: 10 }}>{user.name.charAt(0)}</Avatar>
        }
      }
      else if (userId === partner.userId) {
        if (partner.photoUrl && partner.photoUrl !== "") {
          nameToShow = <Avatar alt={partner.name} src={partner.photoUrl} sx={{ backgroundColor: '#6f91b3', width: 24, height: 24, right: 10 }}></Avatar>
        } else {
          nameToShow = <Avatar sx={{ backgroundColor: '#6f91b3', width: 24, height: 24, fontSize: '0.8rem', fontWeight: 'bold', right: 10 }}>{partner.name.charAt(0)}</Avatar>
        }
      }
    }
    return nameToShow;
  }

  const getReactionEmoji = (reaction: string) => {
    let emoji = null;
    switch(reaction) {
      case 'happy':
        emoji = <PiSmiley style={{ color: 'white' }} />
        break;
      case 'neutral':
        emoji = <PiSmileyMeh style={{ color: 'white' }} />
        break;
      case 'sad':
        emoji = <PiSmileySad style={{ color: 'white' }} />
        break;
      default:
        emoji = null;
        break;
    }
    return <Item>{emoji}</Item>
  }

  // #region createGritItem
  const createGridItem = (item: any) => {
    let gridItem = <div></div>;
    switch (item.type) {
      case 'diaper': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <MdBabyChangingStation style={{ color: '#eda200' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={4}>
              <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
            </Grid>
            <Grid xs={6}>
              <Item className={styles.infoItem}>{getDiaperStatus(item.data.diaperStatus)}</Item>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }} >{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'breastfeeding': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <FaPersonBreastfeeding style={{ color: '#00b012' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={5}>
              {showStartEndTimes(item) ?
                <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")} - {format(calculateEndTime(item), "HH:mm")}</Item>
                :
                <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              }
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
              { item.interval && !largeInterval(item) ?
                <div>
                  <PiArrowsHorizontalFill className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{item.interval}</Item>
                </div>
                : 
                null
              }
            </Grid>
            <Grid xs={5}>
              <Item>Start: {item.data.startSide === 'L' ? 'Links' : 'Rechts'}</Item>
              {item.data.timeLeftBreast > 0 ? <Item>L: <PiTimer style={{ color: '#00b012', fontSize: '1rem', position: 'relative', top: '2px' }} />{calculateBreastFeedingTimes(item.data.timeLeftBreast)}</Item> : null}
              {item.data.timeRightBreast > 0 ? <Item>R: <PiTimer style={{ color: '#00b012', fontSize: '1rem', position: 'relative', top: '2px' }} />{calculateBreastFeedingTimes(item.data.timeRightBreast)}</Item> : null}
              {item.data.pauseTime && item.data.pauseTime.minutes > 0 ? <Item>Pauze: <PiTimer style={{ color: '#00b012', fontSize: '1rem', position: 'relative', top: '2px' }} />{calculateBreastFeedingTimes(convertPauseTimeToMs(item.data.pauseTime))}</Item> : null}
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'bottlefeeding': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <GiBabyBottle style={{ color: '#18d99c' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={4}>
              <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
              { item.interval && !largeInterval(item) ?
                <div>
                  <PiArrowsHorizontalFill className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{item.interval}</Item>
                </div>
                : 
                null
              }
            </Grid>
            <Grid xs={6}>
              <Item>{item.data.bottleAmount} ml</Item>
              <Item>{getFeedingType(item.data.milkType)}</Item>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'pumping': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <GiRoundBottomFlask style={{ color: '#34cdeb' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={5}>
              {showStartEndTimes(item) ?
                <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")} - {format(calculateEndTime(item), "HH:mm")}</Item>
                :
                <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              }
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
            </Grid>
            <Grid xs={5}>
              <Grid xs={1}>
                <Item>Totaal: {formatTotalPumping(item)} ml</Item>
              </Grid>
              <Grid xs={4}>
                { item.data.leftAmount !== "" || item.data.timeLeftBreast !== 0 || (item.data.singleDoublePumping === "double" && item.data.timeDoublePumping !== 0) ? <Item className={styles.pumpingData}>L: {formatPumpingData('Left', item)}</Item> : null }
                { item.data.rightAmount !== "" || item.data.timeRightBreast !== 0 ? <Item className={styles.pumpingData}>R: {formatPumpingData('Right', item)}</Item> : null }
              </Grid>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'temperatures': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <FaTemperatureHigh style={{ color: '#345ceb' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={4}>
              <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
            </Grid>
            <Grid xs={6}>
              <Item>{item.data.temperature}</Item>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'weights': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <FaWeightScale style={{ color: '#8702cf', paddingRight: 3 }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={4}>
              <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
            </Grid>
            <Grid xs={6}>
              <Item>{item.data.weight} gr</Item>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'medication': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <FaPrescriptionBottleMedical style={{ color: '#f02e2e' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={4}>
              <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
            </Grid>
            <Grid xs={6}>
              {item.data.vitamineK || item.data.vitamineD ?
                <Grid xs={3}>
                  <Item>{item.data.vitamineK || item.data.vitamineD ? 'Vitamine' : null} {item.data.vitamineK && item.data.vitamineD ? 'K & D' : item.data.vitamineK && !item.data.vitamineD ? 'K' : !item.data.vitamineK && item.data.vitamineD ? 'D' : null}</Item>
                </Grid>
                :
                null
              }
              <Grid xs={3}>
                {item.data.medicine ?
                  <Item style={{ wordWrap: 'break-word' }}>{item.data.medicine} - {item.data.amount}</Item>
                  :
                  <Item></Item>
                }
              </Grid>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'food': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <BiSolidBowlHot style={{ color: '#eb5200' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={4}>
              <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
              { item.interval && !largeInterval(item) ?
                <div>
                  <PiArrowsHorizontalFill className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{item.interval}</Item>
                </div>
                : 
                null
              }
            </Grid>
            <Grid xs={6}>
              <Grid xs={6}>
                {item.data.food ?
                  <Item style={{ wordWrap: 'break-word' }}>
                    {item.data.food}
                    {item.data.amount !== 0 && ` - ${item.data.amount} gr`}
                  </Item>
                  :
                  <Item></Item>
                }
              </Grid>
              <Grid xs={1}>
                {item.data.reaction != '' ?
                getReactionEmoji(item.data.reaction)
                :
                <Item></Item>
              }
              </Grid>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'drink': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <FaMugHot style={{ color: '#a8d600' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={4}>
              <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
              { item.interval && !largeInterval(item) ?
                <div>
                  <PiArrowsHorizontalFill className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{item.interval}</Item>
                </div>
                : 
                null
              }
            </Grid>
            <Grid xs={6}>
              <Grid xs={6}>
                {item.data.drink ?
                  <Item style={{ wordWrap: 'break-word' }}>
                    {item.data.drink}
                    {item.data.amount !== 0 && ` - ${item.data.amount} ml`}
                  </Item>
                  :
                  <Item></Item>
                }
              </Grid>
              <Grid xs={1}>
                {item.data.reaction != '' ?
                getReactionEmoji(item.data.reaction)
                :
                <Item></Item>
              }
              </Grid>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: 0 }}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.comment != '' ? item.data.comment : null}</Item>
            </Grid>
          </Grid>
        break;
      }
      case 'notes': {
        gridItem =
          <Grid container spacing={2} className={styles.gridContainer}>
            <Grid xs={1}>
              <IconButton onClick={() => handleOnItemClick(item)} sx={{ margin: 0, padding: 0 }}>
                <BsPen style={{ color: '#ff42b7' }} className={styles.gridIcon} />
              </IconButton>
            </Grid>
            <Grid xs={4}>
              <Item className={styles.timeItem}>{format(new Date(item.data.datetime.seconds * 1000), "HH:mm")}</Item>
              {!moreThanOneDayAgo(item) ?
                <div>
                  <MdTimelapse className={styles.timelapseIcon} />
                  <Item className={styles.timeAgo}>{calculateTimeAgo(item)}</Item>
                </div>
                :
                null
              }
            </Grid>
            <Grid xs={6}>
              <Item sx={{ fontStyle: 'italic' }}>{item.data.note != '' ? item.data.note : null}</Item>
            </Grid>
            <Grid xs={1}>
              <Item>{showUserOrPartner(item.userId)}</Item>
            </Grid>
          </Grid>
        break;
      }
      default: {
        gridItem = <div></div>;
        break;
      }
    }
    return gridItem;
  }
  // #endregion createGritItem

  // #region daily stats
  const handleDailyStats = (clickedDate: Date) => {
    if (dailyStats.showStats && isSameDay(dailyStats.dateStats, clickedDate)) {
      // Same day was clicked so just close and reset the stats
      setDailyStats({ showStats: false, dateStats: new Date(), stats: [] });
    }
    else if (dataCurrentBaby && dataCurrentBaby.length > 0) {
      const statsArray = dataCurrentBaby.filter((item: any) => {
        const currentItemDate = new Date(item.data.datetime.seconds * 1000);
        return isSameDay(currentItemDate, clickedDate);
      });

      const stats: any[] = [];
      const itemTypes = ['breastfeeding', 'pumping', 'bottlefeeding', 'diaper', 'food', 'drink'];

      itemTypes.forEach((type: string) => {
        const typeItems = statsArray.filter((item) => item.type === type);

        if (typeItems.length > 0) {
          const time = typeItems.reduce((totalTime: number, item) => {
            if (item.type === 'breastfeeding') {
              return totalTime + item.data.timeLeftBreast + item.data.timeRightBreast;
            }
            if (item.type === 'pumping') {
              if (item.data.singleDoublePumping === 'single') {
                return totalTime + item.data.timeLeftBreast + item.data.timeRightBreast;
              } else {
                return totalTime + item.data.timeDoublePumping;
              }
            }
            return totalTime;
          }, 0);

          const amount = typeItems.reduce((totalAmount: number, item) => {
            if (item.type === 'bottlefeeding') {
              return totalAmount + parseInt(item.data.bottleAmount);
            }
            if (item.type === 'pumping') {
              const totalLeft = item.data.leftAmount ? parseInt(item.data.leftAmount) : 0;
              const totalRight = item.data.rightAmount ? parseInt(item.data.rightAmount) : 0;
              return totalAmount + totalLeft + totalRight;
            }
            if (item.type === 'food' || item.type === 'drink') {
              return totalAmount + parseInt(item.data.amount);
            }
            return totalAmount;
          }, 0);

          const wet = typeItems.reduce((totalAmount: number, item) => {
            if (item.type === 'diaper' && item.data.diaperStatus === 'Wet') {
              return totalAmount + 1;
            }
            return totalAmount;
          }, 0);
          const dirty = typeItems.reduce((totalAmount: number, item) => {
            if (item.type === 'diaper' && item.data.diaperStatus === 'Dirty') {
              return totalAmount + 1;
            }
            return totalAmount;
          }, 0);
          const mixed = typeItems.reduce((totalAmount: number, item) => {
            if (item.type === 'diaper' && item.data.diaperStatus === 'Mixed') {
              return totalAmount + 1;
            }
            return totalAmount;
          }, 0);
          const dry = typeItems.reduce((totalAmount: number, item) => {
            if (item.type === 'diaper' && item.data.diaperStatus === 'Dry') {
              return totalAmount + 1;
            }
            return totalAmount;
          }, 0);

          stats.push({ key: type, value: typeItems.length, time, amount, wet, dirty, mixed, dry });
        }
      });

      setDailyStats({ showStats: true, dateStats: clickedDate, stats });
    }
  }

  const getTypeIcon = (type: string) => {
    const typeMappings: { [key: string]: React.ReactNode } = {
      'diaper': <MdBabyChangingStation style={{ color: '#eda200', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'bottlefeeding': <GiBabyBottle style={{ color: '#18d99c', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'breastfeeding': <FaPersonBreastfeeding style={{ color: '#00b012', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'pumping': <GiRoundBottomFlask style={{ color: '#34cdeb', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'temperatures': <FaTemperatureHigh style={{ color: '#345ceb', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'weights': <FaWeightScale style={{ color: '#8702cf', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'medication': <FaPrescriptionBottleMedical style={{ color: '#f02e2e', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'food': <BiSolidBowlHot style={{ color: '#eb5200', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'drink': <FaMugHot style={{ color: '#a8d600', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
      'notes': <BsPen style={{ color: '#ff42b7', fontSize: '1.4rem', position: 'relative', top: 5 }} />,
    };

    return typeMappings[type] || type;
  }

  const getTypeColor = (type: string) => {
    let color = '#F0FFFF';
    switch (type) {
      case 'diaper': { color = '#eda200'; break; }
      case 'breastfeeding': { color = '#00b012'; break; }
      case 'bottlefeeding': { color = '#18d99c'; break; }
      case 'pumping': { color = '#34cdeb'; break; }
      case 'temperatures': { color = '#345ceb'; break; }
      case 'weights': { color = '#8702cf'; break; }
      case 'medication': { color = '#f02e2e'; break; }
      case 'food': { color = '#eb5200'; break; };
      case 'drink': { color = '#a8d600'; break; };
      case 'notes': { color = '#ff42b7'; break; }
      default: { color = '#F0FFFF'; break; }
    }
    return color;
  }

  const showStatsIcon = (date: Date) => {
    if (!dataCurrentBaby || dataCurrentBaby.length === 0) {
      return false;
    }

    const statsArray = dataCurrentBaby.filter((item: any) => {
      const currentItemDate = new Date(item.data.datetime.seconds * 1000);
      return isSameDay(currentItemDate, date);
    });

    const itemTypes = ['breastfeeding', 'pumping', 'bottlefeeding', 'diaper'];
    const firstMatchingItem = statsArray.find((item: any) => itemTypes.includes(item.type));

    return !!firstMatchingItem;
  }

  const showComma = (stat: any, position: number): string => {
    let comma = '';
    if (
      (position === 1 && (stat.wet > 0 && (stat.dirty > 0 || stat.mixed > 0 || stat.dry > 0))) ||
      (position === 2 && (stat.dirty > 0 && (stat.mixed > 0 || stat.dry > 0))) ||
      (position === 3 && (stat.mixed > 0 && stat.dry > 0))
    ) {
      comma = ', ';
    }
    return comma;
  }
  // #endregion daily stats

  useEffect(() => {
    const dataArray: any[] = [];
    setLoading(true);
    trackingData.map((item: any) => {
      if (item.data.babyId == baby.babyId) {
        dataArray.push(item);
      }
    });
    setDataCurrentBaby(dataArray);
    setLoading(false);
  }, [trackingData, baby]);

  useEffect(() => {
    if (closingForm) {
      setDailyStats({ showStats: false, dateStats: new Date(), stats: [] });
    }
  }, [closingForm]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // This will cause the component to re-render, updating the 'time ago' values
      setForceUpdate(prevForceUpdate => !prevForceUpdate);
    }, 10000); // Update every 10 seconds
  
    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  return (
    <div>
      {dataCurrentBaby && dataCurrentBaby.length > 0 ?
        <div>
          {dataCurrentBaby.map((item: any, index: number) => {
            // Check if it's the first item or the date is different from the previous item
            const isFirstItem = index === 0;
            const isDifferentDate = !isFirstItem && format(new Date(item.data.datetime.seconds * 1000), "dd-MM-yyyy") != format(new Date(dataCurrentBaby[index - 1].data.datetime.seconds * 1000), "dd-MM-yyyy");

            return (
              <div key={index}>
                <div className={styles.outerWrapper}>
                  <div style={{ borderTop: (isFirstItem || isDifferentDate) ? 'none' : '1px solid #dbdbdb' }}>
                    {(isFirstItem || isDifferentDate) && (
                      <div>
                        <Typography component={'span'} variant="h4" sx={{ display: 'block', marginTop: !isFirstItem ? '30px' : '0px' }} onClick={() => handleDailyStats(new Date(item.data.datetime.seconds * 1000))} className={styles.dateIconWrapper}>
                          <div className={styles.dateHeader}>{format(new Date(item.data.datetime.seconds * 1000), "EEEEEE dd MMMM yyyy", { locale: nl })}</div>
                          {showStatsIcon(new Date(item.data.datetime.seconds * 1000)) ? <MdQueryStats size={20} className={styles.statsIcon} /> : null}
                        </Typography>
                        {dailyStats.stats.length > 0 ? (
                          <div style={{ display: dailyStats.showStats && isSameDay(dailyStats.dateStats, new Date(item.data.datetime.seconds * 1000)) ? 'block' : 'none' }} className={styles.dailyStats}>
                            <div>
                              {dailyStats.stats.map((stat: any) => (
                                <p
                                  style={{
                                    color: getTypeColor(stat.key),
                                  }}
                                  key={stat.key}
                                >
                                  {getTypeIcon(stat.key)} {stat.value}x,
                                  {stat.key === 'bottlefeeding' ? ` ${stat.amount}ml` : null}
                                  {stat.key === 'breastfeeding' ? ` ${calculateBreastFeedingTimes(stat.time)}min` : null}
                                  {stat.key === 'pumping' ? ` ${calculateBreastFeedingTimes(stat.time)}min, ${stat.amount}ml` : null}
                                  {stat.key === 'diaper' ? (
                                    `
                                    ${stat.wet > 0 ? `Nat: ${stat.wet}${showComma(stat, 1)}` : ''}
                                    ${stat.dirty > 0 ? `Vuil: ${stat.dirty}${showComma(stat, 2)}` : ''}
                                    ${stat.mixed > 0 ? `Gemengd: ${stat.mixed}${showComma(stat, 3)}` : ''}
                                    ${stat.dry > 0 ? `Droog: ${stat.dry}` : ''}
                                  `
                                  ) : null}
                                  {stat.key === 'food' ? ` ${stat.amount}gr` : null}
                                  {stat.key === 'drink' ? ` ${stat.amount}ml` : null}
                                </p>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                    {createGridItem(item)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        : (baby != null && !loading) ?
          <div className={styles.noTrackingDataContent}>
            <Typography variant="body2">
              Je hebt nog geen gegevens ingevoerd.
            </Typography>
            <Typography variant="body2">
              Klik op één van bovenstaande formulieren om te starten met tracken.
            </Typography>
          </div>
          :
          null
      }
    </div>
  );
}

export default TrackingData;