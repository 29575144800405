import { Duration } from '../components/duration-picker/DurationPicker';

export const convertPauseTimeToMs = (pauseTime: Duration): number => {
    const hoursInMilliseconds = pauseTime.hours * 60 * 60 * 1000;
    const minutesInMilliseconds = pauseTime.minutes * 60 * 1000;
    const secondsInMilliseconds = pauseTime.seconds * 1000;
    const totalPauseTime = hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds;
    return totalPauseTime;
}

export const showTimeInMinAndSec = (time: number): string => {
    const seconds = Math.floor((time / 1000)) % 60;
    const minutes = Math.floor((time / 1000) / 60);

    return minutes.toString().padStart(2, "0") + ':' + seconds.toString().padStart(2, "0")
}