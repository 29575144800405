import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FaPersonBreastfeeding } from 'react-icons/fa6';
import CustomDateTimePicker from '../datepicker/CustomDateTimePicker';
import { Duration } from '../duration-picker/DurationPicker';
import { convertPauseTimeToMs, showTimeInMinAndSec } from '../../utils/Utils';
import PauseDialog from './PauseDialog';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import Stopwatch from '../stopwatch/Stopwatch';
import styles from './Breastfeeding.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';
import '../../styles/FormStyles.css';

interface BreastfeedingProps {
  hideBreastfeeding: boolean;
  onFormClose: (formType: string) => void;
  lastBreastFeeding: any;
  breastfeedingItem: any;
}

interface Values {
  datetime: Date;
  timeLeftBreast: number;
  timeRightBreast: number;
  pauseTime: Duration;
  startSide: string;
  comment: string;
}

const Breastfeeding: FunctionComponent<BreastfeedingProps> = ({ hideBreastfeeding, onFormClose, lastBreastFeeding, breastfeedingItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeLeftBreast, setTimeLeftBreast] = useState(0);
  const [timeRightBreast, setTimeRightBreast] = useState(0);
  const [pauseTime, setPauseTime] = useState<Duration>({ hours: 0, minutes: 0, seconds: 0 });
  const [startSide, setStartSide] = useState(lastBreastFeeding && lastBreastFeeding.data.startSide === 'L' ? 'R' : 'L');
  const [startEndTime, setStartEndTime] = useState('start');
  const [comment, setComment] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const [open, setOpen] = useState(false);

  const sm = useMediaQuery('(max-width:416px)');

  const handlePauseOpen = () => {
    setOpen(true);
  };

  const handlePauseClose = (pauseTime: Duration) => {
    setPauseTime(pauseTime);
    setOpen(false);
  };

  const handleComment = (value: string) => {
    setComment(value);
  }

  const handleStartSide = (side: string) => {
    setStartSide(side);
  }

  const calculateStartTime = () => {
    // Get total minutes and seconds of both sides
    const totalTime = timeLeftBreast + timeRightBreast;
    const seconds = Math.floor((totalTime / 1000)) % 60;
    const minutes = Math.floor((totalTime / 1000) / 60);

    // Startdate is selected as enddate, so recalculate to starttime
    const endTimeMilliseconds = startDate.getTime();
    const timeMilliseconds = (minutes * 60 + seconds) * 1000;
    const newStartDateMilliseconds = endTimeMilliseconds - timeMilliseconds;
    const newStartDate = new Date(newStartDateMilliseconds);

    return newStartDate
  }

  const calculateEndTime = () => {
    const startTimeMs = startDate.getTime();
    const totalPauseTime = convertPauseTimeToMs(pauseTime);

    const totalTimeSinceStart = startTimeMs + timeLeftBreast + timeRightBreast + totalPauseTime;
    let newEndDate = new Date(totalTimeSinceStart);
    setEndDate(newEndDate);
  }

  const handleRunningTimer = (isRunning: boolean) => {
    setIsVisible(isRunning);

    let currentStartDate = startDate;
    if (timeLeftBreast == 0 && timeRightBreast == 0) {
      // Timer is turned on for first time, so reset startdate to now
      currentStartDate = new Date();
      setStartDate(currentStartDate);
    }
  
    const now = new Date();
    const totalActivityTime = Math.abs(now.getTime() - currentStartDate.getTime()); // in milliseconds
    const totalTimeBreast = timeLeftBreast + timeRightBreast;

    const pauseTimeInMilliseconds = totalActivityTime - totalTimeBreast;
    if (pauseTimeInMilliseconds < 0) {
      setPauseTime({ hours: 0, minutes: 0, seconds: 0 });
    } else {
      // Convert milliseconds to hours, minutes, and seconds
      const convertedHours = Math.floor(pauseTimeInMilliseconds / 1000 / 60 / 60);
      const convertedMinutes = Math.floor((pauseTimeInMilliseconds / 1000 / 60) % 60);
      const convertedSeconds = Math.floor((pauseTimeInMilliseconds / 1000) % 60);

      setPauseTime({ hours: convertedHours, minutes: convertedMinutes, seconds: convertedSeconds });
    }

    calculateEndTime();
  };

  const handleAddMinutes = () => {
    const newMinutes = pauseTime.minutes + 1;
    setPauseTime(prevState => ({ ...prevState, minutes: newMinutes }));
  }

  const addBreastfeeding = async (data: any) => {
    // Saving time as miliseconds
    const BreastfeedingsCollection = collection(db, "trackingdata", user.userId, "breastfeedings");
    await setDoc(doc(BreastfeedingsCollection), {
      datetime: data.datetime,
      timeLeftBreast: data.timeLeftBreast,
      timeRightBreast: data.timeRightBreast,
      pauseTime: data.pauseTime,
      startSide: startSide,
      comment: data.comment,
      babyId: baby.babyId
    });

    onFormClose('breastfeeding');
  }

  const updateBreastfeeding = async (data: any) => {
    const breastfeedingCollection = collection(db, "trackingdata", breastfeedingItem.userId, "breastfeedings");
    const breastfeedingDocRef = doc(breastfeedingCollection, breastfeedingItem.id);

    try {
      await updateDoc(breastfeedingDocRef, {
        datetime: data.datetime,
        timeLeftBreast: data.timeLeftBreast,
        timeRightBreast: data.timeRightBreast,
        pauseTime: data.pauseTime,
        startSide: startSide,
        comment: data.comment
      });
    } catch (error) {
      console.error('Error updating breastfeeding:', error);
    }

    onFormClose('breastfeeding');
  }

  const deleteBreastfeeding = async () => {
    const breastfeedingCollection = collection(db, "trackingdata", breastfeedingItem.userId, "breastfeedings");
    const breastfeedingDocRef = doc(breastfeedingCollection, breastfeedingItem.id);

    try {
      await deleteDoc(breastfeedingDocRef);
    } catch (error) {
      console.error('Error deleting breastfeeding:', error);
    }

    onFormClose('breastfeeding');
  }

  const resetForm = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setTimeLeftBreast(0);
    setTimeRightBreast(0);
    setPauseTime({ hours: 0, minutes: 0, seconds: 0 });
    setComment('');
    setStartEndTime('start');
    setStartSide(lastBreastFeeding && lastBreastFeeding.data.startSide === 'L' ? 'R' : 'L');
  }

  useEffect(() => {
    if ((timeLeftBreast != 0 && timeRightBreast == 0) && startSide == 'R') {
      // Left breast triggered first
      handleStartSide('L');
    }
    if ((timeRightBreast != 0 && timeLeftBreast == 0) && startSide == 'L') {
      // Right breast triggered first
      handleStartSide('R');
    }
    calculateEndTime();
  }, [timeLeftBreast, timeRightBreast]);

  useEffect(() => {
    if (breastfeedingItem != null) {
      const start = new Date(breastfeedingItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setStartEndTime('start');
      setTimeLeftBreast(breastfeedingItem.data.timeLeftBreast);
      setTimeRightBreast(breastfeedingItem.data.timeRightBreast);
      setPauseTime(breastfeedingItem.data.pauseTime ?? { hours: 0, minutes: 0, seconds: 0 });
      setStartSide(breastfeedingItem.data.startSide);
      setComment(breastfeedingItem.data.comment);
    }
  }, [breastfeedingItem]);

  useEffect(() => {
    if (breastfeedingItem == null) {
      resetForm();
    }
  }, [hideBreastfeeding]);

  useEffect(() => {
    if (pauseTime) {
      calculateEndTime();
    }
  }, [pauseTime]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          timeLeftBreast: timeLeftBreast,
          timeRightBreast: timeRightBreast,
          pauseTime: pauseTime,
          startSide: startSide,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (startEndTime == 'end') {
            values.datetime = calculateStartTime();
          }

          if (breastfeedingItem != null) {
            updateBreastfeeding(values);
          } else {
            addBreastfeeding(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <FaPersonBreastfeeding style={headerIcon} />
              <span style={sm ? headerTitleSX : headerTitleMD}>Borstvoeding</span>
            </div>

            <div className="form-body">
              <div>
                <div className={styles.stopwatchesGroup} style={{ marginTop: '5px' }}>
                  <div className={`${styles.stopwatchChild} ${styles.space}`}>
                    Links
                    <Stopwatch onTimeChange={setTimeLeftBreast} onRunning={handleRunningTimer} breastfeedingItem={timeLeftBreast} />
                  </div>
                  <div className={styles.stopwatchChild}>
                    Rechts
                    <Stopwatch onTimeChange={setTimeRightBreast} onRunning={handleRunningTimer} breastfeedingItem={timeRightBreast} />
                  </div>
                </div>

                <div className="group-space">
                  <Typography component={'span'} variant="h4">
                    <div>Start voedingskant {(lastBreastFeeding && breastfeedingItem == null) && <span>(laatste voeding: {lastBreastFeeding.data.startSide == 'L' ? 'Links' : 'Rechts'})</span>}</div>
                  </Typography>
                  <div className={`radio-group ${styles.radioGroup}`} role="group" aria-labelledby="my-radio-group" style={{ marginTop: '5px' }}>
                    <label
                      onClick={() => { setFieldValue("startSide", "L"); setStartSide('L') }}
                      style={{ backgroundColor: startSide === "L" ? '#18d99c' : 'transparent', color: startSide === "L" ? '#000' : '#F0FFFF' }}>
                      Links
                    </label>
                    <label
                      onClick={() => { setFieldValue("startSide", "R"); setStartSide('R') }}
                      style={{ backgroundColor: startSide === "R" ? '#18d99c' : 'transparent', color: startSide === "R" ? '#000' : '#F0FFFF' }}>
                      Rechts
                    </label>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '20px' }}>
                <div className={styles.dateTimeGroup}>
                  <div className={styles.dateTimeWrapper}>
                    <label className={styles.dateTimeLabel}>Duur</label>
                    <div className={styles.dateTimeDiv}>
                      <span className={styles.endDate}>{showTimeInMinAndSec(timeLeftBreast + timeRightBreast)}</span>
                    </div>
                  </div>
                  { isVisible ?
                  <div>
                    <div className={styles.dateTimeWrapper}>
                      <label className={styles.dateTimeLabel}>Gepauzeerd</label>
                      <div className={styles.dateTimeDiv}>
                        <div onClick={handlePauseOpen} className={styles.pauseLabel}>{pauseTime.hours}U{pauseTime.minutes}Min{pauseTime.seconds}sec</div>
                        <button type="button" className={styles.addTimeBtn} onClick={handleAddMinutes}>
                          +1
                        </button>
                      </div>
                    </div>
                    <div className={styles.dateTimeWrapper}>
                      <label className={styles.dateTimeLabel}>Starttijd</label>
                      <div className={styles.dateTimeDiv}>
                        <CustomDateTimePicker
                          startDate={startDate}
                          onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date); }}
                        />
                      </div>
                    </div>
                    <div className={styles.dateTimeWrapper}>
                      <label className={styles.dateTimeLabel}>Eindtijd</label>
                      <div className={styles.dateTimeDiv}>
                        <span className={styles.endDate}>{format(endDate, 'dd-MM-yyyy HH:mm')}</span>
                      </div>
                    </div>
                  </div>
                  : null}
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Opmerking</div>
                </Typography>
                <Field
                  as="textarea"
                  name="comment"
                  onChange={(e: any) => handleComment(e.target.value)}
                  value={comment}
                  className="comment-field"
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                {breastfeedingItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteBreastfeeding}
                    sx={{
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                }
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <PauseDialog
        open={open}
        onClose={handlePauseClose}
        pauseTime={pauseTime}
      />
    </div>
  );
}

export default Breastfeeding;
