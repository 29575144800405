import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1C2128',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      padding: '10px 0px',
      color: '#F0FFFF',
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 600,
      padding: '10px 0px',
      color: '#F0FFFF',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 600,
      padding: '10px 0px',
      color: '#F0FFFF',
    },
    h4: {
      fontSize: '0.9rem',
      fontStyle: 'italic',
      padding: '10px 0px',
      color: '#dbdbdb',
    },
    body2: {
      fontSize: '1rem',
      color: '#F0FFFF',
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#F0FFFF',
          textDecoration: 'underline'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'block',
          backgroundColor: '#3d4aad',
          color: 'white',
          textTransform: 'none',
          boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.2)',
          borderRadius: '24px',
          padding: '10px 20px',
          marginTop: '20px',
          fontSize: '16px',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#293487',
          },
        },
      },
    },
  },
});

export default theme;