import { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { ChildrenContext } from "../../context/ChildrenContext";
import { PartnerContext } from "../../context/PartnerContext";
import { UserContext } from "../../context/UserContext";
import { BabyContext } from "../../context/BabyContext";
import styles from './Baby.module.css';
import { AiOutlineMan, AiOutlineWoman } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { LiaBirthdayCakeSolid } from 'react-icons/lia';
import { MdDeleteForever } from 'react-icons/md';
import { BiTime } from 'react-icons/bi';
import { FaRegEdit } from 'react-icons/fa';
import { IoScaleOutline } from 'react-icons/io5';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import BabyDialog from "./BabyDialog";
import BabyDeleteDialog from "./BabyDeleteDialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getChildrenData } from "../../services/DataService";
import nl from 'date-fns/locale/nl';

const { differenceInCalendarDays, format, parse } = require('date-fns');

const stylesSM = {
  fontSize: '1.3rem',
  marginRight: '10px',
  verticalAlign: '-12%'
}
const stylesMD = {
  fontSize: '1.5rem',
  marginRight: '10px',
  verticalAlign: '-8%'
}

function Baby() {
  const [user, setUser] = useContext(UserContext);
  const [partner, setPartner] = useContext(PartnerContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [allChildren, setAllChildren] = useContext(ChildrenContext);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editBaby, setEditBaby] = useState(null);
  const [deleteBaby, setDeleteBaby] = useState(null);
  const sm = useMediaQuery('(max-width:416px)');

  const handleBabyOpen = () => {
    setOpen(true);
  };

  const handleBabyClose = () => {
    getChildren();
    setEditBaby(null);
    setOpen(false);
  };

  const handleBabyDeleteClose = (cancel: boolean) => {
    if (!cancel) {
      getChildren();
    }
    setDeleteBaby(null);
    setOpenDelete(false);
  };

  const handleDeleteBaby = (babyItem: any) => {
    setDeleteBaby(babyItem);
    setOpenDelete(true);
  }

  const handleEditBaby = (babyItem: any) => {
    setEditBaby(babyItem);
    setOpen(true);
  }

  const showAge = (baby: any) => {
    const birthDate = new Date(baby.data.birthday.seconds * 1000)
    const targetDate = new Date();

    // Calculate the difference in days
    const differenceInDays = differenceInCalendarDays(targetDate, birthDate);

    // Calculate years, months, and days
    const years = Math.floor(differenceInDays / 365);
    const months = Math.floor((differenceInDays % 365) / 30);
    const days = differenceInDays % 30;

    // Format the result
    const result = `${years !== 0 ? `${years}j` : ''}${months !== 0 ? `${months}m` : ''}${days}d`;
    return result;
  }

  const getChildren = () => {
    let partnerId = "";
    if (partner && user.partnerSynced) {
      partnerId = partner.userId;
    }
    getChildrenData(user.userId, partnerId).then((data) => {
      setAllChildren(data);
      if (baby != null) {
        // Update baby in state
        const updatedBaby = data.find((x: any) => x.babyId == baby.babyId);
        if (updatedBaby && baby.babyId == updatedBaby.babyId) {
          setBaby(updatedBaby);
        } else {
          setBaby(data[0]);
        }
      }
    })
  }

  return (
    <div className={styles.babyContainer}>
      {allChildren && allChildren.length > 0 ? (
        allChildren.map((babyItem: any, index: any) => (
          <div key={index} className={styles.babyContent} style={{ border: babyItem.data.gender == 'Boy' ? '3px solid #097bd9' : '3px solid #ff70d2' }}>
            <div>
              <Typography variant="h3">
                <BsFillPersonFill style={sm ? stylesSM : stylesMD} /> {babyItem.data.name}
              </Typography>
              <Typography variant="h3">
                {babyItem.data.gender == 'Boy' ? <AiOutlineMan style={sm ? stylesSM : stylesMD} /> : <AiOutlineWoman style={sm ? stylesSM : stylesMD} />} {babyItem.data.gender == 'Boy' ? 'Jongen' : 'Meisje'}
              </Typography>
              <Typography variant="h3">
                <LiaBirthdayCakeSolid style={sm ? stylesSM : stylesMD} /> {format(babyItem.data.birthday.toDate(), "dd MMMM yyyy", { locale: nl })} ({showAge(babyItem)})
              </Typography>
              <Typography variant="h3">
                <BiTime style={sm ? stylesSM : stylesMD} /> {format(babyItem.data.birthtime.toDate(), "HH:mm")}
              </Typography>
              <Typography variant="h3">
                <IoScaleOutline style={sm ? stylesSM : stylesMD} /> {babyItem.data.birthweight} gram
              </Typography>
            </div>
            <div className={styles.btnGroup}>
              <IconButton disabled={babyItem.data.createdByUser != user.userId} onClick={() => handleDeleteBaby(babyItem)} sx={{ p: 0 }}>
                <MdDeleteForever style={{ color: babyItem.data.createdByUser == user.userId ? '#f02e2e' : 'grey' }} size={40} />
              </IconButton>
              <IconButton disabled={babyItem.data.createdByUser != user.userId} onClick={() => handleEditBaby(babyItem)} sx={{ p: 0 }}>
                <FaRegEdit style={{ color: babyItem.data.createdByUser == user.userId ? 'yellow' : 'grey' }} size={30} />
              </IconButton>
            </div>
          </div>
        ))
      ) : (
        <div className={styles.noBabyContent}>
          <Typography variant="body2">
            Je hebt nog geen kind toegevoegd.
          </Typography>
        </div>
      )}

      <div className={styles.babyBtn}>
        <Button
          variant="contained"
          type="button"
          onClick={handleBabyOpen}
        >
          Kind toevoegen
        </Button>
      </div>

      <BabyDialog
        open={open}
        onClose={handleBabyClose}
        babyItem={editBaby}
      />

      <BabyDeleteDialog
        open={openDelete}
        onClose={handleBabyDeleteClose}
        babyItem={deleteBaby}
      />
    </div>
  );
}

export default Baby;
