import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FaMugHot } from "react-icons/fa6";
import Reaction, { ReactionType } from '../reaction/Reaction';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import styles from './Drink.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../styles/FormStyles.css';

interface DrinkProps {
  hideDrink: boolean;
  onFormClose: (formType: string) => void;
  drinkItem: any;
}

interface Values {
  datetime: Date;
  drink: string;
  amount: number;
  reaction: ReactionType | '';
  comment: string;
}

const Drink: FunctionComponent<DrinkProps> = ({ hideDrink, onFormClose, drinkItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [comment, setComment] = useState('');
  const [reaction, setReaction] = useState<ReactionType | ''>('');
  const [drink, setDrink] = useState('');
  const [amount, setAmount] = useState(0);

  const sm = useMediaQuery('(max-width:416px)');

  const handleComment = (value: string) => {
    setComment(value);
  }

  const addDrink = async (data: any) => {
    const drinkCollection = collection(db, "trackingdata", user.userId, "drink");
    await setDoc(doc(drinkCollection), {
        datetime: data.datetime,
        drink: data.drink,
        amount: data.amount,
        reaction: reaction,
        comment: data.comment,
        babyId: baby.babyId
      });

    onFormClose('drink');
  }

  const updateDrink = async (data: any) => {
    const drinkCollection = collection(db, "trackingdata", drinkItem.userId, "drink");
    const drinkDocRef = doc(drinkCollection, drinkItem.id);

    try {
      await updateDoc(drinkDocRef, {
        datetime: data.datetime,
        drink: data.drink,
        amount: data.amount,
        reaction: data.reaction,
        comment: data.comment
      });
    } catch (error) {
      console.error('Error updating drink:', error);
    }

    onFormClose('drink');
  }

  const deleteDrink = async () => {
    const drinkCollection = collection(db, "trackingdata", drinkItem.userId, "drink");
    const drinkDocRef = doc(drinkCollection, drinkItem.id);

    try {
      await deleteDoc(drinkDocRef);
    } catch (error) {
      console.error('Error deleting drink:', error);
    }

    onFormClose('drink');
  }

  const handleDrink = (value: string) => {
    setDrink(value);
  }

  const handleAmount = (value: number) => {
    setAmount(value);
  }

  const resetForm = () => {
    setStartDate(new Date());
    setReaction('');
    setDrink('');
    setAmount(0);
    setComment('');
  }

  useEffect(() => {
    if (drinkItem != null) {
      const start = new Date(drinkItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setReaction(drinkItem.data.reaction);
      setDrink(drinkItem.data.drink);
      setAmount(drinkItem.data.amount);
      setComment(drinkItem.data.comment);
    }
  }, [drinkItem]);

  useEffect(() => {
    if (drinkItem == null) {
      resetForm();
    }
  }, [hideDrink]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          drink: drink,
          amount: amount,
          reaction: reaction,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (drinkItem != null) {
            updateDrink(values);
          } else {
            addDrink(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <FaMugHot style={headerIcon} /> 
              <span style={sm ? headerTitleSX : headerTitleMD}>Drinken</span>
            </div>

            <div className="form-body">
              <div className="group-space datepicker-group">
                <CustomNowDateTimePicker 
                  startDate={startDate}
                  onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date);}}
                  onNowDate={setStartDate}
                />
              </div>

              <div className={`group-space ${styles.drinkgroup}`}>
                <div className={styles.drinkContainer}>
                  <div className={styles.drinkInput}>
                    <Typography component={'span'} variant="h4">
                        <div>Drinken</div>
                    </Typography>
                    <label>
                      <Field 
                        as="input" 
                        name="drink"
                        type="text"
                        placeholder=""
                        onChange={(e: any) => { handleDrink(e.target.value); }} //
                        value={drink} 
                        className={styles.drinkField} 
                        maxLength="30"
                      /> 
                    </label>
                  </div>
                  <div className={styles.drinkInput}>
                    <Typography component={'span'} variant="h4">
                      <div className={styles.space}>Hoeveelheid (ml)</div>
                    </Typography>
                    <label>
                      <Field 
                        as="input" 
                        name="amount"
                        type="number"
                        placeholder=""
                        onChange={(e: any) => { handleAmount(e.target.value); }}
                        value={amount} 
                        className={styles.drinkField} 
                        maxLength="30"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <Reaction onReaction={setReaction} initReaction={reaction} />

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div id="drink-group">Opmerking</div>
                </Typography>
                <Field 
                  as="textarea" 
                  name="comment" 
                  onChange={(e: any) => handleComment(e.target.value)} 
                  value={comment} 
                  className="comment-field" 
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                { drinkItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteDrink}
                    sx={{ 
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                } 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Drink;
