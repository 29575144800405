import React, { useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ user, children }: any) => {
  if (!user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;