import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { BsPen } from 'react-icons/bs';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import styles from './Notes.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../styles/FormStyles.css';

interface NotesProps {
  hideNote: boolean;
  onFormClose: (formType: string) => void;
  noteItem: any;
}

interface Values {
  datetime: Date;
  note: string;
}

const Notes: FunctionComponent<NotesProps> = ({ hideNote, onFormClose, noteItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [note, setNote] = useState('');

  const sm = useMediaQuery('(max-width:416px)');
  const md = useMediaQuery('(min-width:450px)');

  const handleNote = (value: string) => {
    setNote(value);
  }

  const addNote = async (data: any) => {
    const NotesCollection = collection(db, "trackingdata", user.userId, "notes");
    await setDoc(doc(NotesCollection), {
      datetime: data.datetime,
      note: data.note,
      babyId: baby.babyId
    });

    onFormClose('note');
  }

  const updateNote = async (data: any) => {
    const NotesCollection = collection(db, "trackingdata", noteItem.userId, "notes");
    const NotesDocRef = doc(NotesCollection, noteItem.id);

    try {
      await updateDoc(NotesDocRef, {
        datetime: data.datetime,
        note: data.note
      });
    } catch (error) {
      console.error('Error updating note:', error);
    }

    onFormClose('note');
  }

  const deleteNote = async () => {
    const NotesCollection = collection(db, "trackingdata", noteItem.userId, "notes");
    const NotesDocRef = doc(NotesCollection, noteItem.id);

    try {
      await deleteDoc(NotesDocRef);
    } catch (error) {
      console.error('Error deleting note:', error);
    }

    onFormClose('note');
  }

  const resetForm = () => {
    setStartDate(new Date());
    setNote('');
  }

  useEffect(() => {
    if (noteItem != null) {
      const start = new Date(noteItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setNote(noteItem.data.note);
    }
  }, [noteItem]);

  useEffect(() => {
    if (noteItem == null) {
      resetForm();
    }
  }, [hideNote]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          note: note
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (noteItem != null) {
            updateNote(values);
          } else {
            addNote(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <BsPen style={headerIcon} />
              <span style={sm ? headerTitleSX : headerTitleMD}>Notitie</span>
            </div>

            <div className="form-body">
              <div className="group-space datepicker-group">
                <CustomNowDateTimePicker
                  startDate={startDate}
                  onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date); }}
                  onNowDate={setStartDate}
                />
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div id="notes-group">Notitie</div>
                </Typography>
                <Field
                  as="textarea"
                  name="note"
                  onChange={(e: any) => handleNote(e.target.value)}
                  value={note}
                  className="comment-field"
                  style={{ height: md ? '100px' : '140px' }}
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                {noteItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteNote}
                    sx={{
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                }
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Notes;
