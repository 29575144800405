// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD6oSYrgifx5mAs0rV1sM7qd4glYQvsubE",
    authDomain: "baby-book.online",
    projectId: "baby-tracker-123",
    storageBucket: "baby-tracker-123.appspot.com",
    messagingSenderId: "724093177939",
    appId: "1:724093177939:web:6ad5de3d0b32d8cbaa36a6",
    measurementId: "G-408QKECR68"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        if (result) {
            const user = result.user;
        }
    } catch (err) {
        console.error(err);
    }
};

const signOut = () => {
    auth.signOut();
};

export { auth, db, analytics, signInWithGoogle, signOut };