import { useContext, FunctionComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { UserContext } from '../../context/UserContext';
import { PartnerContext } from '../../context/PartnerContext';
import { FbUserContext } from '../../context/FbUserContext';
import { ChildrenContext } from "../../context/ChildrenContext";
import { BabyContext } from "../../context/BabyContext";
import { db, auth } from "../../services/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { deleteCollection, deleteAllUserBabies, deleteDocumentsWithBabyId, getChildrenData, removePartnerFromUser } from "../../services/DataService";
import styles from './DeleteAccountDialog.module.css';
import { Typography } from '@mui/material';

export interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const DeleteAccountDialog: FunctionComponent<DialogProps> = ({ open, onClose }) => {
  const [fbUser, setFbUser] = useContext(FbUserContext);
  const [user, setUser] = useContext(UserContext);
  const [partner, setPartner] = useContext(PartnerContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [allChildren, setAllChildren] = useContext(ChildrenContext);

  const signOut = () => {
    setFbUser(null);
    setUser(null);
    setBaby(null);
    setAllChildren(null);
    auth.signOut();
  }

  const deleteAccount = async () => {
    // 1. Delete all trackingdata subcollections of user
    await deleteCollection(db, `trackingdata/${user.userId}/diapers`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/breastfeedings`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/bottlefeedings`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/pumping`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/temperatures`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/weights`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/medications`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/food`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/drink`, 500);
    await deleteCollection(db, `trackingdata/${user.userId}/notes`, 500);

    // 2. Get all children created by the user (not the partner's created children)
    // Delete any child data created by the partner for this child to clear up any data the partner isn't able to see anymore after account deletion
    const allUserChildren = await getChildrenData(user.userId, "");

    allUserChildren.forEach(async (child: any) => {
      if (partner && user.partnerSynced) {
        const partnerId = partner.userId;
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/diapers`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/breastfeedings`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/bottlefeedings`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/pumping`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/temperatures`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/weights`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/medications`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/food`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/drink`, child.babyId, 500);
        await deleteDocumentsWithBabyId(db, `trackingdata/${partnerId}/notes`, child.babyId, 500);
      }
    });

    // 3. Unlink partner from user
    if (partner && partner.partnerId == user.userId) {
      await removePartnerFromUser(user.userId, partner.partnerId);
    }

    // 4. Delete all babies created by user
    await deleteAllUserBabies(db, `babies`, user.userId, 500);
    // 5. Delete the userid from the trackingdata collection
    await deleteDoc(doc(db, "trackingdata", user.userId));
    // 6. Delete the userid from the users collection
    await deleteDoc(doc(db, "users", user.userId));

    // 7. Sign out user
    signOut();
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#444654',
          padding: '20px'
        },
      }}
    >
      <Typography variant="h2">
        Account verwijderen
      </Typography>
      <Typography variant="body2" sx={{ paddingTop: '10px' }}>
        Als je op 'Ja, verwijder mijn account' klikt, wordt al jouw data definitief verwijderd en is deze niet meer terug te halen.
      </Typography>
      <Typography variant="body2" sx={{ paddingTop: '10px' }}>
        Weet je zeker dat je je account wilt verwijderen?
      </Typography>

      <div>
        <Button
          variant="contained"
          type="button"
          onClick={onClose}
        >
          Nee, annuleer
        </Button>
        <Button
          variant="contained"
          type="button"
          onClick={deleteAccount}
          className={styles.deleteBtn}
        >
          Ja, verwijder mijn account
        </Button>
      </div>

    </Dialog>
  );
}

export default DeleteAccountDialog;
