import { useContext } from "react";
import { Routes, Route } from "react-router-dom"
import { FbUserContext } from "../context/FbUserContext";
import PrivateRoute from "./PrivateRoute"
import PrivacyPolicy from "../components/privacy-policy/PrivacyPolicy"
import About from "../components/about/About";
import SignIn from "../components/auth/Signin"
import Dashboard from "../components/dashboard/Dashboard"
import Profile from "../components/profile/Profile";
import Baby from "../components/baby/Baby";
import NavBar from "../components/navbar/NavBar";
import FAQ from "../components/faq/FAQ";

function AppRoutes() {
  const [fbUser, setFbUser] = useContext(FbUserContext);

  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="about" element={<About />} />
        <Route path="faq" element={<FAQ />} />

        <Route
          path="dashboard"
          element={
            <PrivateRoute user={fbUser}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="profile"
          element={
            <PrivateRoute user={fbUser}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="baby"
          element={
            <PrivateRoute user={fbUser}>
              <Baby />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  )
}

export default AppRoutes