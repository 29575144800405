import { FunctionComponent } from "react";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ThemeProvider } from '@mui/material/styles';
import { datepickertheme, OpenCalendarIcon } from './Datepickertheme';

interface CustomDateTimePickerProps {
  startDate: Date;
  onDateTimeChange: (date: Date) => void;
}

const CustomDateTimePicker: FunctionComponent<CustomDateTimePickerProps> = ({ startDate, onDateTimeChange }) => {
  return (
    <div>
      <div>
        <ThemeProvider theme={datepickertheme}>
          <DateTimePicker
            value={startDate}
            onChange={(date: any) => { onDateTimeChange(date); }}
            slots={{
              openPickerIcon: OpenCalendarIcon
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

export default CustomDateTimePicker;
