import { useEffect, useContext } from "react";
import { auth } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { FbUserContext } from "../../context/FbUserContext";
import AppRoutes from '../../routes/AppRoutes';

function App() {
  const [firebaseuser] = useAuthState(auth);
  const [fbUser, setFbUser] = useContext(FbUserContext);

  useEffect(() => {
    if (firebaseuser) {
      if (fbUser == null && fbUser != firebaseuser) {
        setFbUser(firebaseuser);
      }
    }
  }, [firebaseuser]);

  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
