import { FunctionComponent, useEffect } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ThemeProvider } from '@mui/material/styles';
import { nowdatepickertheme, OpenCalendarIcon } from './Datepickertheme';
import useMediaQuery from '@mui/material/useMediaQuery';

interface BirthDayDatePickerProps {
  birthday: Date;
  onDateChange: (date: Date) => void;
}

const BirthDayDatePicker: FunctionComponent<BirthDayDatePickerProps> = ({ birthday, onDateChange }) => {
  const sm = useMediaQuery('(max-width:416px)');

  return (
    <div>
      <div style={{ width: sm ? '64%' : 'auto', float: 'left' }}>
        <ThemeProvider theme={nowdatepickertheme}>
          <DatePicker
            value={birthday}
            onChange={(date: any) => { onDateChange(date); }}
            slots={{
              openPickerIcon: OpenCalendarIcon
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

export default BirthDayDatePicker;
