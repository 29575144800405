import React, { createContext, useState } from 'react';

type IBabyContext = [any, React.Dispatch<React.SetStateAction<any>>];

const BabyContext = createContext<IBabyContext>([[], () => {}]);

const BabyProvider = ({ children }: { children: any }) => {
  const [baby, setBaby] = useState(null);
  
  return (
    <BabyContext.Provider value={[baby, setBaby]}>
      {children}
    </BabyContext.Provider>
  )
} 

export { BabyContext, BabyProvider };
