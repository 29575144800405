export const headerIcon = { 
  color: '#fff', 
  fontSize: '2.2rem',
  padding: '5px'
}

export const headerTitleSX = {
  color: '#fff',
  fontSize: '0.9rem',
  fontWeight: 'bold',
  position: 'relative' as 'relative',
  top: '-11px'
}
export const headerTitleMD = {
  color: '#fff',
  fontSize: '1.1rem',
  fontWeight: 'bold',
  position: 'relative' as 'relative',
  top: '-10px'
}