import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { BiSolidBowlHot } from "react-icons/bi";
import Reaction, { ReactionType } from '../reaction/Reaction';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import styles from './Food.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../styles/FormStyles.css';

interface FoodProps {
  hideFood: boolean;
  onFormClose: (formType: string) => void;
  foodItem: any;
}

interface Values {
  datetime: Date;
  food: string;
  amount: number;
  reaction: ReactionType | '';
  comment: string;
}

const Food: FunctionComponent<FoodProps> = ({ hideFood, onFormClose, foodItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [comment, setComment] = useState('');
  const [reaction, setReaction] = useState<ReactionType | ''>('');
  const [food, setFood] = useState('');
  const [amount, setAmount] = useState(0);

  const sm = useMediaQuery('(max-width:416px)');

  const handleComment = (value: string) => {
    setComment(value);
  }

  const addFood = async (data: any) => {
    const foodCollection = collection(db, "trackingdata", user.userId, "food");
    await setDoc(doc(foodCollection), {
        datetime: data.datetime,
        food: data.food,
        amount: data.amount,
        reaction: reaction,
        comment: data.comment,
        babyId: baby.babyId
      });

    onFormClose('food');
  }

  const updateFood = async (data: any) => {
    const foodCollection = collection(db, "trackingdata", foodItem.userId, "food");
    const foodDocRef = doc(foodCollection, foodItem.id);

    try {
      await updateDoc(foodDocRef, {
        datetime: data.datetime,
        food: data.food,
        amount: data.amount,
        reaction: data.reaction,
        comment: data.comment
      });
    } catch (error) {
      console.error('Error updating food:', error);
    }

    onFormClose('food');
  }

  const deleteFood = async () => {
    const foodCollection = collection(db, "trackingdata", foodItem.userId, "food");
    const foodDocRef = doc(foodCollection, foodItem.id);

    try {
      await deleteDoc(foodDocRef);
    } catch (error) {
      console.error('Error deleting food:', error);
    }

    onFormClose('food');
  }

  const handleFood = (value: string) => {
    setFood(value);
  }

  const handleAmount = (value: number) => {
    setAmount(value);
  }

  const resetForm = () => {
    setStartDate(new Date());
    setReaction('');
    setFood('');
    setAmount(0);
    setComment('');
  }

  useEffect(() => {
    if (foodItem != null) {
      const start = new Date(foodItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setReaction(foodItem.data.reaction);
      setFood(foodItem.data.food);
      setAmount(foodItem.data.amount);
      setComment(foodItem.data.comment);
    }
  }, [foodItem]);

  useEffect(() => {
    if (foodItem == null) {
      resetForm();
    }
  }, [hideFood]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          food: food,
          amount: amount,
          reaction: reaction,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (foodItem != null) {
            updateFood(values);
          } else {
            addFood(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <BiSolidBowlHot style={headerIcon} /> 
              <span style={sm ? headerTitleSX : headerTitleMD}>Eten</span>
            </div>

            <div className="form-body">
              <div className="group-space datepicker-group">
                <CustomNowDateTimePicker 
                  startDate={startDate}
                  onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date);}}
                  onNowDate={setStartDate}
                />
              </div>

              <div className={`group-space ${styles.foodgroup}`}>
                <div className={styles.foodContainer}>
                  <div className={styles.foodInput}>
                    <Typography component={'span'} variant="h4">
                      <div>Eten</div>
                    </Typography>
                    <label>
                      <Field 
                        as="input" 
                        name="food"
                        type="text"
                        placeholder=""
                        onChange={(e: any) => { handleFood(e.target.value); }} //
                        value={food} 
                        className={styles.foodField} 
                        maxLength="30"
                      /> 
                    </label>
                  </div>
                  <div className={styles.foodInput}>
                    <Typography component={'span'} variant="h4">
                      <div className={styles.space}>Hoeveelheid (gram)</div>
                    </Typography>
                    <label>
                      <Field 
                        as="input" 
                        name="amount"
                        type="number"
                        placeholder=""
                        onChange={(e: any) => { handleAmount(e.target.value); }}
                        value={amount} 
                        className={styles.foodField} 
                        maxLength="30"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <Reaction onReaction={setReaction} initReaction={reaction} />

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div id="food-group">Opmerking</div>
                </Typography>
                <Field 
                  as="textarea" 
                  name="comment" 
                  onChange={(e: any) => handleComment(e.target.value)} 
                  value={comment} 
                  className="comment-field" 
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                { foodItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteFood}
                    sx={{ 
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                } 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Food;
