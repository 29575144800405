import { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { db } from "../../services/firebase";
import { collection, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { GiBabyBottle } from 'react-icons/gi';
import CustomNowDateTimePicker from '../datepicker/CustomNowDateTimePicker';
import { UserContext } from '../../context/UserContext';
import { BabyContext } from '../../context/BabyContext';
import styles from './Bottlefeeding.module.css';
import { headerIcon, headerTitleSX, headerTitleMD } from '../../styles/IconStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../styles/FormStyles.css';

interface BottlefeedingProps {
  hideBottlefeeding: boolean;
  onFormClose: (formType: string) => void;
  bottlefeedingItem: any;
}

interface Values {
  datetime: Date;
  bottleAmount: number;
  milkType: string;
  comment: string;
}

const Bottlefeeding: FunctionComponent<BottlefeedingProps> = ({ hideBottlefeeding, onFormClose, bottlefeedingItem }) => {
  const [user, setUser] = useContext(UserContext);
  const [baby, setBaby] = useContext(BabyContext);
  const [startDate, setStartDate] = useState(new Date());
  const [bottleAmount, setBottleAmount] = useState(0);
  const [milkType, setMilkType] = useState('Breast milk');
  const [comment, setComment] = useState('');

  const sm = useMediaQuery('(max-width:416px)');

  const handleBottleAmount = (value: string) => {
    const amount = parseInt(value);
    setBottleAmount(amount);
  }

  const handleComment = (value: string) => {
    setComment(value);
  }

  const addBottleAmount = (milliliter: number) => {
    setBottleAmount(prevAmount => prevAmount + milliliter);
  };

  const addBottlefeeding = async (data: any) => {
    const bottlefeedingsCollection = collection(db, "trackingdata", user.userId, "bottlefeedings");
    await setDoc(doc(bottlefeedingsCollection), {
      datetime: data.datetime,
      bottleAmount: data.bottleAmount,
      milkType: data.milkType,
      comment: data.comment,
      babyId: baby.babyId
    });

    onFormClose('bottlefeeding');
  }

  const updateBottlefeeding = async (data: any) => {
    const bottlefeedingCollection = collection(db, "trackingdata", bottlefeedingItem.userId, "bottlefeedings");
    const bottlefeedingDocRef = doc(bottlefeedingCollection, bottlefeedingItem.id);

    try {
      await updateDoc(bottlefeedingDocRef, {
        datetime: data.datetime,
        bottleAmount: data.bottleAmount,
        milkType: data.milkType,
        comment: data.comment
      });
    } catch (error) {
      console.error('Error updating bottlefeeding:', error);
    }

    onFormClose('bottlefeeding');
  }

  const deleteBottlefeeding = async () => {
    const bottlefeedingCollection = collection(db, "trackingdata", bottlefeedingItem.userId, "bottlefeedings");
    const bottlefeedingDocRef = doc(bottlefeedingCollection, bottlefeedingItem.id);

    try {
      await deleteDoc(bottlefeedingDocRef);
    } catch (error) {
      console.error('Error deleting bottlefeeding:', error);
    }

    onFormClose('bottlefeeding');
  }

  const resetForm = () => {
    setStartDate(new Date());
    setBottleAmount(0);
    setMilkType('Breast milk');
    setComment('');
  }

  useEffect(() => {
    if (bottlefeedingItem != null) {
      const start = new Date(bottlefeedingItem.data.datetime.seconds * 1000);
      setStartDate(start);
      setBottleAmount(bottlefeedingItem.data.bottleAmount);
      setMilkType(bottlefeedingItem.data.milkType);
      setComment(bottlefeedingItem.data.comment);
    }
  }, [bottlefeedingItem]);

  useEffect(() => {
    if (bottlefeedingItem == null) {
      resetForm();
    }
  }, [hideBottlefeeding]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          datetime: startDate,
          bottleAmount: bottleAmount,
          milkType: milkType,
          comment: comment
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (bottlefeedingItem != null) {
            updateBottlefeeding(values);
          } else {
            addBottlefeeding(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={`form-wrapper ${styles.formWrapper}`}>
            <div className={`form-header ${styles.formHeader}`}>
              <GiBabyBottle style={headerIcon} />
              <span style={sm ? headerTitleSX : headerTitleMD}>Flesvoeding</span>
            </div>

            <div className="form-body">
              <div className="group-space datepicker-group">
                <div>
                  <CustomNowDateTimePicker
                    startDate={startDate}
                    onDateTimeChange={(date: any) => { setFieldValue("datetime", date); setStartDate(date); }}
                    onNowDate={setStartDate}
                  />
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Flesvoeding</div>
                </Typography>
                <div>
                  <label style={{ color: 'white', fontSize: '1.2rem' }}>
                    <Field 
                      as="input" 
                      name="bottleamount"
                      type="number"
                      onChange={(e: any) => handleBottleAmount(e.target.value)} 
                      value={bottleAmount} 
                      className={styles.bottleField} 
                    /> ml
                  </label>
                </div>
                <div className={styles.addBottleGroup}>
                  <button type="button" className={styles.addBottleBtn} onClick={() => addBottleAmount(5)}>
                    +5 ml
                  </button>
                  <button type="button" className={styles.addBottleBtn} onClick={() => addBottleAmount(10)}>
                    +10 ml
                  </button>
                  <button type="button" className={styles.addBottleBtn} onClick={() => addBottleAmount(25)}>
                    +25 ml
                  </button>
                  <button type="button" className={styles.addBottleBtn} onClick={() => addBottleAmount(50)}>
                    +50 ml
                  </button>
                  <button type="button" className={styles.addBottleBtn} onClick={() => addBottleAmount(100)}>
                    +100 ml
                  </button>
                  <button type="button" className={styles.addBottleBtn} onClick={() => addBottleAmount(250)}>
                    +250 ml
                  </button>
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Melktype</div>
                </Typography>
                <div className={`radio-group ${styles.radioGroup}`} role="group" aria-labelledby="my-radio-group" style={{ marginTop: '5px' }}>
                  <label
                    onClick={() => { setFieldValue("milkType", "Formula"); setMilkType('Formula') }}
                    style={{ backgroundColor: milkType === "Formula" ? '#18d99c' : 'transparent', color: milkType === "Formula" ? '#000' : '#F0FFFF' }}>
                    Kunstvoeding
                  </label>
                  <label
                    onClick={() => { setFieldValue("milkType", "Breast milk"); setMilkType('Breast milk') }}
                    style={{ backgroundColor: milkType === "Breast milk" ? '#18d99c' : 'transparent', color: milkType === "Breast milk" ? '#000' : '#F0FFFF' }}>
                    Moedermelk
                  </label>
                </div>
              </div>

              <div className="group-space">
                <Typography component={'span'} variant="h4">
                  <div>Opmerking</div>
                </Typography>
                <Field
                  as="textarea"
                  name="comment"
                  onChange={(e: any) => handleComment(e.target.value)}
                  value={comment}
                  className="comment-field"
                  maxLength="200"
                />
              </div>

              <div className={styles.buttonGroup}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Opslaan
                </Button>
                { bottlefeedingItem &&
                  <Button
                    variant="contained"
                    type="button"
                    onClick={deleteBottlefeeding}
                    sx={{ 
                      backgroundColor: '#c50000',
                      '&:hover': {
                        backgroundColor: '#970000',
                      },
                    }}
                  >
                    Verwijderen
                  </Button>
                } 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Bottlefeeding;
