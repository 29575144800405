import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import lindaMobilePNG from "../../images/Linda-mobile.png";
import lindaDesktopPNG from "../../images/Linda-desktop.png";
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './About.module.css';

function About() {
  const md = useMediaQuery('(min-width:700px)');

  return (
    <Container>
      <Box className={styles.mainContainer}>
        <Typography variant="h1" className={styles.mainTitle}>Over Baby Book</Typography>
        <Typography variant="h3" className={styles.mainTitle}>(en de moeder erachter)</Typography>

        {md ?
          <div className={styles.contentWrapper}>
            <div className={styles.textWrapper}>
              <Typography variant="h2" className={styles.header}>Hallo!</Typography>
              <Typography variant="body2" className={styles.paragraph}>
                Welkom bij Baby Book, jouw ultieme metgezel in het ouderschap! Mijn naam is Linda de Haan en ik ben de trotse maker van deze handige app.
              </Typography>
              <Typography variant="body2" className={styles.paragraph}>
                Als softwareontwikkelaar en kersverse ouder heb ik Baby Book gecreëerd met een eenvoudig doel voor ogen: ouders helpen om alle cruciale aspecten van de zorg voor hun baby moeiteloos bij te houden, zodat zij meer quality time kunnen doorbrengen met hun kleintje.
              </Typography>
            </div>
            <img src={lindaDesktopPNG} alt="Linda de Haan" className={styles.lindaImage} />
          </div>
          :
          <div>
            <Typography variant="h2" className={styles.header}>Hallo!</Typography>
            <Typography variant="body2" className={styles.paragraph} sx={{ display: 'block' }}>
              Welkom bij Baby Book, jouw ultieme metgezel in het ouderschap! Mijn naam is Linda de Haan en ik ben de trotse maker van deze handige app.
            </Typography>
            <img src={lindaMobilePNG} alt="Linda de Haan" className={styles.lindaImage} />
            <Typography variant="body2" className={styles.paragraph} sx={{ display: 'block' }}>
              Als softwareontwikkelaar en kersverse ouder heb ik Baby Book gecreëerd met een eenvoudig doel voor ogen: ouders helpen om alle cruciale aspecten van de zorg voor hun baby moeiteloos bij te houden, zodat zij meer quality time kunnen doorbrengen met hun kleintje.
            </Typography>
          </div>
        }

        <Typography variant="h2" className={styles.header}>Waarom Baby Book?</Typography>
        <Typography variant="body2" className={styles.paragraph}>Als aanstaande moeder besefte ik al snel dat er een overvloed aan informatie en taken op me afkwam. Baby Book is ontstaan vanuit mijn eigen behoefte om hier orde in te brengen. Daarom besloot ik deze webapplicatie te ontwikkelen om essentiële activiteiten rondom de baby te kunnen bijhouden, zoals voedingen, gewichtsontwikkeling, temperatuurmetingen, medicatie, verschonen, kolven en andere belangrijke aandachtspunten.</Typography>

        <Typography variant="h2" className={styles.header}>Wat Baby Book te bieden heeft</Typography>
        <Typography component={'span'} variant="body2" className={styles.paragraph}>
          <ul>
            <li><span style={{ fontWeight: 'bold' }}>Voeding:</span> Registreer borst- of flesvoedingen, inclusief timing en hoeveelheid.</li>
            <li><span style={{ fontWeight: 'bold' }}>Luiers verschonen:</span> Houd bij wanneer je baby is verschoond.</li>
            <li><span style={{ fontWeight: 'bold' }}>Medicijnen:</span> Bewaar gegevens over vitamine K & D en andere medicatie.</li>
            <li><span style={{ fontWeight: 'bold' }}>Gewicht:</span> Volg de groei van je baby met gewichtsregistraties.</li>
            <li><span style={{ fontWeight: 'bold' }}>Temperatuur:</span> Houd temperatuurmetingen van je baby bij.</li>
            <li><span style={{ fontWeight: 'bold' }}>Kolven:</span> Registreer wanneer en hoeveel je hebt gekolfd, inclusief timers.</li>
            <li><span style={{ fontWeight: 'bold' }}>Notities:</span> Voeg belangrijke opmerkingen en observaties toe voor toekomstige referentie.</li>
            <li><span style={{ fontWeight: 'bold' }}>Family sync:</span> Koppel jouw account met die van je partner en deel activiteiten van jullie baby.</li>
            <li><span style={{ fontWeight: 'bold' }}>Data export:</span> Exporteer je data om te delen met bijvoorbeeld je zorgverlener.</li>
            <li><span style={{ fontWeight: 'bold' }}>Statistieken:</span> Houd precies bij hoe vaak je elke activiteit hebt uitgevoerd.</li>
          </ul>
        </Typography>

        <Typography variant="h2" className={styles.header}>Over mij</Typography>
        <Typography variant="body2" className={styles.paragraph}>Als softwareontwikkelaar heb ik mijn passie voor technologie gecombineerd met mijn nieuwe rol als ouder om Baby Book te creëren. Tijdens mijn zwangerschapsverlof ontstond het idee om een makkelijke manier te vinden belangrijke gegevens rondom de ontwikkeling van de baby bij te houden. Dit was mijn motivatie om deze handige webapplicatie te creëren.</Typography>
        <Typography variant="body2" className={styles.paragraph}>Ik hoop dat jij er net zoveel aan hebt als ik en het je leven als nieuwe ouder nét even ietsje gemakkelijker maakt.</Typography>
        <Typography variant="body2" className={styles.paragraph}>Wil je meer over mij te weten komen? Je vindt mij op <a href="https://www.linkedin.com/in/lindadehaan-fullstack/" target="_blank" className={styles.linkedInLink}>LinkedIn</a>.</Typography>

        <Typography variant="h2" className={styles.header}>Contact</Typography>
        <Typography variant="body2" className={styles.paragraph}>Heb je vragen, feedback of wil je contact met mij opnemen? Stuur dan een bericht naar <a href = "mailto: babybookconnect@gmail.com" className={styles.linkedInLink}>babybookconnect@gmail.com</a></Typography>
      </Box>
    </Container>
  );
}

export default About;
