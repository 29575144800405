import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import App from './components/app/App';
import ComposeContext from './context/ComposeContext';
import { UserProvider } from './context/UserContext';
import { PartnerProvider } from './context/PartnerContext';
import { FbUserProvider } from './context/FbUserContext';
import { BabyProvider } from './context/BabyContext';
import { ChildrenProvider } from './context/ChildrenContext';
import { TrackingDataProvider } from './context/TrackingDataContext';
import { StatisticsProvider } from './context/StatisticsContext';
import nl from 'date-fns/locale/nl';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ComposeContext components={[FbUserProvider, UserProvider, PartnerProvider, ChildrenProvider, BabyProvider, TrackingDataProvider, StatisticsProvider]}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
          <CssBaseline />
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  </ComposeContext>
);